import React from 'react';
import TextField from '@material-ui/core/TextField';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { lng } from '../../Tore/Sys'
import SearchSelect from '../SearchSelect';
import Glb from '../../global/Glb';
import PhoneInput from '../PhoneInput';

const textFieldProps = {
    variant: "outlined",
    margin: "dense",
    size: "small"
}


export default function UserEditForm(props) {

    const user = props.record;//{ id: 1, nam: 'İyileşecek', sur: 'Hasta', ema: 'iyihasta@nesa.com', pno: "555-555-55-55", com: "NESA", usr: "User", act: 1 };//

    const handleActChange = props.handleActChange;
    const onChange = props.handleChange;
    const setFormikField = props.setFormikField;

    var allComs = Glb.Storage.allComs;
    var userCompany;
    if (allComs)
        userCompany = allComs.find(c => c.cid === user.cid)

    const handleChange = (e) => {
        e = handleActChange(e)
        if (e)
            onChange(e)
    }

    return (
        <Grid container direction="column">
            <TextField id={lng.itm("M_INP_NAM").id} label={lng.itm("M_INP_NAM").label} defaultValue={user.nam} onChange={onChange} {...textFieldProps} />
            <TextField id={lng.itm("M_INP_SUR").id} label={lng.itm("M_INP_SUR").label} defaultValue={user.sur} onChange={onChange} {...textFieldProps} />
            {/* <SearchSelect use="form" lngKey={"M_INP_COM"} setFormikField={setFormikField} options={allComs} defaultValue={userCompany} /> */}

            <PhoneInput variant="outlined" setFormikField={setFormikField} phone={user.pno} />
            <TextField id={lng.itm("M_INP_EMA").id} label={lng.itm("M_INP_EMA").label} defaultValue={user.ema} onChange={onChange} {...textFieldProps} />
            <TextField id={lng.itm("M_INP_PWD").id} label={lng.itm("M_INP_PWD").label} onChange={onChange} {...textFieldProps} />
            <FormControl>
                <InputLabel id={lng.itm("M_INP_ACT").id} >{lng.itm("M_INP_ACT").label} </InputLabel>
                <Select
                    labelId={lng.itm("M_INP_ACT").id}
                    id={lng.itm("M_INP_ACT").id}
                    onChange={handleChange}
                    variant="outlined"
                    style={{ height: "40px" }}
                >
                    <MenuItem value={true}>{lng.itm("M_LBL_ACT")}</MenuItem>
                    <MenuItem value={false}>{lng.itm("M_LBL_INACT")}</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    );
}
