
var Glb = {
    Storage: {
        usr: {
            cnm: '', // com name
            nam: '',
            sur: '',
            ema: '',
            pwd: '',
            adm: false,
            isloggedIn: false
        },
        allComs: [],
        com: {},
        dia: {},
        ord: {},
        allUsrs: [],
        usrLst: [],
        comLst: [],
        patLst: [],
        diaLst: [],
        ordLst: [],
        prdLst: []
    },
    Filter: {
        lim: 10,
        skp: 0,
        dta: {}
    },
    LikeFields: [
        "nam",
        "sur",
        "ema",
        "ddm",
        "pno",
        "oet"
    ],
    JoinFields: [
        "cid",
        "tid",
        "pid",
    ],
    FilterSelJoin: {
        lim: 10,
        skp: 0,
        sqr: [],
        jqr: []
    },
    Reload: {
        ord: true,
        usr: true,
        com: true,
        pat: true,
        dia: true,
        Func: {
            ord: () => { },
            usr: () => { },
        }
    },
    RegisterSuccess: {
        com: false,
        usr: false,
        prd: false,
        pat: false,
        dia: false,
        ord: false
    },
    EditSuccess: {
        com: false,
        usr: false,
        prd: false,
        pat: false,
        dia: false,
        ord: false
    },
    ListFiltered: {
        com: false,
        usr: false,
        prd: false,
        pat: false,
        dia: false,
        ord: false
    },
    Debug: false,
    Errors: {
        dia: {
            ddm: true,
            hei: true,
            wei: true,
            fts: true,
            fls: true,
            frs: true,
            mll: true,
            mrl: true
        },
        ord: {
            cid: true,
            uid: true,
            pid: true,
            did: true,
            odt: true,
            fil: true
        },
        Insole: {
            stp: true,
            tid: true,
            ihs: true,
            spf: true,
            ilf: true,
            cch: true
        },
        Slipper: {
            slp: true,
            ltp: true,
            mcl: true,
            tcl: true
        }
    },
    ReqLog: [],
    Page: {
        Order: 1
    }
};

export default Glb;