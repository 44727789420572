import React from "react";
import { Route, Redirect } from "react-router-dom";
import glb from '../global/Glb'

function PrivateAdminRoute({ children, ...rest }) {
  let response = localStorage.getItem("response");
  let resObj = JSON.parse(response);

  let isLoggedIn = false;
  let isAdmin = false;
  if (resObj?.sta === "ACK") {
    isLoggedIn = true;
    isAdmin = resObj?.dta.adm === true
    glb.Storage.usr.adm = isAdmin
  }
  const requirement = isAdmin && isLoggedIn;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        requirement ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateAdminRoute;