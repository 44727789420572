import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { lng } from '../../Tore/Sys'
import { Grid, TextField, Typography } from '@material-ui/core';
import Glb from '../../global/Glb';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        // padding: theme.spacing(2),
        height: "100%",
        //color: "theme.palette.text.secondary",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },

}));

export default function WedgesRight(props) {

    const classes = useStyles();

    const { heelMedLbl, heelLatLbl, frntMedLbl, frntLatLbl, fullMedLbl, fullLatLbl } =
        lng.itm("M_INP_WDG");

    const [wedgeRightHeelMedial, setHeelMed] = useState();
    const [wedgeRightHeelLateral, setHeelLat] = useState();
    const [wedgeRightFrontMedial, setFrntMed] = useState();
    const [wedgeRightFrontLateral, setFrntLat] = useState();
    const [wedgeRightFullMedial, setFullMed] = useState();
    const [wedgeRightFullLateral, setFullLat] = useState();

    const isEdit = props?.isEdit;
    const isRegister = props?.isRegister;

    const isInfo = (props?.row != undefined) && (!isEdit && !isRegister);
    const [fillValue, setFillValue] = useState(false);

    useEffect(() => {
        if ((props?.row != undefined) && (isInfo || isEdit || isRegister))
            setFillValue(true)
        Glb.Storage.dia = {};

        if (props.row) {
            var r = props.row;
            setHeelMed(r.wedgeRightHeelMedial);
            setHeelLat(r.wedgeRightHeelLateral);
            setFrntMed(r.wedgeRightFrontMedial);
            setFrntLat(r.wedgeRightFrontLateral);
            setFullMed(r.wedgeRightFullMedial);
            setFullLat(r.wedgeRightFullLateral);

            //Glb.Storage.dia.wedgeRightHeelMedial = parseInt(r.wedgeRightHeelMedial);
            //Glb.Storage.dia.wedgeRightHeelLateral = parseInt(r.wedgeRightHeelLateral);
            //Glb.Storage.dia.wedgeRightFrontMedial = parseInt(r.wedgeRightFrontMedial);
            //Glb.Storage.dia.wedgeRightFrontLateral = parseInt(r.wedgeRightFrontLateral);
            //Glb.Storage.dia.wedgeRightFullMedial = parseInt(r.wedgeRightFullMedial);
            //Glb.Storage.dia.wedgeRightFullLateral = parseInt(r.wedgeRightFullLateral);

            if (isEdit)
                Glb.Storage.dia.did = r.did;

        }
        return () => {

        }
    }, [props?.row])
    let row = props.row;

    function handleChange(e) {
        let val = e.currentTarget.value
        let id = e.currentTarget.id;
        if (id) {
            if (!val || val.length === 0)
                val = 0
            if (val > 15) {
                val = 15
            } else if (val < 0)
                val = 0
            e.currentTarget.value = val;
            Glb.Storage.dia[id] = val;

            switch (id) {
                case "wedgeRightHeelMedial":
                    setHeelMed(val)
                    break;
                case "wedgeRightHeelLateral":
                    setHeelLat(val)
                    break;
                case "wedgeRightFrontMedial":
                    setFrntMed(val)
                    break;
                case "wedgeRightFrontLateral":
                    setFrntLat(val)
                    break;
                case "wedgeRightFullMedial":
                    setFullMed(val)
                    break;
                case "wedgeRightFullLateral":
                    setFullLat(val)
                    break;
                default:
                    break;
            }
        }
    }

    const inputProps = { 
        style: { 
            textAlign: 'center', 
            fontSize: "18px" 
        } 
    };

    const inputLabelProps = {
        style: {
            fontSize: "17px",
            color: "black"
        }
    };

    return (
        <div className={classes.root}>
            <Grid container direction="column" >
                <Grid item >
                    {/* <Paper className={classes.paper}> */}
                    <Typography style={{ paddingBottom: "20px" }}>
                        <strong>
                            {props.label}
                        </strong>
                    </Typography>
                    {/* </Paper> */}
                </Grid>
                <Grid container direction="column" >
                    <Grid >
                        {/* <Paper className={classes.paper}> */}

                        <TextField
                            id="wedgeRightHeelMedial"
                            label={heelMedLbl}
                            //style={{ width: "85%", height: "60%" }}
                            inputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            type="number"
                            onChange={handleChange}
                            value={wedgeRightHeelMedial ? wedgeRightHeelMedial : 0}
                            InputProps={{
                                readOnly: isInfo
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>
                    <Grid >
                        {/* <Paper className={classes.paper}> */}
                        <TextField
                            id="wedgeRightHeelLateral"
                            label={heelLatLbl}
                            //style={{ width: "85%", height: "60%" }}
                            inputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            type="number"
                            onChange={handleChange}
                            value={wedgeRightHeelLateral ? wedgeRightHeelLateral : 0}
                            InputProps={{
                                readOnly: isInfo
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>
                    <Grid >
                        {/* <Paper className={classes.paper}> */}
                        <TextField
                            id="wedgeRightFrontMedial"
                            label={frntMedLbl}
                            //placeholder={wedgeRightFrontMedial}
                            //style={{ width: "85%", height: "60%" }}
                            inputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            type="number"
                            onChange={handleChange}
                            value={wedgeRightFrontMedial ? wedgeRightFrontMedial : 0}
                            InputProps={{
                                readOnly: isInfo
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>
                    <Grid >
                        {/* <Paper className={classes.paper}> */}
                        <TextField
                            id="wedgeRightFrontLateral"
                            //placeholder={wedgeRightFrontLateral}
                            label={frntLatLbl}
                            //style={{ width: "85%", height: "60%" }}
                            inputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            type="number"
                            onChange={handleChange}
                            value={wedgeRightFrontLateral ? wedgeRightFrontLateral : 0}
                            InputProps={{
                                readOnly: isInfo
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>
                    <Grid >
                        {/* <Paper className={classes.paper}> */}
                        <TextField
                            id="wedgeRightFullMedial"
                            label={fullMedLbl}
                            //placeholder={wedgeRightFullMedial}
                            //style={{ width: "85%", height: "60%" }}
                            inputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            type="number"
                            onChange={handleChange}
                            value={wedgeRightFullMedial ? wedgeRightFullMedial : 0}
                            InputProps={{
                                readOnly: isInfo
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>
                    <Grid >
                        {/* <Paper className={classes.paper}> */}
                        <TextField
                            id="wedgeRightFullLateral"
                            label={fullLatLbl}
                            //placeholder={wedgeRightFullLateral}
                            style={{ paddingBottom: "10px" }}
                            inputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            type="number"
                            onChange={handleChange}
                            value={wedgeRightFullLateral ? wedgeRightFullLateral : 0}
                            InputProps={{
                                readOnly: isInfo
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>
                </Grid>
            </Grid>

        </div>
    );
}
