import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Exc } from '../Tore/Exc';
import SearchTable from '../components/table/SearchTable';
import DataTable from '../components/table/DataTable';
import Glb from '../global/Glb';


const useStyles = makeStyles((theme) => ({
    formCenter: {
        alignItems: "center",
        justify: "center",
        height: 300,
        width: "%100",
        margin: "dense",

    },
    table: {
        fontSize: "10px"
    },
    button: {
        margin: theme.spacing(1),
        justifyContent: "center"
    },
}));

export default function Products() {
    const classes = useStyles();
    const columnsTag = "M_TBL_PRD_HDR";
    const searchInputs = ["M_INP_NAM", "M_INP_COM", "M_INP_ACT"]
    var [reRender, setReRender] = useState({});
    const [rows, setRows] = useState([]);

    useEffect(() => {

        Glb.Filter.dta = { act: true }
        Glb.Filter.skp = 0;
        Glb.ListFiltered.prd = true;

        if (!Glb.Reload.prd)
            setRows(Glb.Storage.prdLst)

        return () => {
            Glb.Filter.dta = {}
            Glb.Filter.skp = 0;
            Glb.ListFiltered.prd = false;
        }
    }, [])

    return (
        <Exc>
            <Grid container>
                <Grid container>
                    <Grid item xm={"false"} sm={2} />
                    <Grid container className={classes.formCenter} xm={12} sm={8}>
                        <SearchTable setRows={setRows} searchInputs={searchInputs} registerPath="/productRegister" searchPath="/prdLst" isPrd={true} />
                        <DataTable rows={rows} columnsTag={columnsTag} infoPath="productCompanyRelation" registeredObj="prd" editPath="/productEdit" editable={true} setReRender={setReRender} keyExtractor="tid" />
                    </Grid>
                </Grid>
                <Grid item xm={"false"} sm={2} />
            </Grid>
        </Exc>
    );
}