"using strict"
/*————————————————————————————————————————————————————————————————————————————
    ——————————————————————————————————————————————
    |   Exc : Exception subsystem for React JS   |
    ——————————————————————————————————————————————

© Copyright 2021 İhsan Volkan Töre.

Author              : IVT.  (İhsan Volkan Töre)
Version             : 202103151356

History             :
202103151356: IVT   : First Draft.
————————————————————————————————————————————————————————————————————————————*/
import {def} from "./Sys.js";
import React                from 'react';
import Button               from '@material-ui/core/Button';
import Dialog               from '@material-ui/core/Dialog';
import DialogActions        from '@material-ui/core/DialogActions';
import DialogContent        from '@material-ui/core/DialogContent';
import DialogContentText    from '@material-ui/core/DialogContentText';
import DialogTitle          from '@material-ui/core/DialogTitle';
import Paper                from '@material-ui/core/Paper';
import Switch               from '@material-ui/core/Switch';
import Typography           from '@material-ui/core/Typography';
import Draggable            from 'react-draggable';

/*————————————————————————————————————————————————————————————————————————————
    —————————————————————————
    |  Exception Subsystem  |
    —————————————————————————

    Importing this from Sys.js is recommended
————————————————————————————————————————————————————————————————————————————*/

const DEBUGGING = true;     // true for development, false for production.

/*————————————————————————————————————————————————————————————————————————————
  CLASS : Exception.
  USAGE : Collects exception information properly.
          Not exported.
————————————————————————————————————————————————————————————————————————————*/
class Exception extends Error {

/*————————————————————————————————————————————————————————————————————————————
  CTOR: constructor.
  TASK: Checks if an object is defined and non null.
  ARGS: obj : object    : Object to check.
  INFO: * Algorithm:
        If no incoming error (err)
            builds an exception.
        Collects exception data.
        if DEBUGGING enabled
            Writes exception info to console.
            Shows an alert popup.
        If there was no exception (err) at entry
            Throws the exception built.
        else
            returns Exception object.

        * Collected Data:
            "exc" = Class name of exception.
            "msg" = Exception message.
            "tag" = Exception tag.
            "inf" = Exception info.
            "stk" = Call stack (Structure is browser dependant).
————————————————————————————————————————————————————————————————————————————*/
constructor(tag, inf, err = null) {
    super("");
var t = this,
    n = !def(err);                          // Something new?
    
    if ((!n) && (err instanceof Exception)) // If processed,
       return;                              // First exception is relevant.

    if (n){                                 // If not an Error based one.
        t.exc = "Exception";                // This is the class name.
        t.stk = t.stack;
    }
    else {                                  // If Error based,
        t.exc = err.name;                   // Get class name,
        t.stk = err.stack;                  // capture the stacktrace.
    }
    t.tag = tag;
    t.inf = inf;
    t.msg = n ? tag : err.message;
    if (DEBUGGING){
        t.log();
        t.pop();
    }
    if (n)
       throw t;
}

log(){
var t = this;
    console.log("——————————————————————————————————————————————————————");
    console.log("EXC: "+t.exc);
    console.log("TAG: "+t.tag);
    console.log("INF: "+t.inf);
    console.log("MSG: "+t.msg);
    console.log("STK: ");
    console.log(t.stk);
    console.log("——————————————————————————————————————————————————————");
}

pop() {
var t = this;
    alert(
        "EXC: "+t.exc+"\n"+
        "TAG: "+t.tag+"\n"+
        "INF: "+t.inf+"\n"+
        "MSG: "+t.msg
    );
}

}   // End class Exception.

/*————————————————————————————————————————————————————————————————————————————
  FUNC: exc [static]
  TASK: Raises and/or logs an exception with extra control.
  ARGS: tag : String    : Message selector.
        inf : String    : Extra information.
        err : Error     : A thrown Error if called in a *catch*.
  RETV: Returns Exception if err is defined.
        Otherwise throws an exception.
————————————————————————————————————————————————————————————————————————————*/
export function exc(tag = "E_NO_TAG", inf = "", err = null) {
   return new Exception(tag, inf, err);
}


if (def(window)){
    
    // This is for window.onerror event.
    function windowExcHandler(msg, src, lin, col, err) {
        if (!(err instanceof Exception))
            exc("E_UNHANDLED", msg, err)
        return true
    }
    window.onerror = windowExcHandler;
}

/*————————————————————————————————————————————————————————————————————————————
  CLASS : Exc
  USAGE : JSX Error Boundary Component collecting and displaying exception 
          information properly.
————————————————————————————————————————————————————————————————————————————*/
export class Exc extends React.Component {

    constructor(props) {
        super(props);
    var t = this;
        if (def(t.props["FallBack"])){
            t.fallBack = t.props.FallBack;
        } else {
            t.fallBack = null;
        }
        this.state = {error: t.props.error, errorInfo: t.props.errorInfo};
    }

    componentDidCatch(error, errorInfo) {
        this.setState({error: error, errorInfo: errorInfo});
    }

    render() {
    var t = this,
        s = t.state,
        e = s.error,
        i = s.errorInfo;
        if (!def(e)) {
            return this.props.children; 
        }
        alert("HAS ERROR");

        if (!(e instanceof Exception))
            e = exc("E_UNHANDLED","", e);
        if (t.fallBack)
            return new (t.fallBack)({excObj: e, excInf:i})
        else
            return (<DefaultFallback excObj={e} excInf={i}/>)

    }
}   // End class Exc.


/*———————————————————————————————————————————————————————————————————————————
  FUNC : DragPaper
  TASK : Used to make Exc Default Fallback dialogs draggable 
————————————————————————————————————————————————————————————————————————————*/
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

/*————————————————————————————————————————————————————————————————————————————
  CLASS : DefaultFallback
  USAGE : Exc Default Fallback dialog.
————————————————————————————————————————————————————————————————————————————*/
class DefaultFallback extends React.Component {

constructor(props){
    super(props);
var t = this;
    t.o = t.props.excObj;
    t.i = t.props.excInf;
    t.callStk = t.buildStackDisplay(t.o.stk);
    t.compStk = t.buildStackDisplay(t.i.ComponentStack);
    t.state = {open: false, swst: false};
}

buildStackDisplay(str) {
var x,
    q,
    s = [];

    if (!def(str))
        str = "-";
    x = str.split("\n");
    for(q in x)
        s.push(<DialogContentText>{x[q]}</DialogContentText>)
    return s
}

handleClickOpen = () => {
    console.log("clicked");
    this.setState({open: true});
}

handleClose = () => {
    this.setState({open: false, swst: false});
}

handleChange = (event) => {
    this.setState({swst:event.target.checked});
}

handleBody = () => {
    return (this.state.swst) ? this.compStk : this.callStk;
}

render() {
var t = this,
    e = t.o;
    
    return (
        <div style={{ marginTop: "10%" }}>
            <Button onClick={t.handleClickOpen} color="primary">{"EXC: "+e.tag}</Button>
            <Dialog
                open={t.state.open}
                onClose={t.handleClose}
                PaperComponent={PaperComponent}
                maxWidth={"lg"}
                //scroll={"paper"}                
                aria-labelledby="draggable-dialog-title"
                //aria-describedby="super-dialog-description"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <DialogContentText>{"EXC: "+e.exc}</DialogContentText>
                    <DialogContentText>{"TAG: "+e.tag}</DialogContentText>
                    <DialogContentText>{"INF: "+e.inf}</DialogContentText>
                    <DialogContentText>{"MSG: "+e.msg}</DialogContentText>
                </DialogTitle>
                <DialogContent dividers={true}>
                    
                        {t.handleBody()}
                    
                </DialogContent>
                <DialogActions>
                    <Typography variant="button" display="block">
                    Call Stack
                    <Switch
                        checked={t.state.swst}
                        onChange={t.handleChange}
                        name="checkedA"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    Component stack
                    </Typography>
                    <Button onClick={t.handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

}

