import React, { useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { lng } from '../Tore/Sys'
import Glb from '../global/Glb';
import HttpPost from './HttpPost';

export default function CustomSelectProducts(props) {

    const { lngKey, obj } = props;
    const selects = lng.itm(lngKey);
    const [selectOptions, setSelectOptions] = React.useState(selects);
    const [value, setValue] = React.useState(props.value);
    const cid = props.cid;
    const selectedLng = localStorage.getItem('selectedLng');
    useEffect(() => {
        async function fetchProductsByCid(cid) {
            var data = {
                dta: {
                    cid: cid
                },
                lim: 100,
                skp: 0
            }
            var res = await HttpPost("/prdLst", data);
            if (res?.sta === "ACK") {
                var list = {
                    items: res.dta,
                    label: selects.label,
                    id: selects.id
                }

                setSelectOptions(list);
            }
        }

        if (cid) {
            fetchProductsByCid(cid)
        }
        return () => {

        }
    }, [cid])
    const handleChange = (e) => {
        var id = e.currentTarget.id;
        var v = e.target.value;

        if (id && obj) {
            if (v || v === 0) {
                Glb.Storage[obj][id] = v;
                Glb.Errors.Insole.tid = false;
                setValue(v);
            }
        }
    }

    return (
        <FormControl fullWidth>
            <InputLabel style={{ marginLeft: "5px" }} > {selectOptions.label} </InputLabel>
            <Select id={selectOptions.id}
                onChange={handleChange}
                variant="outlined"
                value={value}
            >
                <MenuItem value={selectOptions.label} disabled>{selectOptions.label} </MenuItem>
                {selectOptions.items && (selectOptions.items.map((selectOption) => (
                    <MenuItem id={selectOptions.id} value={selectOption.tid}>{ selectedLng === "EN" && selectOption.namen ? selectOption.namen : selectOption.nam}</MenuItem>
                )))}
            </Select>
        </FormControl>
    );
}