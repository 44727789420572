import { AppBar, Typography, makeStyles, Tabs, Tab, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import React from "react";
import { lng } from '../../Tore/Sys'
import languages from "../../i18n/language";
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PropTypes from 'prop-types';
import Glb from "../../global/Glb";
import { Grid } from "@material-ui/core";

import IconButton from '@material-ui/core/IconButton';
 
import {
    IconFlagTR,
    IconFlagUS
} from 'material-ui-flags';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    firstTab: {
        marginLeft: "auto",
        minWidth: 45,
    },
    tabs: {
        minWidth: 45,
    },
    logo: {
        fontFamily: "Work Sans, sans-serif",
        fontWeight: 600,
        color: "primary",
        textAlign: "left",
        marginRight: "auto",
    },
}));


export default function ScrollableTabsButtonForce(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    //const [currentLng, setCurrentLng] = useState("TR");

    var comNam = Glb.Storage.usr.cnm;
    var usrNam = Glb.Storage.usr.nam;
    var label = comNam + " - " + usrNam;

    const headerData = lng.itm("M_HDRS_ADM");
    const history = useHistory();

    const handleChange = (event, newValue) => {
        if (newValue)
            setValue(newValue);
        if (newValue === 0)
            history.push("/");
        else {
            // Since the logo is the first element, we have to do (newValue - 1)
            if (headerData[newValue - 1]?.href)
                history.push(headerData[newValue - 1].href);
        }
    };

    return (
        <div className={classes.root}>
            
            <AppBar position="static" color="default">
            
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab className={classes.logo} label={comNam ? label : "NESA"} />
                    
                    <Tab className={classes.firstTab} label={headerData[0].label} icon={<BusinessIcon />} {...a11yProps(0)} />
                    <Tab className={classes.tabs} label={headerData[1].label} icon={<PeopleIcon />} {...a11yProps(1)} />
                    <Tab className={classes.tabs} label={headerData[2].label} icon={<ShoppingCartIcon />} {...a11yProps(2)} />
                    <Tab className={classes.tabs} label={headerData[3].label} icon={<LocalHospitalIcon />} {...a11yProps(3)} />
                    <Tab className={classes.tabs} label={headerData[4].label} icon={<ListAltIcon />} {...a11yProps(4)} />
                    <Tab className={classes.tabs} label={headerData[5].label} icon={<AccessibilityNewIcon />} {...a11yProps(5)} />
                    <Tab className={classes.tabs} label={headerData[6].label} icon={<ExitToAppIcon />} {...a11yProps(6)} />

                    <Grid>
                        <div onClick={() => props.handleLanguageChange(props.currentLng === "EN" ? "TR" : "EN")}>
                            {props.currentLng === "EN" ? (
                                <IconButton><IconFlagTR /></IconButton>
                            ) : (
                                <IconButton><IconFlagUS /></IconButton>
                            )}
                        </div>
                    </Grid>
                </Tabs>
            </AppBar>

        </div>
    );
}
