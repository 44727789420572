import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import { lng } from '../../Tore/Sys'
import CustomPaper from '../CustomPaper';
import CancelAndSubmitButton from '../CancelAndSubmitButton';
import { useHistory } from 'react-router-dom'
import HttpPost from '../HttpPost'
import { useFormik } from 'formik';
import SearchSelect from '../SearchSelect';
import Glb from '../../global/Glb';
import PhoneInput from '../PhoneInput';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formCenter: {
        alignItems: "center",
        justify: "center"
    }
}));

const textFieldProps = {
    variant: "outlined",
    size: "small",
    margin: "dense",
    required: true
}

export default function SignUp() {
    const classes = useStyles();
    const history = useHistory();
    const [options, setOptions] = React.useState(Glb.Storage.allComs);

    const formik = useFormik({
        initialValues: {

        },
        onSubmit: async values => {
            if (!values.cid) {
                alert(lng?.itm("M_WARN_NO_COM"));
                return;
            }
            const user = values;
            let ema = values.ema;
            let response = await HttpPost("/register", user)
            if (response) {
                if (response?.sta === "ACK") {
                    var uid = response?.dta.uid;
                    values.uid = uid;
                    values.act = true;
                    values.ema = ema;
                    Glb.Reload.usr = false;
                    Glb.RegisterSuccess.usr = true;


                    if (Glb.Storage["allUsrs"]) {
                        Glb.Storage["allUsrs"].push(values);
                        var allUsrsStr = JSON.stringify(Glb.Storage.allUsrs);
                        localStorage.setItem("allUsrs", allUsrsStr);
                    } else {
                        Glb.Storage["allUsrs"] = [];
                        Glb.Storage["allUsrs"].push(values);
                        var allUsrsStr = JSON.stringify(Glb.Storage.allUsrs);
                        localStorage.setItem("allUsrs", allUsrsStr);
                    }
                    if (Glb.Storage["usrLst"]) {
                        Glb.Storage["usrLst"].push(values);
                    } else {
                        Glb.Storage["usrLst"] = [];
                        Glb.Storage["usrLst"].push(values);
                    }
                    history.goBack();
                }
            }
        },
    });

    return (
        <Grid container spacing={5}>
            <Grid item sm={2} />
            <Grid container className={classes.formCenter} direction="column">
                <CustomPaper>
                    <h3> {lng.itm("M_HDR_USR_INS")} </h3>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container direction="column" className={classes.formCenter}>
                            <SearchSelect id={lng.itm("M_INP_COM").id} use="form" lngKey="M_INP_COM" setFormikField={formik.setFieldValue} options={options} />
                            <TextField id={lng.itm("M_INP_NAM").id} label={lng.itm("M_INP_NAM").label} {...textFieldProps} onChange={formik.handleChange} />
                            <TextField id={lng.itm("M_INP_SUR").id} label={lng.itm("M_INP_SUR").label} {...textFieldProps} onChange={formik.handleChange} />
                            <PhoneInput variant="outlined" setFormikField={formik.setFieldValue} required={true} />
                            <TextField id={lng.itm("M_INP_EMA").id} label={lng.itm("M_INP_EMA").label} type="email" {...textFieldProps} onChange={formik.handleChange} />
                            <TextField id={lng.itm("M_INP_PWD").id} label={lng.itm("M_INP_PWD").label} type="password" {...textFieldProps} onChange={formik.handleChange} />
                            <Grid item>
                                <CancelAndSubmitButton />
                            </Grid>
                        </Grid>
                    </form>
                </CustomPaper>
            </Grid>
            <Grid item sm={2} />
        </Grid>

    );
}
