import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import { lng } from "../../Tore/Sys";
import CustomPaper from "../CustomPaper";
import CancelAndSubmitButton from "../CancelAndSubmitButton";
import CustomSelectMultiple from "../CustomSelectMultiple";
import SearchSelect from "../SearchSelect";
import Glb from "../../global/Glb";
import HttpPost from "../HttpPost";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    "& > * + *": {
      margin: theme.spacing(3),
    },
  },
  formCenter: {
    alignItems: "center",
    justify: "center",
  },
}));

const inputProps = {
  margin: "dense",
  variant: "outlined",
};

export default function ProductCompanyRegister() {
  const classes = useStyles();
  const history = useHistory();
  const [values, setValues] = React.useState([]);

  useEffect(() => {
    Glb.ListFiltered.prd = false;
    loadData();
    return () => {
      Glb.Filter.lim = 10;
    }
  }, []);

  const loadData = async () => {
    Glb.Filter.dta = { act: true };
    Glb.Filter.lim = 100;

    let response = await HttpPost("/prdLst", Glb.Filter);
    if (response.sta === "ACK") {
      if (response.dta)
        setValues(response.dta);
    }
  };

  var products = lng.itm("M_INP_INSOLE_TYPES");
  var label = lng.itm("M_MUL_SEL");

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      let iid = values.cid;
      delete values.cid;
      values.iid = iid;
      //alert(JSON.stringify(values));
      const prodRel = values.prds.map((p) => p.tid);
      values.prds = prodRel;
      //alert(JSON.stringify(prodRel));
      let response = await HttpPost("/prdRel", values);
      if (response) {
        if (response?.sta === "ACK") {

          Glb.Reload.cpi = false;
          Glb.RegisterSuccess.cpi = true;
          // if (Glb.Storage.comLst) {
          //   Glb.Storage.comLst.push(values);
          // } else {
          //   Glb.Storage.comLst = [];
          //   Glb.Storage.comLst.push(values);
          // }

          history.push("/products");
        }
      }
    },
  });

  const handleChange = (e, value) => {
    //alert(JSON.stringify(value));
    formik.setFieldValue("prds", value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={2} />
      <Grid
        container
        className={classes.formCenter}
        direction="column"
        spacing={2}
      >
        <CustomPaper>
          <h3> {lng.itm("M_HDR_PRD_COM_INS")} </h3>
          <form onSubmit={formik.handleSubmit}>
            {" "}
            <Grid container direction="column" spacing={2}>
              <SearchSelect
                lngKey="M_INP_COM"
                use="form"
                {...inputProps}
                setFormikField={formik.setFieldValue}
              />
              <Grid item className={classes.root}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={values}
                  onChange={handleChange}
                  getOptionLabel={(option) => option.nam}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={label}
                      placeholder={products.label}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <CancelAndSubmitButton />
              </Grid>
            </Grid>
          </form>
        </CustomPaper>
      </Grid>
      <Grid item sm={2} />
    </Grid>
  );
}
