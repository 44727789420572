import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { lng } from '../../Tore/Sys'
import Glb from '../../global/Glb';
import checkBoxChecked from '../../images/checkboxChecked.png'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

export default function BodyPainAreasLeft(props) {

    const classes = useStyles();

    const labels = lng.itm("M_CHK_BD");

    const isEdit = props?.isEdit;
    const isRegister = props?.isRegister;

    const isInfo = (props?.row != undefined) && (!isEdit && !isRegister);
    const fillValue = (props?.row != undefined) && (isInfo || isEdit || isRegister);

    const [state, setState] = React.useState({
        bodyLSFootPain: false,
        bodyLSHipPain: false,
        bodyLSNeckPain: false,
        bodyLSBackPain: false,
        bodyLSKneePain: false,
        bodyLSAnklePain: false

    });

    useEffect(() => {

        if (fillValue) {

            let row = props.row;

            let checkBoxesState = {
                bodyLSFootPain: row.bodyLSFootPain === true ? (true) : (false),
                bodyLSHipPain: row.bodyLSHipPain === true ? (true) : (false),
                bodyLSNeckPain: row.bodyLSNeckPain === true ? (true) : (false),
                bodyLSBackPain: row.bodyLSBackPain === true ? (true) : (false),
                bodyLSKneePain: row.bodyLSKneePain === true ? (true) : (false),
                bodyLSAnklePain: row.bodyLSAnklePain === true ? (true) : (false)
            }
            setState(checkBoxesState);
        }
    }, [props.row]);

    const handleChange = (event) => {
        if (isEdit || !isInfo) {
            var value = event.target.checked;
            var name = event.target.name;
            if (name) {
                if (value && value === false)
                    delete Glb.Storage.dia[name]
                else
                    Glb.Storage.dia[name] = value;

                setState({ ...state, [name]: value });
            }

        }
    };

    const { bodyLSFootPain, bodyLSHipPain, bodyLSNeckPain, bodyLSBackPain, bodyLSKneePain, bodyLSAnklePain } = state;
    const myCheckBox = () => {
        return (
            <div style={{
                width: '21.33px',
                height: '21.33px',
                display: 'inline-block',
            }} >
                <img src={checkBoxChecked} style={{
                    marginTop: "2px",
                    width: '17.33px',
                    height: '17.33px',
                    display: 'inline-block',
                }} />
            </div>
        )
    }
    return (
        <div className={classes.root}>
            <FormControl className={classes.formControl}>
                <FormLabel>{props.formLabel}</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={bodyLSNeckPain} onChange={handleChange} name="bodyLSNeckPain" />}
                        label={<span style={bodyLSNeckPain ? { fontWeight: 'bold' } : {}}>{labels.one}</span>}
                        //label={labels.one}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={bodyLSBackPain} onChange={handleChange} name="bodyLSBackPain" />}
                        label={<span style={bodyLSBackPain ? { fontWeight: 'bold' } : {}}>{labels.two}</span>}
                        //label={labels.two}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={bodyLSHipPain} onChange={handleChange} name="bodyLSHipPain" />}
                        label={<span style={bodyLSHipPain ? { fontWeight: 'bold' } : {}}>{labels.three}</span>}
                        //label={labels.three}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={bodyLSKneePain} onChange={handleChange} name="bodyLSKneePain" />}
                        label={<span style={bodyLSKneePain ? { fontWeight: 'bold' } : {}}>{labels.four}</span>}
                        //label={labels.four}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={bodyLSAnklePain} onChange={handleChange} name="bodyLSAnklePain" />}
                        label={<span style={bodyLSAnklePain ? { fontWeight: 'bold' } : {}}>{labels.five}</span>}
                        //label={labels.five}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={bodyLSFootPain} onChange={handleChange} name="bodyLSFootPain" />}
                        label={<span style={bodyLSFootPain ? { fontWeight: 'bold' } : {}}>{labels.six}</span>}
                        //label={labels.six}
                    />
                </FormGroup>
                <FormHelperText></FormHelperText>
            </FormControl>
        </div>
    );
}
