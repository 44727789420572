import {
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PainAreas from "./PainAreas";
import { ThemeProvider } from "@material-ui/styles";
import { lng } from "../../Tore/Sys";
import WedgesLeft from "./WedgesLeft";
import WedgesRight from "./WedgesRight";
import ShortnessLeft from "./ShortnessLeft";
import ShortnessRight from "./ShortnessRight";
import PatientInfo from "./PatientInfo";
import Glb from "../../global/Glb";
import CancelAndSubmitButton from "../CancelAndSubmitButton";
import HttpPost from "../HttpPost";
import { useHistory } from "react-router-dom";
import Helper from "../../utils/Helper";
import MeasurementAFoot from "./MeasurementAFoot";
import MeasurementBFoot from "./MeasurementBFoot";
import MeasurementABImage from '../../images/ab.jpg';

export default function DiagnosisRegister({ pat, com, usr, setDia, handleClose, typ }) {
  const isAdmin = Glb.Storage.usr.adm;
  const history = useHistory();
  const [measurer, setMeasurer] = useState();
  const [fillValue, setFillValue] = useState(false);

  const [oldDiagnosis, setOldDiagnosis] = useState(undefined);

  function handleChange(e) {
    let val = e.currentTarget.value
    let id = e.currentTarget.id;

    if (id) {

      Glb.Storage.dia[id] = val;
      switch (id) {
        case "measurer":
          setMeasurer(val)
          break;
        default:
          break;
      }

    }
  }

  useEffect(() => {
    async function getOldDiagnosis() {

      var data = {
        lim: 1,
        skp: 0
      };
      data.dta = {};
      data.dta.pid = pat.pid;
      var diaResp = await HttpPost("/diaLst", data);
      if (diaResp?.sta === "ACK") {
        if (diaResp?.dta) {
          var diagnosis = diaResp.dta[0];
          if (diagnosis) {
            console.log(diagnosis)
            setFillValue(true);
            setOldDiagnosis(diagnosis)
            setMeasurer(diagnosis.measurer);


          }
        }
      }
    }

    Helper.resetDiaErrors();

    Glb.Storage.dia = {};
    var cid;
    var uid;
    if (com)
      cid = com.cid;
    else
      cid = Glb.Storage.usr.cid;
    if (usr)
      uid = usr.uid;
    else
      uid = Glb.Storage.usr.uid;

    Glb.Storage.dia.cid = cid;
    Glb.Storage.dia.uid = uid;
    console.log("usr id ----->", Glb.Storage.dia.uid);
    Glb.Storage.dia.pid = pat.pid;
    Glb.Storage.dia.pnm = pat.nam + " " + pat.sur;
    Glb.Storage.dia.pno = pat.pno;

    if (pat) {
      getOldDiagnosis()
    }

  }, [pat]);

  const handleSubmit = async () => {
    var errorsObj = Glb.Errors.dia;


    for (var prop in errorsObj) {
      if (errorsObj[prop] !== false) {
        alert("Lütfen gerekli alanları (*) eksiksiz giriniz.");
        return;
      }
    }


    var dia = Glb.Storage.dia;
    var pnm = dia.pnm;
    var pno = dia.pno;
    delete dia.pnm;
    delete dia.pno;
    let uid;
    if (usr)
      uid = usr.uid;
    else
      uid = Glb.Storage.usr.uid;
    dia.uid = uid;

    let response = await HttpPost("/diaIns", dia);
    if (response) {
      if (response?.sta === "ACK") {
        var did = response?.dta.did;
        dia.did = did;
        dia.act = true;
        dia.pnm = pnm;
        dia.pno = pno;
        dia.det = Helper.formatDate();
        Glb.Reload.dia = false;
        Glb.RegisterSuccess.dia = true;
        if (Glb.Storage.diaLst) {
          Glb.Storage.diaLst.push(dia);
        } else {
          Glb.Storage.diaLst = [];
          Glb.Storage.diaLst.push(dia);
        }
        if (setDia) {
          setDia(dia);
          if (handleClose)
            handleClose();
        } else
          history.goBack();
      }
    }
  };

  const classes = useStyles();

  return (
    <ThemeProvider>
      <Grid container>
        {/* <Grid sm={2} /> */}
        <Grid
          container
          item
          direction="column"
          className={classes.formCenter}
          sm={12}
          spacing={1}
        >

          <Grid container item>
            <Grid item sm={12}>
              {/* <Paper className={classes.paper}> */}
              <Typography variant="h3" style={{ paddingTop: "20px" }}>
                {lng.itm("M_HDR_DIA_INS")}
              </Typography>
              {/* </Paper> */}
            </Grid>
          </Grid>
          <Grid container item className={classes.select}>
            {isAdmin && (
              <>
                <Grid sm={4}>

                  <TextField disabled label={lng.itm("M_INP_COM").label} value={com?.nam} />
                </Grid>
                <Grid sm={4}>

                  <TextField disabled label={lng.itm("M_INP_USR").label} value={usr?.nam + " " + usr.sur} />
                </Grid>
              </>
            )}

            <Grid sm={4}>
              <TextField disabled label={lng.itm("M_INP_PAT").label} value={pat?.nam + " " + pat.sur} />

            </Grid>
          </Grid>

          <Grid item>
            <PainAreas row={oldDiagnosis} isRegister={true} />
          </Grid>

          <Grid container item direction="row">
            <Grid item sm={3}>
              <Paper className={classes.paper}>
                <PatientInfo row={oldDiagnosis} isRegister={true} />
              </Paper>
            </Grid>
            <Grid item sm={3}>
              <Paper className={classes.paper}>
                <WedgesLeft row={oldDiagnosis} label={lng.itm("M_INP_WDG").labelL} isRegister={true} />
              </Paper>
            </Grid>
            <Grid item sm={3}>
              <Paper className={classes.paper}>
                <WedgesRight row={oldDiagnosis} label={lng.itm("M_INP_WDG").labelR} isRegister={true} />
              </Paper>
            </Grid>
            <Grid container item sm={3} direction="column">
              <Grid container>
                <Grid item sm={6}>
                  <Paper className={classes.paper}>
                    <ShortnessLeft row={oldDiagnosis} label={lng.itm("M_INP_SHR").labelL} isRegister={true} />
                  </Paper>
                </Grid>
                <Grid item sm={6}>
                  <Paper className={classes.paper}>
                    <ShortnessRight row={oldDiagnosis} label={lng.itm("M_INP_SHR").labelR} isRegister={true} />
                  </Paper>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid container>
                  {typ === "Slipper" && (<Grid item sm={12}>
                    <Paper className={classes.paper}>
                      <Grid container style={{"justify-content":"center"}}>
                        <Grid sm={3}>
                          <MeasurementAFoot row={oldDiagnosis} isRegister={true} />
                        </Grid>
                        <Grid sm={6}>
                          <img alt="Foot pain areas" src={MeasurementABImage} className={classes.abImg} />
                        </Grid>
                        <Grid sm={3}>
                          <MeasurementBFoot row={oldDiagnosis} isRegister={true} />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>)}
                  <Grid item sm={12}>
                    <Paper className={classes.paper}>
                      <TextField id={lng.itm("M_INP_MEA").id} label={lng.itm("M_INP_MEA").label} onChange={handleChange} value={measurer ? measurer : ""} />
                    </Paper>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column" alignItems="flex-end">
            <Grid style={{ paddingTop: "30px" }}>
              <CancelAndSubmitButton handleClose={handleClose} onClick={handleSubmit} />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid sm={2} /> */}
      </Grid>
    </ThemeProvider>
  );
}

const useStyles = makeStyles((theme) => ({
  formCenter: {
    alignItems: "center",
    //width: "%100",
    //margin: "dense",
  },
  paper: {
    // padding: theme.spacing(2),
    height: "100%",
    //color: "theme.palette.text.secondary",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "top",
    paddingTop: "20px",
  },
  select: {
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  abImg: {
    height: "135px",
    width: "135px"
  }
}));