import axios from "axios";
import uuid from "uuid/v4";
import Glb from "../global/Glb";
import { Crypt } from "../components/Encryption";
import { Exc, lng } from "../Tore/Sys";
import Helper from "../utils/Helper";

var Req = {
  _ssnId: null,
  //_url: "http://192.168.3.206:8071/", // Local Address
  //_url: "https://localhost:6443/", // Local adress
  _url: "https://www.footmedlab.com:6443/", // Server adress
  _furl: "https://www.footmedlab.com:7443/",// File server address
  // _url: "https://localhost:6443/", // Server adress
  //_furl: "https://lcoalhost:7443/",// File server address
  //_furl: "https://localhost:7443/", // Server adress
  _login: true,
  // Builds base request object with complete header info.
  // If request id (rid) is not given, it will generate one.
  _makeHeader: async (createNewSsn) => {
    var rid;
    var r = Req;

    rid = uuid();

    return {
      rid: rid, // Request id
      tim: await r._makeTimeStamp(), // Timestamp
      src: await r._fetchSsnId(createNewSsn), // Source Name
      tar: "Edge", // Target Name
    };
  },

  _makeSqr: async (dta, hdr) => {
    var crp;
    var sqr = [];
    var likeFields = Glb.LikeFields;

    let counter = 0;
    for (var propName in dta) {
      if (counter > 0) sqr.push(" and ");

      if (likeFields.includes(propName)) {
        if (propName !== "ema") {
          sqr.push(propName);
          sqr.push(" ILIKE ");
          sqr.push("%" + dta[propName] + "%");
        } else {
          crp = new Crypt(hdr);
          crp.encode("%" + dta[propName] + "%", null);
          sqr.push("ema");
          sqr.push(" ILIKE ");
          sqr.push(crp.ema);
        }
      } else {
        if (propName === "act" || propName === "ook" || propName === "ods") {
          if (dta[propName] === -1) {
            sqr.push("(" + propName + "=");
            sqr.push(true);
            sqr.push("or " + propName + "=");
            sqr.push(false);
            sqr.push(")");
          } else {
            sqr.push(propName);
            sqr.push("=");
            sqr.push(dta[propName]);
          }
        } else if (propName === "typ") {
          if (dta[propName] === -1) {
            sqr.pop();
            delete dta[propName];
          }
          else {
            sqr.push(propName);
            sqr.push(" = ");
            sqr.push(dta[propName]);
          }
        } else {
          sqr.push(propName);
          sqr.push(" = ");
          sqr.push(dta[propName]);
        }
      }
      counter = counter + 1;
    }
    return sqr;
  },

  _decodeEma: async (dta, hdr) => {
    var crp;

    if (dta !== null && dta.length > 0) {
      let l = dta.length;
      crp = new Crypt(hdr);
      for (let i = 0; i < l; i++) {
        crp.decode(dta[i].ema, null);
        dta[i].ema = crp.ema;
      }
    }
  },

  // Session Control Statement
  // If there is no session Id this returns a random GUID.
  _fetchSsnId: function (createNew) {
    if (createNew) Req._ssnId = uuid();
    else if (Req._ssnId === null) {
      let response = localStorage.getItem("response");
      let resObj = JSON.parse(response);
      Req._ssnId = resObj.tar;
    }
    return Req._ssnId;
  },

  _makeTimeStamp: function () {
    var d = new Date();
    return (
      "" +
      d.getFullYear() +
      d.getMonth() +
      d.getDate() +
      d.getHours() +
      d.getMinutes() +
      d.getSeconds() +
      d.getMilliseconds()
    );
  },

  _excHandler: function (response) {
    let msg;
    var respMsg = response?.dta?.msg;
    if (respMsg) {

      if (respMsg === "E_EDGE_INV_ARG") {
        if (response.dta?.inf) {
          var lastThree = response.dta.inf.substr(response.dta.inf.length - 3);
          msg = lng.itm("EXC")?.E_EDGE_INV_ARG[lastThree];
        }
      }
      else {
        msg = lng.itm("EXC")[response.dta.tag];
      }

    }
    if (!msg)
      msg = lng.itm("EXC").UNHANDLED;
    alert(JSON.stringify(msg))


  },

  _send: async function (endPoint, rx, furl = false) {
    var path;
    var resp;
    var r;

    path = Req._url + endPoint;
    if (furl)
      path = Req._furl + endPoint;
    console.log("SENDING TO : " + path);
    console.log("REQUEST : /n", rx);
    resp = await axios.post(path, rx);

    r = resp?.data;
    console.warn(r);

    if (Glb.Debug)
      Glb.ReqLog[Glb.ReqLog.length] = {
        method: endPoint,
        req: JSON.stringify(rx, undefined, 4),
        res: JSON.stringify(r, undefined, 4),
      };

    if (typeof r?.tar === "undefined") {
      // TODO : exception handler.
    }

    console.warn("response -> " + endPoint, r);

    if (r.tar !== "irrelevant") Req._ssnId = r.tar;
    if (r.sta === "EXC") {
      //alert(Req._login)
      if (r.dta.tag === "E_MUST_LOGIN" && Req._login) {
        await Req._relogin(Req._login);
        rx.tim = await Req._makeTimeStamp();
        rx.src = await Req._fetchSsnId();
        return await Req._send(endPoint, rx);
      }
      else {
        Req._excHandler(r);
      }
    }
    if (r.sta === "ACK") {
      if (r.dta) {
        var dta = r.dta;
        if (dta?.length > 0) {
          if (dta[0].hasOwnProperty("ema")) {

            let l = dta.length;
            var hdr = {
              rid: rx.rid, // Request id
              tim: rx.tim, // Timestamp
              src: rx.src, // Source Name
              tar: rx.tar, // Target Name
            };
            var crp = new Crypt(hdr);
            for (let i = 0; i < l; i++) {
              crp.decode(dta[i].ema, null);
              dta[i].ema = crp.ema;
            }
            r.dta = dta;

          }
        }
      }
    }
    return r;
  },

  _relogin: async function (loginForce) {

    if (Req._login == false)
      return;

    Req._login = false;
    var rxStr = localStorage.getItem("rx");
    var rx = JSON.parse(rxStr);
    var hdr = {
      rid: rx.rid, // Request id
      tim: rx.tim, // Timestamp
      src: rx.src, // Source Name
      tar: rx.tar, // Target Name
    };
    var crp = new Crypt(hdr);
    crp.decode(rx.ema, rx.pwd);


    let loginDta = {
      ema: crp.ema,
      pwd: crp.pwd,
    };
    //alert("asdasdads" + JSON.stringify(loginDta))
    await Req.login(loginDta);
  },

  register: async function (data) {
    var hdr;
    var req;
    var crp;

    hdr = await Req._makeHeader(false);
    crp = new Crypt(hdr);
    crp.encode(data.ema, data.pwd);
    req = {
      ...hdr, //Header
      dta: {
        ema: crp.ema, //Email
        pwd: crp.pwd, //Password
        pno: data.pno, //Phone Number
        cid: data.cid, //Company ID
        nam: data.nam, //First Name
        sur: data.sur, //Last Name
        adm: data.adm, //Is Admin
      },
    };
    var res = await Req._send("register", req);
    // if (res) {
    //   if (res?.sta === "ACK") {
    //     var dta = req.dta;
    //     dta.ema = data.ema;
    //     dta.act = true;
    //     delete dta.pwd;
    //     if (Glb.Storage.allUsrs) {
    //       Glb.Storage.allUsrs.push(dta);
    //       localStorage.setItem("allUsrs", Glb.Storage.allUsrs);
    //     }
    //   }
    // }
    return res;
  },

  login: async function (data) {
    var hdr;
    var req;
    var res;

    hdr = await Req._makeHeader(true);

    //var lgn = the_Test(hdr) //go(hdr.rid, hdr.src, hdr.tim, hdr.tar, data.ema, data.pwd);
    const crp = new Crypt(hdr);
    //alert(JSON.stringify(data))
    crp.encode(data.ema, data.pwd);

    req = {
      ...hdr, //Header
      ema: crp.ema, //Email
      pwd: crp.pwd, //Password
    };
    res = await Req._send("login", req);
    if (res.sta === "ACK") {
      Req._login = true;
      Req._ssnId = res.tar;
      localStorage.setItem("rx", JSON.stringify(req));
      localStorage.setItem("response", JSON.stringify(res));
      var dta = res.dta;
      if (dta.adm === true) {
        hdr.src = res.tar;
        req = {
          ...hdr,
          sqr: ["act", "=", true, "or", "act", "=", false],
          skp: 0,
          lim: 100,
        };
        var comRes = await Req._send("comLst", req);
        if (comRes.sta === "ACK") {
          if (comRes.dta) {
            localStorage.setItem("allComs", JSON.stringify(comRes.dta));
            Glb.Storage.allComs = comRes.dta;
            Glb.Reload.com = false;
          }
        }

        var usrRes = await Req._send("usrLst", req);
        if (usrRes.sta === "ACK") {
          if (usrRes.dta) {
            localStorage.setItem("allUsrs", JSON.stringify(usrRes.dta));
            Glb.Storage.allUsrs = usrRes.dta;
            Glb.Reload.usr = false;
          }
        }
      } else {
        hdr.src = res.tar;
        req = {
          ...hdr,
          sqr: [
            "cid",
            "=",
            dta.cid,
            "and",
            "(act",
            "=",
            true,
            "or",
            "act",
            "=",
            false,
            ")",
          ],
          skp: 0,
          lim: 100,
        };
        usrRes = await Req._send("usrLst", req);
        if (usrRes.sta === "ACK") {
          if (usrRes.dta) {
            localStorage.setItem("allUsrs", JSON.stringify(usrRes.dta));
            Glb.Storage.allUsrs = usrRes.dta;
            Glb.Reload.usr = false;
          }
        }
      }
    }
    return res;
  },

  usrUpd: async function (data) {
    var hdr;
    var req;
    var crp;

    hdr = await Req._makeHeader(false);
    let iid = data.iid;
    var index = data.index;
    delete data.iid;
    delete data.index;

    var pureEma = data.ema;
    if (data.hasOwnProperty("ema")) {
      crp = new Crypt(hdr);
      crp.encode(data.ema, null);
      data.ema = crp.ema;
    }

    if (data.hasOwnProperty("pwd")) {
      crp = new Crypt(hdr);
      crp.encode(null, data.pwd);
      data.pwd = crp.pwd;
    }

    req = {
      ...hdr, //Header
      iid: iid, //User ID
      dta: data, //Fields and Values to Update
    };
    var res = await Req._send("usrUpd", req);

    if (res?.sta === "ACK") {
      var listName = "allUsrs";
      if (Glb.ListFiltered.usr) listName = "usrLst";
      if (Glb.Storage[listName].length > 0) {
        if (pureEma) {
          data.ema = pureEma;
        }
        var row = Glb.Storage[listName][index];
        Object.keys(data).map((item) => {
          row[item] = data[item];
        });
      }
    }
    return res;
  },

  usrDel: async function (data) {
    var hdr;
    var req;

    hdr = await Req._makeHeader(false);
    req = {
      ...hdr, //Header
      iid: data.iid, //Item ID
    };
    var res = await Req._send("usrDel", req);

    if (res.sta === "ACK") Glb.Reload.usr = true;

    return res;
  },

  usrLst: async function (data) {
    var hdr;
    var req;
    var dta = { ...data.dta };
    //var crp;

    let sqr = [];
    hdr = await Req._makeHeader(false);
    sqr = await Req._makeSqr(dta, hdr);

    req = {
      ...hdr, //Header
      skp: data.skp, //Skip from beginning of records
      lim: data.lim, //Limit of records to retrieve
      sqr: sqr, //Criteria Fields and Values
    };

    var res = await Req._send("usrLst", req);

    Glb.Storage.usrLst = res?.dta || [];
    Glb.Reload.usr = false;

    // if (res.sta === "ACK") {
    //   if (res?.dta !== null && res?.dta.length > 0) {
    //     let dta = res.dta;
    //     let l = dta.length;
    //     crp = new Crypt(hdr);

    //     for (let i = 0; i < l; i++) {
    //       crp.decode(dta[i].ema, null);
    //       dta[i].ema = crp.ema;
    //     }
    //   }
    // }

    return res;
  },

  comIns: async function (data) {
    var hdr;
    var req;
    var crp;

    hdr = await Req._makeHeader(false);
    crp = new Crypt(hdr);
    crp.encode(data.ema, null);
    data.ema = crp.ema;

    req = {
      ...hdr, //Header
      dta: data, //Company Name
    };
    var res = await Req._send("comIns", req);

    // if (res) {
    //   if (res?.sta === "ACK") {
    //     var dta = req.dta;
    //     dta.ema = data.ema;
    //     dta.act = true;
    //     if (Glb.Storage.allComs) {
    //       Glb.Storage.allComs.push(dta);
    //       localStorage.setItem("allComs", Glb.Storage.allComs);
    //     }
    //   }
    // }
    return res;
  },

  comUpd: async function (data) {
    var hdr;
    var req;
    var crp;

    var iid = data.iid;
    var index = data.index;
    delete data.index;
    delete data.iid;

    hdr = await Req._makeHeader(false);

    let pureEma = data.ema;
    if (data && data.hasOwnProperty("ema")) {
      crp = new Crypt(hdr);
      crp.encode(data.ema);
      data.ema = crp.ema;
    }

    req = {
      ...hdr, //Header
      iid: iid, //Item ID
      dta: data, //Fields and Values to Update
    };
    var res = await Req._send("comUpd", req);
    if (res?.sta === "ACK") {
      var listName = "allComs";
      if (Glb.ListFiltered.com) listName = "comLst";

      if (Glb.Storage[listName].length) {
        if (pureEma) {
          data.ema = pureEma;
        }
        var row = Glb.Storage[listName][index];

        Object.keys(data).map((item) => {
          row[item] = data[item];
        });
      }
    }
    return res;
  },

  comLst: async function (data) {
    var hdr;
    var req;
    var dta = { ...data.dta };
    //var crp;

    //alert(JSON.stringify(data))

    let sqr = [];
    let jqr = [];

    if (dta.hasOwnProperty("tid")) {
      // If we need to join
      jqr.push("c.cid = p.cid");
      jqr.push("and");
      jqr.push("p.tid");
      jqr.push("==");
      jqr.push(dta["tid"]);
      delete dta.tid;
    }

    hdr = await Req._makeHeader(false);
    sqr = await Req._makeSqr(dta, hdr);

    req = {
      ...hdr, //Header
      skp: data.skp, //Skip from beginning of records
      lim: data.lim, //Limit of records to retrieve
      sqr: sqr, //Criteria Fields and Values
      jqr: jqr,
    };

    var res = await Req._send("comLst", req);

    // if (res.sta === "ACK") {
    //   if (res?.dta !== null && res?.dta.length > 0) {
    //     let dta = res.dta;
    //     let l = dta.length;
    //     crp = new Crypt(hdr);

    //     for (let i = 0; i < l; i++) {
    //       crp.decode(dta[i].ema, null);
    //       dta[i].ema = crp.ema;
    //     }
    //   }
    // }
    //alert(JSON.stringify(res))
    Glb.Storage.comLst = res?.dta || [];

    Glb.Reload.com = false;



    return res;
  },

  patIns: async function (data) {
    var hdr;
    var req;
    var crp;

    hdr = await Req._makeHeader(false);

    crp = new Crypt(hdr);
    crp.encode(data.ema, null);
    data.ema = crp.ema;
    req = {
      ...hdr, //Header
      dta: data,
    };
    return await Req._send("patIns", req);
  },

  patUpd: async function (data) {
    var hdr;
    var req;
    var crp;

    var iid = data.iid;
    var index = data.index;
    delete data.index;
    delete data.iid;

    hdr = await Req._makeHeader(false);

    let pureEma = data.ema;
    if (data && data.hasOwnProperty("ema")) {
      crp = new Crypt(hdr);
      crp.encode(data.ema, null);
      data.ema = crp.ema;
    }

    req = {
      ...hdr, //Header
      iid: iid, //Item ID
      dta: data, //Fields and Values to Update
    };

    var res = await Req._send("patUpd", req);
    if (res?.sta === "ACK") {
      if (Glb.Storage.patLst) {
        if (pureEma) {
          data.ema = pureEma;
        }
        //alert(Glb.ListFiltered.pat)
        if (Glb.ListFiltered.pat) {
          var row = Glb.Storage.patLst[index];
          Object.keys(data).map((item) => {
            row[item] = data[item];
          });
        }
      }
    }
    return res;
  },

  patLst: async function (data) {
    var hdr;
    var req;
    var dta = { ...data.dta };
    //var crp;

    var sqr = [];

    hdr = await Req._makeHeader(false);
    sqr = await Req._makeSqr(dta, hdr);

    req = {
      ...hdr, //Header
      skp: data.skp, //Skip from beginning of records
      lim: data.lim, //Limit of records to retrieve
      sqr: sqr, //Criteria Fields and Values
    };
    var res = await Req._send("patLst", req);

    Glb.Storage.patLst = res?.dta || [];
    Glb.Reload.pat = false;

    // if (res.sta === "ACK") {
    //   if (res?.dta !== null && res?.dta.length > 0) {
    //     let dta = res.dta;
    //     let l = dta.length;
    //     crp = new Crypt(hdr);

    //     for (let i = 0; i < l; i++) {
    //       crp.decode(dta[i].ema, null);
    //       dta[i].ema = crp.ema;
    //     }
    //     res.dta = dta;
    //   }
    // }

    return res;
  },

  patLstByNam: async function (data) {
    var hdr;
    var req;
    var dta = data.dta;

    hdr = await Req._makeHeader(false);

    req = {
      ...hdr, //Header
      skp: data.skp, //Skip from beginning of records
      lim: data.lim, //Limit of records to retrieve
      dta: dta, //Criteria Fields and Values
    };
    var res = await Req._send("patLstByNam", req);
    return res;
  },

  ordIns: async function (data) {
    var hdr;
    var req;

    hdr = await Req._makeHeader(false);
    req = {
      ...hdr, //Header
      dta: data,
    };
    return await Req._send("ordIns", req);
  },

  ordUpd: async function (data) {
    var hdr;
    var req;
    var iid = data.oid;
    var index = data.index;
    delete data.index;
    delete data.oid;
    hdr = await Req._makeHeader(false);
    req = {
      ...hdr, //Header
      iid: iid, //Item ID
      dta: data, //Fields and Values to Update
    };
    var res = await Req._send("ordUpd", req);
    if (res?.sta === "ACK") {
      if (Glb.Storage.ordLst) {
        var row = Glb.Storage.ordLst[index];
        Object.keys(data).map((item) => {
          row[item] = data[item];
        });
        if (row.hasOwnProperty("ook")) {
          if (row.ook === false)
            row.oct = ""
          else
            row.oct = Helper.makeTimeStamp();
        }

        //Glb.Storage.ordLst[index] = row;
      }
    }
    return res;
  },

  ordLst: async function (data) {
    var hdr;
    var req;
    var dta = { ...data.dta };

    var sqr = [];

    hdr = await Req._makeHeader(false);
    sqr = await Req._makeSqr(dta, hdr);

    req = {
      ...hdr, //Header
      skp: data.skp, //Skip from beginning of records
      lim: data.lim, //Limit of records to retrieve
      sqr: sqr, //Criteria Fields and Values
    };
    var res = await Req._send("ordLst", req);

    Glb.Storage.ordLst = res?.dta || [];
    Glb.Reload.ord = false;

    return res;
  },

  ordDel: async function (data) {
    var hdr;
    var req;

    hdr = await Req._makeHeader(false);
    req = {
      ...hdr, //Header
      iid: data.iid, //Item ID
    };
    var res = await Req._send("ordDel", req);

    if (res.sta === "ACK") Glb.Reload.ord = true;

    return res;
  },

  ordOk: async function (data) {
    var hdr;
    var req;

    hdr = await Req._makeHeader(false);
    req = {
      ...hdr, //Header
      iid: data.iid, //Item ID
    };
    var res = await Req._send("ordOk", req);

    if (res.sta == "ACK") Glb.Reload.ord = true;

    return res;
  },

  ordCan: async function (data) {
    var hdr;
    var req;

    hdr = await Req._makeHeader(false);
    req = {
      ...hdr, //Header
      iid: data.iid, //Item ID
    };
    var res = await Req._send("ordCan", req);

    if (res.sta === "ACK") Glb.Reload.ord = true;

    return res;
  },

  diaIns: async function (data) {
    var hdr;
    var req;

    hdr = await Req._makeHeader(false);
    req = {
      ...hdr, //Header
      dta: data,
    };

    return await Req._send("diaIns", req);
  },

  diaUpd: async function (data) {
    var hdr;
    var req;
    let iid = data.did;
    var index = data.index;
    delete data.index;
    delete data.did;

    hdr = await Req._makeHeader(false);
    req = {
      ...hdr, //Header
      iid: iid, //Item ID
      dta: data, //Fields and Values to Update
    };
    var res = await Req._send("diaUpd", req);
    if (res?.sta === "ACK") {
      // alert("DIALST" + JSON.stringify(Glb.Storage.diaLst))
      if (Glb.Storage.diaLst && Glb.ListFiltered.dia) {
        if (!index) {
          index = Glb.Storage.diaLst.findIndex(d => d.did === iid)
        }
        if (index) {
          //alert("asdasd")
          Glb.Reload.dia = true;
          var row = Glb.Storage.diaLst[index];
          Object.keys(data).map((item) => {
            row[item] = data[item];
          });
        }
      }

    }
    return res;
  },

  diaLst: async function (data) {
    var hdr;
    var req;
    var dta = { ...data.dta };
    hdr = await Req._makeHeader(false);

    var sqr = [];
    var jqr = [];
    var hnam = dta.hasOwnProperty("nam");
    var hsur = dta.hasOwnProperty("sur");

    if (hnam) {
      // If we need to join
      jqr.push("d.pid = p.pid");
      jqr.push(" and ");
      jqr.push("p.nam");
      jqr.push(" ILIKE ");
      jqr.push("%" + dta["nam"] + "%");
      delete dta.nam;
    }

    if (hsur) {
      if (jqr.length === 0) {
        jqr.push("d.pid = p.pid");
      }
      jqr.push(" and ");
      jqr.push("p.sur");
      jqr.push(" ILIKE ");
      jqr.push("%" + dta["sur"] + "%");
      delete dta.sur;
    }

    if (hnam || hsur) {

    } else sqr = await Req._makeSqr(dta, hdr);

    req = {
      ...hdr, //Header
      skp: data.skp, //Skip from beginning of records
      lim: data.lim, //Limit of records to retrieve
      sqr: sqr, //Criteria Fields and Values
      jqr: jqr,
    };
    var res = await Req._send("diaLst", req);
    //alert(Glb.ListFiltered.dia)
    if (Glb.ListFiltered.dia) {
      Glb.Storage.diaLst = res?.dta || [];
      Glb.Reload.dia = false;
    }

    return res;
  },

  prdIns: async function (data) {
    var hdr;
    var req;

    hdr = await Req._makeHeader(false);
    req = {
      ...hdr, //Header
      dta: data, //Product Name
      //typ: data.typ, //Product Type
      // obj: data.obj, //Product JSON
    };

    return await Req._send("prdIns", req);
  },

  prdUpd: async function (data) {
    var hdr;
    var req;
    var iid = data.iid;
    var index = data.index;

    delete data.index;
    delete data.iid;
    hdr = await Req._makeHeader(false);
    req = {
      ...hdr, //Header
      iid: iid, //Item ID
      dta: data, //Fields and Values to Update
    };
    // return await Req._send("prdUpd", req);
    var res = await Req._send("prdUpd", req);
    if (res?.sta === "ACK") {
      if (Glb.Storage.prdLst) {
        var row = Glb.Storage.prdLst[index];
        Object.keys(data).map((item) => {
          row[item] = data[item];
        });
      }
    }
    return res;
  },

  prdLst: async function (data) {
    var hdr;
    var req;
    var dta = { ...data.dta };

    let sqr = []; //Req._makeSqr(dta);
    let jqr = [];

    if (dta.hasOwnProperty("cid")) {
      jqr.push("p.tid = c.tid");
      jqr.push("and");
      jqr.push("c.cid");
      jqr.push("==");
      jqr.push(dta["cid"]);
      delete dta.cid;
    }

    hdr = await Req._makeHeader(false);
    sqr = await Req._makeSqr(dta, hdr);
    req = {
      ...hdr, //Header
      skp: data.skp, //Skip from beginning of records
      lim: data.lim, //Limit of records to retrieve
      sqr: sqr, //Criteria Fields and Values
      jqr: jqr,
    };
    var res = await Req._send("prdLst", req);

    if (Glb.ListFiltered.prd) {
      Glb.Storage.prdLst = res?.dta || [];
      Glb.Reload.prd = false;
    }

    return res;
  },

  prdRel: async function (data) {
    var hdr;
    var req;
    hdr = await Req._makeHeader(false);
    req = {
      ...hdr, //Header
      iid: data.iid, //Item ID
      dta: {
        prds: data.prds,
      }, //Company ID List
    };
    return await Req._send("prdRel", req);
  },

  prdRelDel: async function (data) {
    var hdr;
    var req;
    hdr = await Req._makeHeader(false);
    req = {
      ...hdr, //Header
      dta: data.dta //Item ID
    };
    return await Req._send("prdRelDel", req);
  },

  GetFileNames: async function (data) {
    var hdr;
    var req;
    hdr = await Req._makeHeader(false);
    req = {
      ...hdr, //Header
      dta: data //Item ID
    };
    return await Req._send("GetFileNames", req, true);
  },

  DownloadFiles: async function (data) {
    var r = Req;
    var hdr;
    var req;
    hdr = await Req._makeHeader(false);
    req = {
      ...hdr, //Header
      dta: data //Item ID
    };
    //var res = await axios.post(r._furl + "DownloadFiles", req);

    axios({
      url: r._furl + "DownloadFiles", //your url
      method: 'POST',
      data: req,
      responseType: 'blob', // important
    }).then((response) => {
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      var filename = "";
      var disposition = response.headers["content-disposition"];
      if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
    //return res;
    //alert(JSON.stringify(resp))
  },

  downloadOrderFiles: async (dta) => {

    var hdr = await Req._makeHeader(false);

    var setProgress = dta.setProgress;
    var rx = {
      ...hdr,
      "dta": {
        "cid": dta.cid,
        "oid": dta.oid
      }
    }

    axios({
      url: Req._furl + "DownloadFiles", //your url
      method: 'POST',
      data: rx,
      responseType: 'blob', // important
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log(progressEvent.lengthComputable)
        console.log(percentCompleted);
        setProgress(percentCompleted)
      }
    }).then((response) => {
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      var filename = "";
      var disposition = response.headers["content-disposition"];
      if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });

  },

  execute: async (path, data) => {
    var gofnc = Req[path.substr(1)];

    //if (Helper.isFunction(gofnc))
    return await gofnc(data);
  },
};

export default Req.execute;
