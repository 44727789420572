import { Button, Grid } from '@material-ui/core';
import React from 'react'
import CancelButton from './CancelButton';
import { lng } from '../Tore/Sys'

export default function CancelAndSubmitButton(props) {

    const onClick = props.onClick;
    const handleClose = props.handleClose;
    var submit = true;


    if (props?.submitable === false)
        submit = false;


    return (
        <Grid item >
            <Grid container item spacing={1} style={{ justifyContent: "center" }} >
                <Grid item>
                    <CancelButton handleClose={handleClose} />
                </Grid>
                <Grid item>
                    {
                        (submit) &&
                        <Button type="submit" variant="contained" color="primary" size="small" onClick={onClick}  >
                            {lng.itm("M_INP_SBM")}
                        </Button>
                    }
                </Grid>
            </Grid>
        </Grid>
    );

}