import React from 'react';
import { Grid, IconButton, TableCell, TableRow } from '@material-ui/core';
import EditButton from '../EditButton';
import InfoButton from '../InfoButton';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import RemoveButton from '../RemoveButton';
import Helper from '../../utils/Helper';
import Glb from '../../global/Glb';

class DataTableRow extends React.Component {
    handleClick = () => {
        this.props.onRowClick(this.props.row);
    }

    render() {

        const { editPath, infoPath, diaPath, columns, row, index, classes, erasable, editable, setReRender, rowIndex, typeId, setRows, keyExtractor } = this.props;

        const hasInfo = infoPath !== undefined; // check if there is an information path(page) : sometimes table rows are not enough to show entire data XD

        let style = index % 2 === 0 ? { background: "#fdffe0" } : { background: "white" }
        const centeredPropNames = ["act", "dia", "typ", "ook", "rel", "oct", "odt", "oet", "det", "ods"]

        var ook = false;
        var isAdmin = Glb.Storage.usr.adm;

        if (!isAdmin) {
            if (row?.ook) {
                if (row.ook === true)
                    ook = true;
            }
        }

        return (
            <TableRow style={style}>
                <TableCell className={classes.tableCell}>
                    <Grid container direction="row" >

                        {(editable) &&
                            <Grid item>
                                <EditButton row={row} path={editPath} history={this.props.history} setReRender={setReRender} rowIndex={rowIndex} editable={!ook} ></EditButton>
                            </Grid>
                        }
                        {(erasable && (!ook || isAdmin)) &&
                            <Grid item>
                                <RemoveButton typeId={typeId} cid={row?.cid} oid={row?.oid} uid={row?.uid} setRows={setRows} keyExtractor={keyExtractor} visible={!ook} />
                            </Grid>
                        }
                    </Grid>
                </TableCell>

                {columns.map((header) => {
                    let cell = undefined
                    // eslint-disable-next-line
                    Object.entries(row).map(([key, value]) => {
                        (() => {

                            if (header.for === "dia" && key === "did" && value !== '00000000-0000-0000-0000-000000000000') {

                                cell = (
                                    <TableCell className={classes.tableCell} align={centeredPropNames.includes(header.for) ? ("center") : ("left")}>
                                        <InfoButton row={row} path={diaPath} setReRender={setReRender} page={this.props.page}/>
                                    </TableCell>
                                )
                                return;
                            }
                            else if (header.for === "rel") {
                                cell = (
                                    <TableCell className={classes.tableCell} align={centeredPropNames.includes(header.for) ? ("center") : ("left")}>
                                        <InfoButton row={row} path={diaPath} setReRender={setReRender} />
                                    </TableCell>
                                )
                            }
                            else if (key === header.for) {
                                if (key === "act" || key === "ook" || key === "ods") {
                                    cell = (
                                        <TableCell className={classes.tableCell} onClick={hasInfo ? (this.handleClick) : (undefined)} align={centeredPropNames.includes(header.for) ? ("center") : ("left")}>
                                            {(value) ? <CheckIcon /> : <ClearIcon />}
                                        </TableCell>
                                    )
                                }
                                else if (key === "pno") {
                                    cell = (
                                        <TableCell className={classes.tableCell} onClick={hasInfo ? (this.handleClick) : (undefined)} align={centeredPropNames.includes(header.for) ? ("center") : ("left")}>
                                            {Helper.maskPhone(value)}
                                        </TableCell>
                                    )
                                }
                                else if (key === "adr") {
                                    cell = (
                                        <TableCell className={classes.tableCell} onClick={hasInfo ? (this.handleClick) : (undefined)} align={centeredPropNames.includes(header.for) ? ("center") : ("left")} style={{ width: '38%' }}>
                                            {value}
                                        </TableCell>
                                    )
                                }
                                else if (key === "oet" || key === "odt" || key === "oct") {
                                    cell = (
                                        <TableCell className={classes.tableCell} onClick={hasInfo ? (this.handleClick) : (undefined)} align={centeredPropNames.includes(header.for) ? ("center") : ("left")}>
                                            {Helper.timeStampToDate(value)}
                                        </TableCell>
                                    )
                                }
                                else
                                    cell = (<TableCell className={classes.tableCell} onClick={hasInfo ? (this.handleClick) : (undefined)} align={centeredPropNames.includes(header.for) ? ("center") : ("left")} >{value}</TableCell>);
                            }

                        })()
                    })
                    if (cell !== undefined)
                        return cell;
                    else
                        return (<TableCell className={classes.tableCell} onClick={hasInfo ? (this.handleClick) : (undefined)} align={centeredPropNames.includes(header.for) ? ("center") : ("left")}>-</TableCell>)
                })}
            </TableRow>
        );
    }
}

export default DataTableRow;