import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import { lng } from "../../Tore/Sys";
import CustomPaper from "../CustomPaper";
import CancelAndSubmitButton from "../CancelAndSubmitButton";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import HttpPost from "../HttpPost";
import Glb from "../../global/Glb";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  formCenter: {
    alignItems: "center",
    justify: "center",
  },
}));

const inputProps = {
  margin: "dense",
  variant: "outlined",
  size: "small",
};

export default function ProductRegister() {
  const classes = useStyles();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      nam: "",
    },
    onSubmit: async (values) => {
      const product = values;
      let nam = values.nam;
      let response = await HttpPost("/prdIns", product);
      if (response) {
        if (response?.sta === "ACK") {
          var tid = response?.dta.tid;
          values.tid = tid;
          values.act = true;
          values.nam = nam;
          Glb.Reload.com = false;
          Glb.RegisterSuccess.prd = true;
          if (Glb.Storage.prdLst) {
            Glb.Storage.prdLst.push(values);
          } else {
            Glb.Storage.prdLst = [];
            Glb.Storage.prdLst.push(values);
          }

          history.goBack();
        }
      }
    },
  });

  return (
    <Grid container spacing={5}>
      <Grid item sm={2} />
      <Grid
        container
        className={classes.formCenter}
        direction="column"
        spacing={2}
      >
        <CustomPaper>
          <h3> {lng.itm("M_HDR_PRD_INS")} </h3>
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction="column">
              <TextField
                id={lng.itm("M_INP_NAM").id}
                label={lng.itm("M_INP_NAM").label}
                {...inputProps}
                onChange={formik.handleChange}
                required
              />

              <TextField
                id={lng.itm("M_INP_NAMEN").id}
                label={lng.itm("M_INP_NAMEN").label}
                {...inputProps}
                onChange={formik.handleChange}
                required
              />
            </Grid>

            <Grid item>
              <CancelAndSubmitButton />
            </Grid>
          </form>
        </CustomPaper>
      </Grid>
      <Grid item sm={2} />
    </Grid>
  );
}
