const languages = {
  TR: {
    M_HDR_CMP: "NESA",
    M_LBL_INSOLE: "Tabanlık",
    M_LBL_SLIPPER: "Terlik",
    M_LBL_REPAIR: "Tamir",
    M_LBL_ESTIM_DELIVER_DATE: "Teslim Edileceği Gün *",
    M_LBL_ESTIM_DELIVER_TIME: "Teslim Edileceği Saat",
    M_HDR_COM_INS: "Firma Ekle",
    M_HDR_COM_UPD: "Firma Bilgilerini Düzenle",
    M_HDR_USR_INS: "Kullanıcı Kaydet",
    M_HDR_USR_UPD: "Kullanıcı Bilgilerini Düzenle",
    M_HDR_PAT_INS: "Hasta Kaydet",
    M_HDR_PAT_UPD: "Hasta Bilgilerini Düzenle",
    M_HDR_PRD_INS: "Yeni Ürün Ekle",
    M_HDR_PRD_UPD: "Ürün Bilgilerini Düzenle",
    M_HDR_PRD_COM_INS: "Firmaya Çoklu Ürün Tanımla",
    M_HDR_DIA_PAIN_AREAS: "Ağrı Bölgeleri",
    M_HDR_DIA_PAT_MEASURES: "Hasta Ölçüleri",
    M_HDR_DIA_IND: "Tanı ve İndikasyonlar",
    M_HDR_DIA_INS: "Yeni Tanı Ekle",
    M_HDR_DIA_INF: "Tanı Bilgileri Görüntüle",
    M_HDR_DIA_UPD: "Tanı Bilgileri Güncelle",
    M_HDR_PAIN_AREAS: "Ağrı Bölgeleri",
    M_HDR_ORD_INS: "Yeni Sipariş Ekle",
    M_HDR_ORD_UPD: "Siparişi Düzenle",
    M_TBL_PRD_COM_REL_LBL: "Tipinin Tanımlı Olduğu Firmalar",
    M_MUL_SEL: "Çoklu Seçim",
    M_ALR_REG_SUCCESS: "Kayıt Başarılı.",
    M_ALR_EDT_SUCCESS: "Güncelleme Başarılı.",
    M_CHK_ORD_DELIVERY_STATUS : "Teslim Durumu", // Delivery Status
    M_CHK_ORD_OOK: "Onayla",
    M_CHK_ORD_OOK_INFO : "Onaylanmış",
    M_LBL_DROPZONE: "Dosyaları sürükleyiniz ya da tıklayınız.",
    M_BTN_SELECT_FILE: "Dosya Seç",
    M_LBL_FILE: "Dosya",
    M_LBL_DOWNLOAD: "Sipariş Dosyalarını İndir",
    M_LBL_FILES: "Dosyalar",
    M_LBL_DELETE_ALL: "Tümünü Sil",
    M_LBL_UPLOAD: "Yükle",
    M_LBL_ACT: "Aktif",
    M_LBL_INACT: "Pasif",
    M_HDRS_USR: [
      // {
      //   label: "TANILAR",
      //   href: "/diagnosis",
      // },
      {
        label: "SİPARİŞLER",
        href: "/orders",
      },
      {
        label: "HASTALAR",
        href: "/patients",
      },
      {
        label: "ÇIKIŞ",
        href: "/logout",
      },
    ],
    M_HDRS_ADM: [
      {
        label: "KURUMLAR",
        href: "/companies",
      },
      {
        label: "KULLANICILAR",
        href: "/users",
      },
      {
        label: "ÜRÜNLER",
        href: "/products",
      },
      {
        label: "TANILAR",
        href: "/diagnosis",
      },
      {
        label: "SİPARİŞLER",
        href: "/orders",
      },
      {
        label: "HASTALAR",
        href: "/patients",
      },
      {
        label: "ÇIKIŞ",
        href: "/logout",
      },
    ],
    M_HDRS_GUE: [
      {
        label: "GİRİŞ",
        href: "/login",
      },
    ],
    M_INP_EMA: {
      id: "ema",
      label: "E-Posta",
    },
    M_INP_PWD: {
      id: "pwd",
      label: "Şifre",
    },
    M_INP_NAM: {
      id: "nam",
      label: "İsim",
    },
    M_INP_NAMEN: {
      id: "namen",
      label: "İsim (İngilizce)",
    },
    M_INP_SUR: {
      id: "sur",
      label: "Soyisim",
    },
    M_INP_COM_NAM: {
      id: "nam",
      label: "Firma adı",
    },
    M_INP_PAT_NAM: {
      id: "nam",
      label: "Hasta adı",
    },
    M_INP_PAT_SUR: {
      id: "sur",
      label: "Hasta soyadı",
    },
    M_INP_PNO: {
      id: "pno",
      label: "Telefon",
    },
    M_INP_COM: {
      id: "cid",
      label: "Firma",
    },
    M_INP_USR: {
      id: "uid",
      label: "Kullanıcı",
    },
    M_INP_PAT: {
      id: "pid",
      label: "Hasta",
    },
    M_INP_DIA: {
      id: "did",
      label: "Tanı",
      warning: "Lütfen bir tanı oluşturunuz."
    },
    M_INP_ADR: {
      id: "adr",
      label: "Adres",
    },
    M_INP_ACT: {
      id: "act",
      label: "Etkin",
    },
    M_INP_OOK: {
      id: "ook",
      label: "Onay",
    },
    M_INP_MEA: {
      id: "measurer",
      label: "Ölçü alan kişi",
    },
    M_INP_ODS: {
      id: "ods",
      label: "Teslim",
      items: [
        {
          value: -1,
          text: "Tümü",
        },
        {
          value: true,
          text: "Evet",
        },
        {
          value: false,
          text: "Hayır",
        },
      ],
    },
    M_INP_PRD: {
      id: "tid",
      label: "Ürün",
    },
    M_INP_OET: {
      id: "oet",
      label: "Oluşturulma Tarihi",
    },
    M_INP_ODT: {
      id: "odt",
      label: "Teslim Tarihi",
    },
    M_INP_ODN: {
      id: "odn",
      label: "Teslimat notu",
    },
    M_INP_ONT: {
      id: "ont",
      label: "Sipariş notu",
    },
    M_INP_TYP: {
      id: "typ",
      label: "Tür",
    },
    M_INP_TYP_ITMS: [
      {
        value: -1,
        text: "Tümü"
      },
      {
        value: "Insole",
        text: "Tabanlık"
      },
      {
        value: "Slipper",
        text: "Terlik"
      },
      {
        value: "Repair",
        text: "Tamir"
      }
    ],
    M_INP_OBJ: {
      id: "obj",
      label: "EK ÖZELLİKLER",
    },
    M_INP_DDM: {
      id: "ddm",
      label: "Tanı Notu",
    },
    M_INP_ACT_ITMS: [
      {
        value: -1,
        text: "Tümü",
      },
      {
        value: true,
        text: "Aktif",
      },
      {
        value: false,
        text: "Pasif",
      },
    ],
    M_INP_OOK_ITMS: [
      {
        value: -1,
        text: "Tümü",
      },
      {
        value: true,
        text: "Evet",
      },
      {
        value: false,
        text: "Hayır",
      },
    ],
    M_INP_INSOLE_FOOT_TYPE: {
      // AYAKKABI TIPI
      label: "Ayakkabı Tipi *",
      id: "stp",
      items: [
        {
          value: 0,
          text: "Günlük",
        },
        {
          value: 1,
          text: "İş Ayakkabısı",
        },
        {
          value: 2,
          text: "Spor-Basketbol",
        },
        {
          value: 3,
          text: "Spor-Futbol Krampon",
        },
        {
          value: 4,
          text: "Spor-Tenis",
        },
        {
          value: 5,
          text: "Spor-Voleybol",
        },
        {
          value: 6,
          text: "Diğer",
        },
      ],
    },
    M_INP_INSOLE_HARDNESS: {
      label: "Tabanlık Sertliği *",
      id: "ihs",
      items: [
        {
          value: 0,
          text: "Diabetik",
        },
        {
          value: 1,
          text: "Yumuşak",
        },
        {
          value: 2,
          text: "Arasert",
        },
        {
          value: 3,
          text: "Arasert Flex",
        },
        {
          value: 4,
          text: "Sert",
        },
        {
          value: 5,
          text: "Ekstra Sert (Plastik)",
        },
        {
          value: 6,
          text: "Sporcu Malzeme",
        },
      ],
    },
    // TABANLIK TIPI
    M_INP_INSOLE_TYPES: {
      label: "Tabanlık Tipi *",
      id: "tid",
      items: [
        // {
        //   value: "0699b60e-8145-44d2-9176-111d4cd16eeb",
        //   text: "CadCam",
        // },
        // {
        //   value: "9e6423cd-b4a0-4cde-8118-c58727b032f1",
        //   text: "Gait Plate",
        // },
        // {
        //   value: "38db3bb1-b663-432b-8610-ed029eb385c9",
        //   text: "Ucbl",
        // },
        // {
        //   value: "73c95e29-7550-4d27-bdfd-35ae3e395219",
        //   text: "Hallux Limitus",
        // },
        // {
        //   value: "297e0310-6212-403a-8073-161043d17850",
        //   text: "Hallux Rijidus",
        // },
        // {
        //   value: "09220d57-1e41-49ed-8b02-88ca8fc190cd",
        //   text: "Diabetik",
        // },
        // {
        //   value: "c5d77896-5b7f-403b-b339-d3c4e42b1df3",
        //   text: "Whitman",
        // },
        // {
        //   value: "92794769-e41d-4a54-bfc5-d57ea164028b",
        //   text: "Köpük",
        // },
        // {
        //   value: "bc1652bf-55b0-4cee-9d17-fe66380d55af",
        //   text: "Karbon",
        // },
        // {
        //   value: "56680e36-bf52-452d-a918-4e56f525b6d4",
        //   text: "Alçı",
        // },
        // {
        //   value: "301f77d3-deb1-4c86-b270-99a866c5565b",
        //   text: "Senserimotor",
        // },
        // {
        //   value: "a6bb5c31-1e3a-460e-bc7f-a903812565f1",
        //   text: "Dinamik",
        // },
        // {
        //   value: "2ec8ed53-e3e4-4564-ac58-ad4b44f5ba59",
        //   text: "Control Max 3 Sert",
        // },
        // {
        //   value: "1a45c989-446d-4878-af01-724c9c780f07",
        //   text: "Control Max 3 Arasert",
        // },
        // {
        //   value: "247038e2-0da7-42cd-b572-cd12d58fc467",
        //   text: "Slim - Capron",
        // },
      ],
    },
    M_INP_INSOLE_LEFT: {
      // Olusturulmasi istenen tabanlik icin, tabanligin kullanilacagi urun birakildi mi
      label: "Firmaya Bırakılan Ürün *",
      id: "ilf",
      items: [
        {
          value: 0,
          text: "Yok",
        },
        {
          value: 1,
          text: "Ayakkabı",
        },
        {
          value: 2,
          text: "Terlik",
        },
        {
          value: 3,
          text: "Sonra Bırakılacak",
        },
      ],
    },
    // tabanlık kaplama tercihleri
    M_INP_INSOLE_COVERING_CHOICES: {
      label: "Kaplama Tercihi *",
      id: "cch",
      items: [
        {
          value: 0,
          text: "Alcantara-Siyah",
        },
        {
          value: 1,
          text: "Alcantara-Krem",
        },
        {
          value: 2,
          text: "Alcantara-Gri",
        },
        {
          value: 11,
          text: "Alcantara-Kahverengi",
        },
        {
          value: 3,
          text: "Kaygan-Siyah",
        },
        {
          value: 4,
          text: "Kaygan-Füme",
        },
        {
          value: 5,
          text: "Kaygan-Mavi",
        },
        {
          value: 6,
          text: "Kaygan-Pembe",
        },
        {
          value: 7,
          text: "Kaygan-Mor",
        },
        {
          value: 8,
          text: "Kaygan-Kahverengi",
        },
        {
          value: 9,
          text: "Kaygan-Açık Bej",
        },
        {
          value: 10,
          text: "Kaygan-Koyu Bej",
        },
        {
          value: 12,
          text: "Kaygan-Yeşil",
        },
        {
          value: 13,
          text: "Kaygan-Mor",
        },
      ],
    },
    M_INP_INSOLE_STAGES: {
      label: "Aşama Tercihi *",
      id: "spf",
      items: [
        {
          value: 0,
          text: "Yok",
        },
        {
          value: 1,
          text: "Yumuşak",
        },
        {
          value: 2,
          text: "Ekstra Yumuşak",
        },
      ],
    },
    M_INP_SLIPPER_TYPES: {
      label: "Terlik Tipi *",
      id: "slp",
      items: [
        {
          value: 0,
          text: "Çift Bantlı",
        },
        {
          value: 1,
          text: "Üç Bantlı",
        },
        {
          value: 2,
          text: "Parmak Arası",
        },
        {
          value: 5,
          text: "Y Tip Parmak Arası", // Flip-flop
        },
        {
          value: 3,
          text: "Sandalet",
        },
        {
          value: 4,
          text: "Kapalı Model",
        },
      ],
    },
    M_INP_SLIPPER_LEATHER_TYPES: {
      id: "ltp",
      label: "Deri Tipi *",
      items: [
        {
          value: 0,
          text: "Orijinal - Siyah",
        },
        {
          value: 1,
          text: "Orijinal - Yeşil",
        },
        {
          value: 2,
          text: "Orijinal - Mor",
        },
        {
          value: 6,
          text: "Orijinal - Siyah Pütürlü",
        },
        {
          value: 7,
          text: "Orijinal - Siyah Pütürlü Sert",
        },
        {
          value: 8,
          text: "Orijinal - Mavi",
        },
        {
          value: 9,
          text: "Orijinal - Lacivert",
        },
        {
          value: 3,
          text: "Suni - Siyah",
        },
        {
          value: 4,
          text: "Suni - Lacivert",
        },
        {
          value: 5,
          text: "Suni - Krem",
        },
        {
          value: 10,
          text: "Suni - Pembe",
        },
        {
          value: 11,
          text: "Suni - Sarı",
        },
        {
          value: 12,
          text: "Suni - Kırmızı",
        },
        {
          value: 13,
          text: "Suni - Bronz",
        },
        {
          value: 14,
          text: "Suni - Turuncu",
        },
        {
          value: 15,
          text: "Suni - Siyah",
        },
        {
          value: 16,
          text: "Keçe - Yeşil",
        },
        {
          value: 17,
          text: "Keçe - Siyah",
        },
        {
          value: 18,
          text: "Keçe - Mavi",
        },
        {
          value: 19,
          text: "Keçe - Kırmızı",
        },
        {
          value: 20,
          text: "Keçe - Gri",
        }
      ],
    },
    M_INP_SLIPPER_MATERIAL_COLOR: {
      id: "mcl",
      label: "Materyal Rengi *",
      items: [
        {
          value: 0,
          text: "Mavi",
        },
        {
          value: 1,
          text: "Siyah",
        },
        {
          value: 2,
          text: "Turuncu",
        },
        {
          value: 3,
          text: "Gri",
        },
        {
          value: 4,
          text: "Yeşil",
        },
        {
          value: 5,
          text: "Krem Rengi (Bej)",
        },
      ],
    },
    M_INP_SLIPPER_TREAD_COLOR: {
      // Terlik taban rengi
      id: "tcl",
      label: "Alt Taban Rengi *",
      items: [
        {
          value: 0,
          text: "Beyaz",
        },
        {
          value: 1,
          text: "Siyah",
        },
      ],
    },
    M_INP_SLIPPER_FILE_TYPES: {
      id: "ftp",
      label: "Alçı, Köpük Ölçüsü, 3D Tarama Dosyası Var mı?",
      items: [
        {
          value: 0,
          text: "Yok",
        },
        {
          value: 1,
          text: "Alçı",
        },
        {
          value: 2,
          text: "Köpük Ölçüsü",
        },
        {
          value: 3,
          text: "3D Tarama Dosyası",
        },
      ],
    },
    M_INP_LGN: "GİRİŞ YAP",
    M_INP_SBM: "KAYDET",
    M_INP_UPD: "KAYDET",
    M_INP_CAN: "İPTAL",
    M_INP_PAG: {
      id: "pag",
      label: "Sayfa",
    },
    M_INP_WDG: {
      labelL: "Sol Ayak Kama",
      labelR: "Sağ Ayak Kama",
      heelMedLbl: "Topuktan Medial (mm)",
      heelLatLbl: "Topuktan Lateral (mm)",
      frntMedLbl: "Önden Medial (mm)",
      frntLatLbl: "Önden Lateral (mm)",
      fullMedLbl: "Komple Medial (mm)",
      fullLatLbl: "Komple Lateral (mm)",
    },
    M_INP_SHR: {
      labelL: "Sol Ayak Kısalık",
      labelR: "Sağ Ayak Kısalık",
      heel: "Topuktan (mm)",
      frnt: "Önden (mm)",
      full: "Komple (mm)"
    },
    M_INP_A: {
      title: "A Ölçüsü",
      labelLA: "Sol A (cm) ",
      labelRA: "Sağ A (cm) "
    },
    M_INP_B: {
      title: "B Ölçüsü",
      labelLA: "Sol B (cm) ",
      labelRA: "Sağ B (cm) "
    },
    M_INP_PAT_INF: {
      hei: "Boy (cm)",
      wei: "Ağırlık(kg)",
      fts: "Ayakkabı Numarası",
      fls: "Sol Ayak Boyu (cm)",
      frs: "Sağ Ayak Boyu (cm)",
      mll: "Sol Metatars Genişliği (cm)",
      mrl: "Sağ Metatars Genişliği (cm)",
    },
    M_BTN_ADD: "YENİ",
    M_BTN_ADD_MULTI: [
      {
        label: "TABANLIK",
        to: "/insoleRegister",
      },
      {
        label: "TERLİK",
        to: "/slipperRegister",
      },
      {
        label: "TAMİR",
        to: "/repairRegister",
      },
    ],
    M_BTN_SEA: "ARA",
    M_TBL_COM_HDR: [
      {
        label: "İSİM",
        for: "nam",
      },
      {
        label: "E-POSTA",
        for: "ema",
      },
      {
        label: "ADRES",
        for: "adr",
      },
      {
        label: "TELEFON",
        for: "pno",
      },
      {
        label: "ETKİN",
        for: "act",
      },
    ],
    M_TBL_COMPRD_HDR: [
      {
        label: "İSİM",
        for: "nam",
      },
      {
        label: "ETKİN",
        for: "act",
      },
    ],
    M_TBL_PRD_HDR: [
      {
        label: "İSİM",
        for: "nam",
      },
      // {
      //   label: "TANIMLI FİRMALAR",
      //   for: "rel",
      // },
      {
        label: "İNGİLİZCE İSİM", //Name
        for: "namen", //
      },
      {
        label: "ETKİN",
        for: "act",
      },
    ],
    M_TBL_USR_HDR: [
      {
        label: "İSİM",
        for: "nam",
      },
      {
        label: "SOYİSİM",
        for: "sur",
      },
      {
        label: "E-POSTA",
        for: "ema",
      },
      {
        label: "TELEFON",
        for: "pno",
      },
      {
        label: "FİRMA",
        for: "cnm",
      },
      {
        label: "ETKİN",
        for: "act",
      },
    ],
    M_TBL_PAT_HDR: [
      {
        label: "İSİM",
        for: "nam",
      },
      {
        label: "SOYİSİM",
        for: "sur",
      },
      {
        label: "E-POSTA",
        for: "ema",
      },
      {
        label: "TELEFON",
        for: "pno",
      },
      {
        label: "KULLANICI",
        for: "unm",
      },
      {
        label: "ETKİN",
        for: "act",
      },
    ],
    M_TBL_PAT_ADM_HDR: [
      {
        label: "İSİM",
        for: "nam",
      },
      {
        label: "SOYİSİM",
        for: "sur",
      },
      {
        label: "E-POSTA",
        for: "ema",
      },
      {
        label: "TELEFON",
        for: "pno",
      },
      {
        label: "FİRMA",
        for: "cnm",
      },
      {
        label: "KULLANICI",
        for: "unm",
      },
      {
        label: "ETKİN",
        for: "act",
      },
    ],
    M_TBL_DIA_HDR: [
      {
        label: "HASTA",
        for: "pnm",
      },
      {
        label: "TELEFON",
        for: "pno",
      },
      {
        label: "KULLANICI",
        for: "unm",
      },
      {
        label: "TANI NOTU",
        for: "ddm",
      },
      {
        label: "OLUŞTURMA TARİHİ",
        for: "det",
      },
      {
        label: "ETKİN",
        for: "act",
      },
    ],
    M_TBL_DIA_ADM_HDR: [
      {
        label: "HASTA",
        for: "pnm",
      },
      {
        label: "TELEFON",
        for: "pno",
      },
      {
        label: "FİRMA",
        for: "cnm",
      },
      {
        label: "KULLANICI",
        for: "unm",
      },
      {
        label: "TANI NOTU",
        for: "ddm",
      },
      {
        label: "OLUŞTURMA TARİHİ",
        for: "det",
      },
      {
        label: "ETKİN",
        for: "act",
      },
    ],
    M_TBL_ORD_HDR: [
      {
        label: "KULLANICI",
        for: "unm",
      },
      {
        label: "HASTA",
        for: "pnm",
      },
      {
        label: "TANI",
        for: "dia",
      },
      {
        label: "SİPARİŞ TİPİ", // admin only column
        for: "typ",
      },
      {
        label: "OLUŞTURMA TARİHİ",
        for: "oet",
      },
      {
        label: "ONAY",
        for: "ook",
      },
      {
        label: "ONAY TARİHİ",
        for: "oct",
      },
      {
        label: "TESLİM",
        for: "ods",
      },
      {
        label: "TESLİM TARİHİ",
        for: "odt",
      }
    ],
    M_TBL_ORD_HDR_ADM: [
      {
        label: "FİRMA", // admin only column
        for: "cnm",
      },
      {
        label: "KULLANICI",
        for: "unm",
      },
      {
        label: "HASTA",
        for: "pnm",
      },
      {
        label: "TANI",
        for: "dia",
      },
      {
        label: "SİPARİŞ TİPİ", // admin only column
        for: "typ",
      },
      {
        label: "OLUŞTURMA TARİHİ",
        for: "oet",
      },
      {
        label: "ONAY",
        for: "ook",
      },
      {
        label: "ONAY TARİHİ",
        for: "oct",
      },
      {
        label: "TESLİM",
        for: "ods",
      },
      {
        label: "TESLİM TARİHİ",
        for: "odt",
      }
    ],
    M_CHK_FT: {
      formLabelL: "Sol Ayak",
      formLabelR: "Sağ Ayak",
      one: "1- Lateral Topuk",
      two: "2- Medial Topuk",
      three: "3- Lateral Ark",
      four: "4- Medial Ark",
      five: "5- 4/5 Metatarsal Bölge",
      six: "6- 2/3 Metatarsal Bölge",
      seven: "7- 1. Metatarsal Bölge",
      eight: "8- Başparmak",
    },
    M_CHK_BD: {
      formLabelL: "Sol Vücut",
      formLabelR: "Sağ Vücut",
      one: "Boyun",
      two: "Bel",
      three: "Kalça",
      four: "Diz",
      five: "Ayak Bileği",
      six: "Ayak",
    },
    M_CHK_DIA: {
      formLabel: "Tanı ve Endikasyonlar",
      pesplanus: "Pesplanus",
      pescavus: "Pescavus",
      calcanealExostosis: "Calceneal Exostosis",
      plantarFasitis: "Plantar Fasitis",
      mortonNeuroma: "Morton Neuroma",
      rheumatism: "Romatizma",
      diabeticFoot: "Diyabetik Ayak",
      tibialisPosteriorDeformity: "Tibialis Posterior Deformitesi",
      achillesTendinitis: "Aşil Tendiniti",
      halluxValgus: "Hallux Valgus",
      navicularAccesory: "Navicular Accesory",
      halluxRigidus: "Hallux Rigidus",
      halluxLimitus: "Hallux Limitus",
      stressFracture: "Stres Kırığı",
      ddm: "Tanı Notu"
    },
    M_CHK_INSOLE_XTR_SOFT_COVER: {
      label: "Ekstra Yumuşak Üst Kaplama",
      id: "xts",
    },
    EXC: {
      UNHANDLED: "Beklenmedik bir hata oluştu.",
      E_MUST_LOGIN: "Lütfen giriş yapınız.",
      E_EDGE_INV_ARG: {
        ema: "Mail adresi hatalı.",
        pwd: "Şifre geçersiz. En az 6 karakterli olmalı.",
        uid: "Kullanıcı id geçersiz!",

      },
      E_EDGE_INV_USR: {
        login: "Kullanıcı adı ya da şifre yanlış."
      },
      E_EDGE_INV_COM: {
        usrUpd: "Firması pasif olan kullanıcı aktif duruma getirilemez."
      },
      E_EDGE_NO: "Böyle bir kayıt yok: ",
      E_DATA_INV_LIM: "Lst isteği için geçersiz limit bilgisi gönderilmiş.",
      E_EDGE_REG: "Mail adresi daha önce kullanılmış."
    },
    M_WARN_INV_VALS: "Herhangi bir değişiklik yapmadınız.",
    M_WARN_INV_FILTER: "Lütfen filtreleme yapınız.",
    M_WARN_INV_PASS: "Şifre 6 karakterden az olamaz.",
    M_WARN_NO_COM: "Lütfen firma seçiniz."

  },

  EN: {
    M_HDR_CMP: "NESA", //	
    M_LBL_INSOLE: "Insole", //	Insole
    M_LBL_SLIPPER: "Slipper", //    Slipper
    M_LBL_REPAIR: "Modification", //      Modification
    M_LBL_ESTIM_DELIVER_DATE: "Delivery Date *", // Delivery Date
    M_LBL_ESTIM_DELIVER_TIME: "Delivery Hour", //  Delivery Hour
    M_HDR_COM_INS: "Add Company", // Add Company
    M_HDR_COM_UPD: "Edit Company Information", // Edit Company Information
    M_HDR_USR_INS: "Save User", // Save User
    M_HDR_USR_UPD: "Edit User Information", //Edit User Information
    M_HDR_PAT_INS: "Save Patient", // Save Patient
    M_HDR_PAT_UPD: "Edit Patient Information", // Edit Patient Information
    M_HDR_PRD_INS: "Add New Product", // Add New Product
    M_HDR_PRD_UPD: "Edit Product Details", //Edit Product Details
    M_HDR_PRD_COM_INS: "Define Multiple Product to Company", // Define Multiple Product to Company
    M_HDR_DIA_PAIN_AREAS: "Pain Areas", // Pain Areas
    M_HDR_DIA_PAT_MEASURES: "Patient Measurements", // Patient Measurements
    M_HDR_DIA_IND: "Diagnosis and Indications", //Diagnosis and Indications
    M_HDR_DIA_INS: "Add New Diagnosis", // Add New Diagnosis
    M_HDR_DIA_INF: "Show Diagnosis", //Show Diagnosis
    M_HDR_DIA_UPD: "Update Diagosis", //Update Diagosis
    M_HDR_PAIN_AREAS: "Pain Areas", //Pain Areas
    M_HDR_ORD_INS: "Add A New Order", //Add A New Order
    M_HDR_ORD_UPD: "Edit Order", //Edit Order
    M_TBL_PRD_COM_REL_LBL: "Companies Which Insole Type Is Defined", //Companies  Which Insole Type Is Defined
    M_MUL_SEL: "Multiple Choice", //Multiple Choice
    M_ALR_REG_SUCCESS: "Succesful Save.", // Succesful Save 
    M_ALR_EDT_SUCCESS: "Succesful Update.", // Succesful Update
    M_CHK_ORD_DELIVERY_STATUS : "Delivery Status", // Delivery Status
    M_CHK_ORD_OOK: "Confirm",
    M_CHK_ORD_OOK_INFO : "Confirmed",
    M_LBL_DROPZONE: "Drop or click for uploading files.",
    M_BTN_SELECT_FILE: "Select File",
    M_LBL_DOWNLOAD: "Download Order Files",
    M_LBL_FILE: "File",
    M_LBL_FILES: "Files",
    M_LBL_DELETE_ALL: "Remove All",
    M_LBL_UPLOAD: "Upload",
    M_LBL_ACT: "Active",
    M_LBL_INACT: "Passive",
    M_HDRS_USR: [
      {
        label: "ORDERS", //Orders
        href: "/orders", //
      },
      {
        label: "PATIENTS", //Patients
        href: "/patients", //
      },
      {
        label: "LOGOUT", // Logout
        href: "/logout", //
      },
    ],
    M_HDRS_ADM: [
      {
        label: "COMPANIES", //
        href: "/companies", //
      },
      {
        label: "USERS", //
        href: "/users", //
      },
      {
        label: "PRODUCTS", //
        href: "/products", //
      },
      {
        label: "DIAGNOSIS", //
        href: "/diagnosis", //
      },
      {
        label: "ORDERS", //
        href: "/orders", //
      },
      {
        label: "PATIENTS", //
        href: "/patients", //
      },
      {
        label: "LOGOUT", //
        href: "/logout", //
      },
    ],
    M_HDRS_GUE: [
      {
        label: "LOGIN", //
        href: "/login", //
      },
    ],
    M_INP_EMA: {
      id: "ema", //
      label: "Email", //email
    },
    M_INP_PWD: {
      id: "pwd", //
      label: "Password", //password
    },
    M_INP_NAM: {
      id: "nam", //
      label: "Name", //Name
    },
    M_INP_NAMEN: {
      id: "namen",
      label: "Name (In English)",
    },
    M_INP_SUR: {
      id: "sur", //
      label: "Surname", //Surname
    },
    M_INP_COM_NAM: {
      id: "nam", //
      label: "Company Name", //Company Name
    },
    M_INP_PAT_NAM: {
      id: "nam", //
      label: "Patient Name", // Patient's Name
    },
    M_INP_PAT_SUR: {
      id: "sur", //
      label: "Patient Surname", //Patient's Surname
    },
    M_INP_PNO: {
      id: "pno", //
      label: "Phone", //Telephon
    },
    M_INP_COM: {
      id: "cid", //
      label: "Company", //Company
    },
    M_INP_USR: {
      id: "uid", //
      label: "User", //User
    },
    M_INP_PAT: {
      id: "pid", //
      label: "Patient", //Patient
    },
    M_INP_DIA: {
      id: "did", //
      label: "Diagnosis", // Diagnosis
      warning: "Please create a diagnosis." // Please create a diagnosis.
    },
    M_INP_ADR: {
      id: "adr", //
      label: "Address", // Adress
    },
    M_INP_ACT: {
      id: "act", //
      label: "Active", //Active
    },
    M_INP_OOK: {
      id: "ook", //
      label: "Confirm", // Confirm
    },
    M_INP_MEA: {
      id: "measurer", //
      label: "Measurer", //Measurer
    },
    M_INP_ODS: {
      id: "ods", //
      label: "Delivery", //Delivery
      items: [
        {
          value: -1,
          text: "All", //All
        },
        {
          value: true,
          text: "Yes", //Yes
        },
        {
          value: false,
          text: "No", //NO
        },
      ],
    },
    M_INP_PRD: {
      id: "tid", //
      label: "Product", //Product
    },
    M_INP_OET: {
      id: "oet", //
      label: "Creation Date", //Creation Date
    },
    M_INP_ODT: {
      id: "odt", //
      label: "Delivery Date", //Delivery Date
    },
    M_INP_ODN: {
      id: "odn", //
      label: "Delivery Note", //Delivery Note
    },
    M_INP_ONT: {
      id: "ont", //
      label: "Order Note", //Order Note
    },
    M_INP_TYP: {
      id: "typ", //
      label: "Type", //Type
    },
    M_INP_TYP_ITMS: [
      {
        value: -1,
        text: "All" // All
      },
      {
        value: "Insole", //
        text: "Insole"
      },
      {
        value: "Slipper", //
        text: "Slipper"
      },
      {
        value: "Repair", //
        text: "Revision" // Revision
      }
    ],
    M_INP_OBJ: {
      id: "obj", //
      label: "Additional Properties", //Additional Properties
    },
    M_INP_DDM: {
      id: "ddm", //
      label: "Diagnosis Note", //Diagnosis Note
    },
    M_INP_ACT_ITMS: [
      {
        value: -1,
        text: "All", //All
      },
      {
        value: true,
        text: "Active", //Active
      },
      {
        value: false,
        text: "Passive", //Passive
      },
    ],
    M_INP_OOK_ITMS: [
      {
        value: -1,
        text: "All", //All
      },
      {
        value: true,
        text: "Yes", //Yes
      },
      {
        value: false,
        text: "No" //No
      },
    ],
    M_INP_INSOLE_FOOT_TYPE: {
      // AYAKKABI TIPI
      label: "Shoe Type *", //Shoe Type
      id: "stp", //
      items: [
        {
          value: 0,
          text: "Daily Shoe", // Daily Shoe
        },
        {
          value: 1,
          text: "Working Shoe", //Working Shoe
        },
        {
          value: 2,
          text: "Sport-Basketball Shoe", //Sport-Basketball Shoe
        },
        {
          value: 3,
          text: "Sport-Football Crampon", //Sport Football Crampon
        },
        {
          value: 4,
          text: "Sport-Tennis Shoe", //Sport Tennis Shoe
        },
        {
          value: 5,
          text: "Sport-Volleyball Shoe", //Sport Volleyball Shoe
        },
        {
          value: 6,
          text: "The Other Type", //The Other Type
        },
      ],
    },
    M_INP_INSOLE_HARDNESS: {
      label: "Insole Hardness *", //Insole Hardness
      id: "ihs", //
      items: [
        {
          value: 0,
          text: "Diabetic", //Diabetic
        },
        {
          value: 1,
          text: "Soft", //Soft
        },
        {
          value: 2,
          text: "Semi-Rigid", //Semi-Rigid
        },
        {
          value: 3,
          text: "Semi-Rigid Flex", //Semi-Rigid Flex
        },
        {
          value: 4,
          text: "Rigid", //Rigid
        },
        {
          value: 5,
          text: "Extra Rigid (Plastic)", //Ekstre Rigid Plastic
        },
        {
          value: 6,
          text: "Sport Material (Soft)", //Sport Material (Soft)
        },
      ],
    },
    // TABANLIK TIPI
    M_INP_INSOLE_TYPES: {
      label: "Insole Type *", //Insole Type
      id: "tid", //
    },
    M_INP_INSOLE_LEFT: {
      // Olusturulmasi istenen tabanlik icin, tabanligin kullanilacagi urun birakildi mi   Is any product left to the company which ll be  to be used with the insole ?
      label: "Is Any Product Left to the Company? *", // Is Any Product Left to the Company?
      id: "ilf", //
      items: [
        {
          value: 0,
          text: "No", // No
        },
        {
          value: 1,
          text: "Shoe", // Shoe
        },
        {
          value: 2,
          text: "Slippers/sandals which lining(sole) can removable", // Slippers/sandals which lining(sole) can removable
        },
        {
          value: 3,
          text: "Patient will leave later", // Patient will leave later
        },
      ],
    },
    // tabanlık kaplama tercihleri
    M_INP_INSOLE_COVERING_CHOICES: {
      label: "Covering Choices *", // look //
      id: "cch", //
      items: [
        {
          value: 0,
          text: "Rough - Black", // Rough-Black
        },
        {
          value: 1,
          text: "Rough - Creme", // Rough-Creme
        },
        {
          value: 2,
          text: "Rough - Grey", // Rough-Grey
        },
        {
          value: 11,
          text: "Rough - Brown",
        },
        {
          value: 3,
          text: "Flat - Siyah", // Flat-Siyah
        },
        {
          value: 4,
          text: "Flat - Grey", // Flat-Grey
        },
        {
          value: 5,
          text: "Flat - Blue", // Flat-Blue
        },
        {
          value: 6,
          text: "Flat - Pink", //Flat-Pink
        },
        {
          value: 7,
          text: "Flat - Purple", // Flat-Purple
        },
        {
          value: 8,
          text: "Flat - Brown", // Flat-Brown
        },
        {
          value: 9,
          text: "Flat - Light Beige", // Flat-Light Beige
        },
        {
          value: 10,
          text: "Flat - Dark Beige", // Flat-Dark Beige
        },
        {
          value: 12,
          text: "Flat - Green",
        },
        {
          value: 13,
          text: "Flat - Red",
        },
      ],
    },
    M_INP_INSOLE_STAGES: {
      label: "Cushion Choice *", // Cushion Choice
      id: "spf", //
      items: [
        {
          value: 0,
          text: "No", // No 
        },
        {
          value: 1,
          text: "Soft", // Soft
        },
        {
          value: 2,
          text: "Extra Soft", //Extra Soft
        },
      ],
    },
    M_INP_SLIPPER_TYPES: {
      label: "Slipper/Sandal Type *", // Slipper/Sandal Type
      id: "slp", //
      items: [
        {
          value: 0,
          text: "Double Strapped Sandal", // Double Strapped Sandal
        },
        {
          value: 1,
          text: "Triple Strapped Sandal", // Triple Strapped Sandal
        },
        {
          value: 2,
          text: "Flip-flop", // Flip-flop
        },
        {
          value: 5,
          text: "Y Type Flip-flop", // Flip-flop
        },
        {
          value: 3,
          text: "Double Strapped and Heel Strapped Sandal", // Double Strapped and Heel Strapped Sandal
        },
        {
          value: 4,
          text: "Clog", // Clog
        },
      ],
    },
    M_INP_SLIPPER_LEATHER_TYPES: {
      id: "ltp", //
      label: "Leather Type *", // Leather Type 
      items: [
        {
          value: 0,
          text: "Original - Black", // Original-Black
        },
        {
          value: 1,
          text: "Original - Green", // Original-Green
        },
        {
          value: 2,
          text: "Original - Blue", // Original-Blue
        },
        {
          value: 6,
          text: "Original - Black Rough",
        },
        {
          value: 7,
          text: "Original - Black Rough Rigid",
        },
        {
          value: 8,
          text: "Original - Blue",
        },
        {
          value: 9,
          text: "Original - Dark Blue",
        },
        {
          value: 3,
          text: "Artificial - Black", // Artificial-Black
        },
        {
          value: 4,
          text: "Artificial - Dark Blue", // Artificial-Dark Blue
        },
        {
          value: 5,
          text: "Artificial - Creme", // Artificial-Creme
        },
        {
          value: 10,
          text: "Artificial - Pink",
        },
        {
          value: 11,
          text: "Artificial - Yellow",
        },
        {
          value: 12,
          text: "Artificial - Red",
        },
        {
          value: 13,
          text: "Artificial - Bronze",
        },
        {
          value: 14,
          text: "Artificial - Orange",
        },
        {
          value: 15,
          text: "Artificial - Black",
        },
        {
          value: 16,
          text: "Felt - Green",
        },
        {
          value: 17,
          text: "Felt - Black",
        },
        {
          value: 18,
          text: "Felt - Blue",
        },
        {
          value: 19,
          text: "Felt - Red",
        },
        {
          value: 20,
          text: "Felt - Grey",
        }
      ],
    },
    M_INP_SLIPPER_MATERIAL_COLOR: {
      id: "mcl", //
      label: "Slipper/ Sandal  Material Color *", // Slipper/ Sandal  Material Color
      items: [
        {
          value: 0,
          text: "Blue", // Blue 
        },
        {
          value: 1,
          text: "Black", // Black
        },
        {
          value: 2,
          text: "Orange", // Orange
        },
        {
          value: 3,
          text: "Grey", //Grey
        },
        {
          value: 4,
          text: "Green", // Green
        },
        {
          value: 5,
          text: "Creme (Beige)",
        },
      ],
    },
    M_INP_SLIPPER_TREAD_COLOR: {
      // Terlik taban rengi
      id: "tcl", //
      label: "Out Sole Color *", // Out Sole Color
      items: [
        {
          value: 0,
          text: "White", // White
        },
        {
          value: 1,
          text: "Black", // Black
        },
      ],
    },
    M_INP_SLIPPER_FILE_TYPES: {
      id: "ftp", //
      label: "Is there any foam box, cast ,3d scan data ?", // Is there any foam box, cast ,3d scan data ?
      items: [
        {
          value: 0,
          text: "No", // No 
        },
        {
          value: 1,
          text: "Cast", // Cast
        },
        {
          value: 2,
          text: "Foam Box", // Foam Box
        },
        {
          value: 3,
          text: "3D Scan Data", // 3 scan data
        },
      ],
    },
    M_INP_LGN: "LOGIN", //Login
    M_INP_SBM: "SAVE", // Save
    M_INP_UPD: "SAVE", // Save
    M_INP_CAN: "CANCEL", // Cancel
    M_INP_PAG: {
      id: "pag", //
      label: "Page", //
    },
    M_INP_WDG: {
      labelL: "Left Foot Wedge", // Left Foot Wedge
      labelR: "Right Foot Wedge", // Right Foot Wedge
      heelMedLbl: "From Hell Medial (mm)", // From Hell Medial (mm)
      heelLatLbl: "From Heel Latera (mm)", // From Heel Latera (mm)
      frntMedLbl: "Forefoot Medial (mm)", // Forefoot Medial (mm)
      frntLatLbl: "Forefoot Lateral (mm)", // Forefoot Lateral (mm)
      fullMedLbl: "All Foot Medial (mm)", // All Foot Medial (mm)
      fullLatLbl: "All Foot Lateral (mm)", // All Foot Lateral (mm)
    },
    M_INP_SHR: {
      labelL: "Left Foot Shortness", // Left Foot Shortness
      labelR: "Right Foot Shortness", // Right Foot Shortness
      heel: "From Hell (mm)", // From Hell (mm)
      frnt: "Forefoot (mm)", // Forefoot (mm)
      full: "All Foot (mm)" // All Foot (mm)
    },
    M_INP_A: {
      title: "A Measurement", // A Measurement
      labelLA: "Left A (cm) ", // Left A (cm)
      labelRA: "Right A (cm) " // Right A (cm)
    },
    M_INP_B: {
      title: "B Measurement", //
      labelLA: "Left B (cm) ", // Left B (cm)
      labelRA: "Right B (cm) " // Right B (cm)
    },
    M_INP_PAT_INF: {
      hei: "Height (cm)", // Height (cm)
      wei: "Weight (kg)", // Weight (kg)
      fts: "Shoe Size", // Shoe Size
      fls: "Left Foot Length (cm)", // Left Foot Length (cm)
      frs: "Right Foot Lenght (cm)", // Right Foot Lenght (cm) 
      mll: "Left Foot Metatarsal Width (cm)", // Left Foot Metatarsal Width (cm)
      mrl: "Right Foot Metatarsal Width (cm)", // Right Foot Metatarsal Width (cm)
    },
    M_BTN_ADD: "NEW", //
    M_BTN_ADD_MULTI: [
      {
        label: "INSOLE", // Insole
        to: "/insoleRegister", //
      },
      {
        label: "SLIPPER/SANDAL", // Slipper/Sandal
        to: "/slipperRegister", //
      },
      {
        label: "REVISION", // Revision
        to: "/repairRegister", //
      },
    ],
    M_BTN_SEA: "SEARCH", //
    M_TBL_COM_HDR: [
      {
        label: "NAME", // Name
        for: "nam", //
      },
      {
        label: "EMAIL", // email
        for: "ema", //
      },
      {
        label: "ADDRESS", // Adress
        for: "adr", //
      },
      {
        label: "MOBILE NUMBER", // Mobile Number
        for: "pno", //
      },
      {
        label: "ACTIVE", // Active
        for: "act", //
      },
    ],
    M_TBL_COMPRD_HDR: [
      {
        label: "NAME", // Name
        for: "nam", //
      },
      {
        label: "ACTIVE", // Active
        for: "act", //
      },
    ],
    M_TBL_PRD_HDR: [
      {
        label: "NAME", //Name
        for: "nam", //
      },
      {
        label: "ENGLISH NAME", //Name
        for: "namen", //
      },
      {
        label: "ACTIVE", // Active
        for: "act", //
      },
    ],
    M_TBL_USR_HDR: [
      {
        label: "NAME", // Name 
        for: "nam", //
      },
      {
        label: "SURNAME", //Surname
        for: "sur", //
      },
      {
        label: "EMAIL", // email
        for: "ema", //
      },
      {
        label: "MOBILE NUMBER", //Mobile Number
        for: "pno", //
      },
      {
        label: "COMPANY", // Company 
        for: "cnm", //
      },
      {
        label: "ACTIVE", //Active
        for: "act", //
      },
    ],
    M_TBL_PAT_HDR: [
      {
        label: "NAME", //Name 
        for: "nam", //
      },
      {
        label: "SURNAME", //Surname 
        for: "sur", //
      },
      {
        label: "EMAIL", //email 
        for: "ema", //
      },
      {
        label: "MOBILE NUMBER", //Mobile Number
        for: "pno", //
      },
      {
        label: "USER", //User
        for: "unm", //
      },
      {
        label: "ACTIVE", //Active
        for: "act", //
      },
    ],
    M_TBL_PAT_ADM_HDR: [
      {
        label: "NAME", // Name
        for: "nam", //
      },
      {
        label: "SURNAME", // Surname
        for: "sur", //
      },
      {
        label: "EMAIL", // email
        for: "ema", //
      },
      {
        label: "PHONE", // Mobile Number
        for: "pno", //
      },
      {
        label: "COMPANY", // Company
        for: "cnm", //
      },
      {
        label: "USER", // User
        for: "unm", //
      },
      {
        label: "ACTIVE", // Active
        for: "act", //
      },
    ],
    M_TBL_DIA_HDR: [
      {
        label: "PATIENT", // Patient
        for: "pnm", //
      },
      {
        label: "MOBILE NUMBER", // Mobile Number
        for: "pno", //
      },
      {
        label: "USER", // User
        for: "unm", //
      },
      {
        label: "DIAGNOSIS NOTE", // Diagnoisis Note
        for: "ddm", //
      },
      {
        label: "ORDER DATE", // Order Date
        for: "det", //
      },
      {
        label: "ACTIVE", // Active
        for: "act", //
      },
    ],
    M_TBL_DIA_ADM_HDR: [
      {
        label: "PATIENT", // Patient
        for: "pnm", //
      },
      {
        label: "MOBILE NUMBER", // Mobile Number
        for: "pno", //
      },
      {
        label: "COMPANY", // Company
        for: "cnm", //
      },
      {
        label: "USER", // User
        for: "unm", //
      },
      {
        label: "DIAGNOSIS NOTE", // Diagnosis Note
        for: "ddm", //
      },
      {
        label: "ORDER DATE", // Order Date
        for: "det", //
      },
      {
        label: "ACTIVE", // Active
        for: "act", //
      },
    ],
    M_TBL_ORD_HDR: [
      {
        label: "USER", // User
        for: "unm", //
      },
      {
        label: "PATIENT", // Patient
        for: "pnm", //
      },
      {
        label: "DIAGNOSIS", // Diagonosis
        for: "dia", //
      },
      {
        label: "ORDER TYPE", // // admin only column Order Type
        for: "typ", //
      },
      {
        label: "ORDER DATE", // Order Date
        for: "oet", //
      },
      {
        label: "CONFIRMATION", // Confirmation 
        for: "ook", //
      },
      {
        label: "CONFIRMED DATE", // Confirmed Date
        for: "oct", //
      },
      {
        label: "DELIVERY", // Delivery
        for: "ods", //
      },
      {
        label: "DELIVERY DATE", // Delivery DAte
        for: "odt", //
      }
    ],
    M_TBL_ORD_HDR_ADM: [
      {
        label: "COMPANY", // // admin only column Company
        for: "cnm", //
      },
      {
        label: "USER", // User
        for: "unm", //
      },
      {
        label: "PATIENT", // Patient
        for: "pnm", //
      },
      { 
        label: "DIAGNOSIS", //Diagnosis
        for: "dia", //
      },
      {
        label: "ORDER TYPE", // // admin only column Order Type
        for: "typ", //
      },
      {
        label: "ORDER DATE", // Order Date
        for: "oet", //
      },
      {
        label: "CONFIRMATION", // Confirmation
        for: "ook", //
      },
      {
        label: "CONFIRMATION DATE", // Confirmation Date
        for: "oct", //
      },
      {
        label: "DELIVERY", // Delivery
        for: "ods", //
      },
      {
        label: "DELIVERY DATE", // Delivery Date
        for: "odt", //
      }
    ],
    M_CHK_FT: {
      formLabelL: "Left Foot", // Left Foot
      formLabelR: "Rigt Foot", // Rigt Foot
      one: "1- Lateral Heel", // Lateral Heel
      two: "2- Medial Heel", // Medial Heel
      three: "3- Lateral Arch", // Lateral Arch
      four: "4- Medial Arch", // MEdial Arch
      five: "5- 4/5 Metatarsal Zone", // 4/5 Metatarsal Zone
      six: "6- 2/3 Metatarsal Zone", // 2/3 Metatarsal Zone
      seven: "7- 1. Metatarsal Zone", // 1. Metatarsal Zone
      eight: "8- Bigo Toe", // Bigo Toe
    },
    M_CHK_BD: {
      formLabelL: "Left Body", // LEft Body
      formLabelR: "Right Body", // Right Body
      one: "Neck", // Neck
      two: "Waist", // Waist
      three: "Hip", // Hip
      four: "Knee", // Knee 
      five: "Ankle", // Ankle
      six: "Foot", // Foot
    },
    M_CHK_DIA: {
      formLabel: "Diagnosis and Indications", // Diagnosis and İndications
      pesplanus: "Pesplanus", // Pesplanus
      pescavus: "Pescavus", // Pescavus
      calcanealExostosis: "Calceneal Exostosis", // Calcaneal Exostosis
      plantarFasitis: "Plantar Fasciitis", // Plantar Fasciitis
      mortonNeuroma: "Morton Neuroma", // Morton Neuroma
      rheumatism: "Rheumatism", // Rheumatism
      diabeticFoot: "Diabetic Foot", // Diabetic Foot
      tibialisPosteriorDeformity: "Tibialis Posterior Deformity", // Tibialis Posterior Deformity
      achillesTendinitis: "Achilles Tendinitis", //Achilles Tendinitis
      halluxValgus: "Hallux Valgus", // Hallux Valgus
      navicularAccesory: "Navicular Accessory", // Navicular Accessory
      halluxRigidus: "Hallux Rigidus", // Hallux Ridigdus
      halluxLimitus: "Hallux Limitus", // Hallux Limitus
      stressFracture: "Stress Fractuer", // Stress Fractuer
      ddm: "Additional Diagnosis Notes" // Additional Diagnosis Notes
    },
    M_CHK_INSOLE_XTR_SOFT_COVER: {
      label: "Extra Soft Insole Cover", // Extra Soft Insole Cover
      id: "xts", //
    },
    EXC: {
      UNHANDLED: "An unexpected error has occurred.", // An unexpected error has occurred
      E_MUST_LOGIN: "Please Login.", // Please Login
      E_EDGE_INV_ARG: {
        ema: "Email address is incorrect.", // e-mail address is incorrect
        pwd: "Password is invalid. Must contain at least 6 characters.", // Password is invalid. Must contain at least 6 characters
        uid: "Invalid User ID!", // Invalid User ID

      },
      E_EDGE_INV_USR: {
        login: "Username or Password is incorrect." // Username or Password is İncorrect
      },
      E_EDGE_INV_COM: {
        usrUpd: "User whose company is inactive cannot be activated." // User whose company is inactive cannot be activated.
      },
      E_EDGE_NO: "There is no such record: ", // There is no such registiration
      E_DATA_INV_LIM: "Invalid limit information sent for list(lst) request.", // Invalid limit information sent for Lst request
      E_EDGE_REG: "Email address has been used before." // Email address has been used before
    },
    M_WARN_INV_VALS: "You did not make any changes.", // You did not make any changes.
    M_WARN_INV_FILTER: "Please Filter.", // Please Filter
    M_WARN_INV_PASS: "Password cannot be less than 6 characters.", //Password cannot be less than 6 characters
    M_WARN_NO_COM: "Please select the Company." // Please select the Company
  }
};

export default languages;
