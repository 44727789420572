import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Grid, Button } from '@material-ui/core';
import { lng } from '../../Tore/Sys'
import HttpPost from '../HttpPost';
import Glb from '../../global/Glb'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formCenter: {
        alignItems: "center",
        justify: "center"
    }
}));

const textFieldProps = {
    variant: "outlined",
    margin: "dense"
}



export default function Login(props) {
    const classes = useStyles();

    const [ema, setEma] = useState("");
    const [pwd, setPwd] = useState("");


    const handleEma = (event) => {
        setEma(event.target.value);
    }
    const handlePwd = (event) => {
        setPwd(event.target.value);
    }

    const handleSubmit = async () => {

        const login = {
            "ema": ema,
            "pwd": pwd
        }
        let response = await HttpPost("/login", login)
        if (response?.sta === "ACK") {
            Glb.usr = response.dta;
            Glb.usr.isloggedIn = true;
            //console.log(Glb.usr)
            props.handleLogin(Glb.usr)
        }
        // else if (response?.sta === "EXC") {
        //     if (response?.dta) {
        //         var msg = lng.itm("EXC")?.E_EDGE_INV_USR?.login;
        //         alert(msg);
        //     }
        // }
        //alert(JSON.stringify(response, null, 2));
    }

    return (

        <Grid container spacing={5}>
            <Grid item sm={2} />

            <Grid container className={classes.formCenter} direction="column" spacing={2}>

                <TextField required id={lng.itm("M_INP_EMA").id} label={lng.itm("M_INP_EMA").label} defaultValue="" onChange={handleEma} {...textFieldProps} />
                <TextField required id={lng.itm("M_INP_PWD").id} label={lng.itm("M_INP_PWD").label} defaultValue="" onChange={handlePwd} type="password" {...textFieldProps} />
                <Button type="submit" variant="contained" color="primary" size="small" onClick={handleSubmit} >
                    {lng.itm("M_INP_LGN")}
                </Button>

            </Grid>
            <Grid item sm={2} />
        </Grid>

    );
}
