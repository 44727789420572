import { Grid } from "@material-ui/core";
import React from "react";
//import { lng } from '../../Tore/Sys'
import CustomSelect from "../CustomSelect";
import DatePicker from "../DatePicker";
import RadioButtonsGroup from "../RadioButtons";
import FileInput from "../FileInput";
import CancelAndSubmitButton from "../CancelAndSubmitButton";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Glb from "../../global/Glb";
export default function SlipperEdit({ row }) {

  const handleChange = (e) => {
    var checked = e.target.checked;
    var id = e.target.id;
    if (id) {
      if (checked || checked === false) {
        Glb.Storage.ord[id] = checked;
      }
    }
    //alert(JSON.stringify(Glb.Storage.ord));
  };
  var isAdmin = Glb.Storage.usr.adm;

  const handleDateChange = (formattedDate) => {
    Glb.Storage.ord.odt = formattedDate;
  };
  return (
    <>
      <Grid container item direction="column" sm={6} spacing={1}>
        <Grid item>
          <CustomSelect value={row?.slp} lngKey="M_INP_SLIPPER_TYPES" obj="ord" />
        </Grid>
        <Grid item>
          <CustomSelect value={row?.mcl} lngKey="M_INP_SLIPPER_MATERIAL_COLOR" obj="ord" />
        </Grid>
      </Grid>
      <Grid container item direction="column" sm={6} spacing={1}>
        <Grid item>
          <CustomSelect value={row?.ltp} lngKey="M_INP_SLIPPER_LEATHER_TYPES" obj="ord" />
        </Grid>
        {/* <Grid item>
          <CustomSelect value={row?.ftp} lngKey="M_INP_SLIPPER_FILE_TYPES" obj="ord" />
        </Grid> */}
        <Grid item>
          <RadioButtonsGroup defVal={row?.tcl} lngKey="M_INP_SLIPPER_TREAD_COLOR" />
        </Grid>
      </Grid>
      <Grid container item direction="column" sm={6} spacing={1}>
        <Grid item>
          <DatePicker value={row?.odt} handleChange={handleDateChange} lngKey="M_INP_ODT" />
        </Grid>
      </Grid>
    </>
  );
}
