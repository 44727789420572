import { createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import { red } from '@material-ui/core/colors';

const theme = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      root: {
        padding: 0,
        marginLeft: 0
      },
      colorPrimary: {
        main: green[600],
      },
      colorSecondary: {
        main: red[600],
      },

    },
  },
  palette: {
    primary: {
      main: green[600],
    },
    secondary: {
      main: green[600],
    },
    
  },
  typography: {
    htmlFontSize: 18,
  }
});

export default theme;