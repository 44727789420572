import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@material-ui/core";
import React from "react";
import Glb from "../../global/Glb";
import { lng } from "../../Tore/Sys";
import CustomSelect from "../CustomSelect";
import CustomSelectProducts from "../CustomSelectProducts";
import DatePicker from "../DatePicker";

const handleChange = (e) => {
  var checked = e.target.checked;
  var id = e.target.id;
  if (id) {
    if (checked || checked === false) {
      Glb.Storage.ord[id] = checked;
    }
  }
  //alert(JSON.stringify(Glb.Storage.ord));
};

export default function InsoleEdit({ row }) {
  var extraSoftLng = lng.itm("M_CHK_INSOLE_XTR_SOFT_COVER");
  var isAdmin = Glb.Storage.usr.adm;
  const handleDateChange = (formattedDate) => {
    Glb.Storage.ord.odt = formattedDate;
  };
  return (
    <>
      <Grid container item direction="column" sm={6} spacing={1}>
        <Grid item>
          <CustomSelect value={row?.stp} lngKey="M_INP_INSOLE_FOOT_TYPE" obj="ord" />
        </Grid>
        <Grid item>
          <CustomSelect value={row?.ihs} lngKey="M_INP_INSOLE_HARDNESS" obj="ord" />
        </Grid>
      </Grid>
      <Grid container item direction="column" sm={6} spacing={1}>
        <Grid item>
          <CustomSelectProducts value={row?.tid} lngKey="M_INP_INSOLE_TYPES" obj="ord" cid={row?.cid} />
        </Grid>
        <Grid item>
          <CustomSelect value={row?.spf} lngKey="M_INP_INSOLE_STAGES" obj="ord" />
        </Grid>
      </Grid>
      <Grid container item direction="column" sm={6} spacing={1}>
        <Grid item>
          <CustomSelect value={row?.ilf} lngKey="M_INP_INSOLE_LEFT" obj="ord" />
        </Grid>
        <Grid item>
          <DatePicker value={row?.odt} handleChange={handleDateChange} lngKey="M_INP_ODT" />
        </Grid>
      </Grid>
      <Grid container item direction="column" sm={6} spacing={1}>
        <Grid item>
          <CustomSelect value={row?.cch} lngKey="M_INP_INSOLE_COVERING_CHOICES" obj="ord" />
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id={extraSoftLng.id}
                    name="extraSoft"
                    onChange={handleChange}
                    defaultChecked={row?.xts}
                  />
                }
                label={extraSoftLng.label}
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
