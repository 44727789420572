import { Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import theme from '../../theme'
import { lng } from '../../Tore/Sys'

export default function NewButton(props) {
    const registerPath = props.registerPath;
    var visible = props.visible;
    if (visible === undefined)
        visible = true;
    return (
        visible ?
            (<Button
                component={Link}
                to={registerPath}
                variant="contained"
                color="primary"
                size="small"
                style={{
                    width: 80
                }}
                startIcon={<AddCircleIcon />}
            >
                <Typography theme={theme}> {lng.itm("M_BTN_ADD")} </Typography>
            </Button>) : (<> </>)
    );
}
