import React, { useState, useEffect } from 'react';
import { lng } from '../../Tore/Sys'
import { Grid, makeStyles, Paper } from '@material-ui/core';
import FootPainAreasRight from './FootPainAreasRight';
import FootPainAreasLeft from './FootPainAreasLeft';
import foot from '../../images/ayak.png';
import body from '../../images/body.png';
import BodyPainAreasLeft from './BodyPainAreasLeft';
import BodyPainAreasRight from './BodyPainAreasRight';
import DiagnosisChecks from './DiagnosisChecks';

const useStyles = makeStyles((theme) => ({
    formCenter: {
        //alignItems: "center",
        justify: 'center'
    },
    footImg: {
        height: "250px",
        width: "250px"
    },
    bodyImg: {
        height: "200px",
        width: "200px"
    },
    paper: {
        // padding: theme.spacing(2),
        height: "100%",
        //color: "theme.palette.text.secondary",
        //justify: "center",
        display: "flex",
        //flexDirection: "column",
        justifyContent: "center",
    },
    paperCheckBoxList: {
        height: "100%",
        display: "flex",
        //justifyContent: "center",
    }
}));

export default function PainAreas(props) {

    const classes = useStyles();

    const footLabels = lng.itm("M_CHK_FT");
    const bodyLabels = lng.itm("M_CHK_BD");
    const [row, setRow] = useState();
    const [isRegister, setIsRegister] = useState(false);

    useEffect(() => {

        if (props.row) {
            setRow(props.row)

        }
        if (props.isRegister)
            setIsRegister(true)
        return () => {

        }
    }, [props.row])

    return (

        <Grid container className={classes.formCenter} direction="row" spacing={1}>
            <Grid container item direction="row">
                <Grid item sm={3}>
                    <Paper className={classes.paper} >
                        <h3 align="center">
                            {lng.itm("M_HDR_DIA_IND")}
                        </h3>
                    </Paper>
                </Grid>
                <Grid item sm={9} >
                    <Paper className={classes.paper}>
                        <h3 align="center">
                            {lng.itm("M_HDR_PAIN_AREAS")}
                        </h3>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container item >
                <Grid item sm={3}>
                    <Paper className={classes.paper}>
                        <DiagnosisChecks row={row} isEdit={row?.isEdit} isRegister={isRegister} />
                    </Paper>
                </Grid>
                <Grid container sm={3} direction="column" >
                    <Grid item style={{ height: "50%" }}>
                        <Paper className={classes.paperCheckBoxList}>
                            <FootPainAreasLeft row={row} formLabel={footLabels.formLabelL} isEdit={row?.isEdit} isRegister={isRegister} />
                        </Paper>
                    </Grid>
                    <Grid item style={{ height: "50%" }}>
                        <Paper className={classes.paperCheckBoxList}>
                            <BodyPainAreasLeft row={row} formLabel={bodyLabels.formLabelL} isEdit={row?.isEdit} isRegister={isRegister} />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container item sm={3} direction="column">
                    <Grid item style={{ height: "50%" }}>
                        <Paper className={classes.paper} style={{ textAlign: "center" }}>
                            <img alt="Foot pain areas" src={foot} className={classes.footImg} />
                        </Paper>
                    </Grid>
                    <Grid item style={{ height: "50%" }}>
                        <Paper className={classes.paper} >
                            <img alt="Human body" src={body} className={classes.bodyImg} />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container item sm={3} direction="column">
                    <Grid style={{ height: "50%" }}>
                        <Paper className={classes.paperCheckBoxList}>
                            <FootPainAreasRight row={row} side="right" formLabel={footLabels.formLabelR} isEdit={row?.isEdit} isRegister={isRegister} />
                        </Paper>
                    </Grid>
                    <Grid style={{ height: "50%" }}>
                        <Paper className={classes.paperCheckBoxList}>
                            <BodyPainAreasRight row={row} formLabel={bodyLabels.formLabelR} isEdit={row?.isEdit} isRegister={isRegister} />
                        </Paper>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>

        //     // {/* <h2> {lng.itm("M_HDR_DIA_PAIN_AREAS")} </h2> */ }

        //    <Grid container direction="row" alignItems="center" justify="center" >
        //         <Grid container alignItems="center" justify="center">
        //             <Grid item>
        //                 <FootPainAreas formLabel={footLabels.formLabelL} />
        //             </Grid>
        //             <Grid item>
        //                 <img src={foot} className={classes.footImg} />
        //             </Grid>
        //             <Grid item>
        //                 <FootPainAreas formLabel={footLabels.formLabelR} />
        //             </Grid>
        //         </Grid>

        //         <Grid container alignItems="center" justify="center">
        //             <Grid item>
        //                 <BodyPainAreas formLabel={bodyLabels.formLabelL} />
        //             </Grid>
        //             <Grid item>
        //                 <img src={body} className={classes.bodyImg} />
        //             </Grid>
        //             <Grid item>
        //                 <BodyPainAreas formLabel={bodyLabels.formLabelR} />
        //             </Grid>
        //         </Grid>

        //     </Grid>
    );
}