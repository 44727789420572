import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        // padding: theme.spacing(2),
        height: "100%",
        //color: "theme.palette.text.secondary",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "top",
        padding: "20px"
    },
}));

export default function CustomPaper({ children }) {
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            {children}
        </Paper>
    );
}
