import React, { useState } from 'react';
import Glb from '../../global/Glb';
import { useFormik } from 'formik';
import HttpPost from '../HttpPost';
import { useHistory } from 'react-router-dom'
import { Grid, TextField, makeStyles } from '@material-ui/core';
import { lng } from '../../Tore/Sys'
import CancelAndSubmitButton from '../CancelAndSubmitButton';
import SearchSelect from '../SearchSelect';
import CustomPaper from '../CustomPaper';
import PhoneInput from '../PhoneInput';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formCenter: {
        alignItems: "center",
        justify: "center"
    }
}));

const textFieldProps = {
    variant: "outlined",
    margin: "dense",
    size: "small"
}


export default function PatientRegister({ setPid, setPat, handleClose, com, usr }) {
    const classes = useStyles();
    const isAdmin = Glb.Storage.usr.adm;
    const history = useHistory();

    const [cid, setCid] = useState("");

    var comDisabled = false;


    const formik = useFormik({
        initialValues: {
            uid: Glb.Storage.usr.uid,
            cid: Glb.Storage.usr.cid
        },
        onSubmit: async values => {
            let patient = values;
            if (com)
                patient.cid = com.cid;
            if (usr)
                patient.uid = usr.uid;
            let ema = patient.ema;
            let response = await HttpPost("/patIns", patient);
            if (response) {
                if (response?.sta === "ACK") {
                    var pid = response?.dta.pid;
                    values.pid = pid;
                    values.act = true;
                    values.ema = ema;
                    Glb.Reload.pat = false;
                    Glb.RegisterSuccess.pat = true;
                    if (Glb.Storage.patLst) {
                        Glb.Storage.patLst.push(values);
                    } else {
                        Glb.Storage.patLst = [];
                        Glb.Storage.patLst.push(values);
                    }
                    if (setPat || setPid) {
                        if (setPat) {
                            if (values)
                                setPat(values);
                        }
                        if (setPid) {
                            if (values?.pid)
                                setPid(values.pid)
                        }
                        if (handleClose)
                            handleClose()
                    } else
                        history.goBack();
                }
            }
        },
    });

    return (


        <Grid container>
            <Grid item sm={2} />
            <Grid container className={classes.formCenter} direction="column">
                <CustomPaper>
                    <h3> {lng.itm("M_HDR_PAT_INS")} </h3>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container direction="column" className={classes.formCenter}>
                            {(isAdmin && (!usr) && (!com)) && (
                                <>
                                    <SearchSelect
                                        lngKey="M_INP_COM"
                                        use="form"
                                        setFormikField={formik.setFieldValue}
                                        setId={setCid}
                                        required
                                        disable={comDisabled}
                                        defObj={com}
                                        options={Glb.Storage.allComs}
                                    />

                                    <SearchSelect
                                        lngKey="M_INP_USR"
                                        use="form"
                                        setFormikField={formik.setFieldValue}
                                        id={cid}
                                        objProp="cid"
                                        defObj={usr}
                                        required
                                        options={Glb.Storage.allUsrs}

                                    />
                                </>
                            )}
                            <TextField id={lng.itm("M_INP_NAM").id} required label={lng.itm("M_INP_NAM").label} onChange={formik.handleChange} {...textFieldProps} />
                            <TextField id={lng.itm("M_INP_SUR").id} required label={lng.itm("M_INP_SUR").label} onChange={formik.handleChange} {...textFieldProps} />
                            <PhoneInput required setFormikField={formik.setFieldValue} variant="outlined" />
                            <TextField id={lng.itm("M_INP_EMA").id} label={lng.itm("M_INP_EMA").label} onChange={formik.handleChange} {...textFieldProps} />

                            <CancelAndSubmitButton handleClose={handleClose} />
                        </Grid>
                    </form>

                </CustomPaper>

            </Grid>
            <Grid item sm={2} />
        </Grid>



    );
}
