import { Button, FormControl, InputLabel, MenuItem, Select, Table, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import { lng } from '../../Tore/Sys'
import Pagination from './Pagination';
import SearchSelect from '../SearchSelect';
import Glb from '../../global/Glb';
import HttpPost from '../HttpPost';
import PhoneInput from '../PhoneInput';
import DatePicker from '../DatePicker';
import Helper from '../../utils/Helper';

export default function SearchTable(props) {

    const actRows = lng.itm("M_INP_ACT_ITMS");
    const ookRows = lng.itm("M_INP_OOK_ITMS");
    var { registerPath, optionsLngKey, searchInputs, searchPath, setRows, newButtonVisible, isPrd } = props;

    const specialInputs = ["M_INP_COM", "M_INP_USR", "M_INP_PAT", "M_INP_DIA", "M_INP_PRD", "M_INP_ACT", "M_INP_OOK", "M_INP_TYP", "M_INP_PNO", "M_INP_ODS", "M_INP_OET"]

    const paginationComponentCellCount = 4;
    var emptyCellCount = searchInputs.length + 2 - paginationComponentCellCount;
    // if (isPrd == true)
    //     emptyCellCount = emptyCellCount - 1;
    const emptyCellArray = new Array(emptyCellCount).fill(0);

    const [page, setPage] = useState(1);
    const [cid, setCid] = useState();

    useEffect(() => {

        return () => {

        }
    }, [cid])

    const handleChange = (e) => {
        if (e.target.value || e.target.value === false)
            Glb.Filter.dta[e.currentTarget.id] = e.target.value;
        else
            delete Glb.Filter.dta[e.currentTarget.id]
        // console.log(e)
    }

    const handleOetChange = (timeStamp) => {
        if (timeStamp)
            Glb.Filter.dta["oet"] = timeStamp;
        else
            delete Glb.Filter.dta["oet"]
    }

    const onClick = () => {
        Glb.Filter.skp = 0;
        setPage(1);
        handleSearch();
    }
    const handleSearch = async () => {
        Helper.resetRegisterAlerts();
        Helper.resetUpdateAlerts();
        let dta = Glb.Filter.dta;

        let keysLength = Object.keys(dta).length;
        if (dta &&
            !(keysLength === 0) &&
            (dta.constructor === Object)) {

            let response = await HttpPost(searchPath, Glb.Filter)
            if (response.sta === "ACK") {

                Glb.ListFiltered[searchPath.substring(1, 4)] = true;
                if (setRows) {
                    setRows([])
                    if (response.dta)
                        setRows(response.dta);
                }
            }

        } else {
            alert(lng.itm("M_WARN_INV_FILTER"));
        }
    }

    return (
        <>
            <Table>
                <TableHead>
                    {searchPath && (
                        <TableRow>

                            <TableCell />
                            {searchInputs.map((input) => (
                                (!specialInputs.includes(input)) ?
                                    (<TableCell key={lng.itm(input).id}><TextField fullWidth id={lng.itm(input).id} label={lng.itm(input).label} onChange={handleChange} /></TableCell>)
                                    :
                                    (
                                        (() => {
                                            switch (input) {

                                                case "M_INP_ACT":
                                                    return (
                                                        <TableCell>
                                                            <FormControl fullWidth style={{ minWidth: 60 }}>
                                                                <InputLabel id={lng.itm("M_INP_ACT").id}>{lng.itm("M_INP_ACT").label}</InputLabel>
                                                                <Select

                                                                    id={lng.itm("M_INP_ACT").id}
                                                                    labelId={lng.itm("M_INP_ACT").id}
                                                                    onChange={handleChange}
                                                                    defaultValue={true}

                                                                >
                                                                    {actRows.map((itm) => (
                                                                        <MenuItem id={lng.itm("M_INP_ACT").id} value={itm.value}>{itm.text}</MenuItem>
                                                                    ))}

                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                    );
                                                case "M_INP_OOK":
                                                    return (
                                                        <TableCell>
                                                            <FormControl style={{ minWidth: 60 }}>
                                                                <InputLabel id={lng.itm("M_INP_OOK").id}>{lng.itm("M_INP_OOK").label}</InputLabel>
                                                                <Select
                                                                    labelId={lng.itm("M_INP_OOK").id}
                                                                    id={lng.itm("M_INP_OOK").id}
                                                                    onChange={handleChange}
                                                                    defaultValue={-1}
                                                                >
                                                                    {ookRows.map((itm) => (
                                                                        <MenuItem id={lng.itm("M_INP_OOK").id} value={itm.value}>{itm.text}</MenuItem>
                                                                    ))}

                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                    );

                                                case "M_INP_PNO":
                                                    return (
                                                        <TableCell>
                                                            <PhoneInput />
                                                        </TableCell>
                                                    );
                                                case "M_INP_TYP":
                                                    const typRows = lng.itm("M_INP_TYP_ITMS");
                                                    return (
                                                        <TableCell>
                                                            <FormControl fullWidth>
                                                                <InputLabel id={lng.itm("M_INP_TYP").id}>{lng.itm("M_INP_TYP").label}</InputLabel>
                                                                <Select
                                                                    labelId={lng.itm("M_INP_TYP").id}
                                                                    id={lng.itm("M_INP_TYP").id}
                                                                    onChange={handleChange}
                                                                >
                                                                    {typRows.map((itm) => (
                                                                        <MenuItem id={lng.itm("M_INP_TYP").id} value={itm.value}>{itm.text}</MenuItem>
                                                                    ))}

                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                    );
                                                case "M_INP_ODS":
                                                    const odsData = lng.itm("M_INP_ODS");

                                                    return (
                                                        <TableCell>
                                                            <FormControl fullWidth>
                                                                <InputLabel id={odsData.id}>{odsData.label}</InputLabel>
                                                                <Select
                                                                    labelId={odsData.id}
                                                                    id={odsData.id}
                                                                    onChange={handleChange}
                                                                    defaultValue={false}
                                                                >
                                                                    {odsData?.items.map((itm) => (
                                                                        <MenuItem id={odsData.id} value={itm.value}>{itm.text}</MenuItem>
                                                                    ))}

                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                    );
                                                case "M_INP_USR":
                                                    return (
                                                        <TableCell >
                                                            <SearchSelect use="search" lngKey={input} id={cid} objProp="cid"
                                                            />
                                                        </TableCell>
                                                    );
                                                case "M_INP_COM":
                                                    return (
                                                        <TableCell >
                                                            <SearchSelect use="search" lngKey={input} setId={setCid}
                                                            />
                                                        </TableCell>
                                                    );

                                                case "M_INP_OET":
                                                    return (
                                                        <TableCell >
                                                            <DatePicker inputVariant="standard" lngKey={input} handleChange={handleOetChange} maxDate={new Date()}> </DatePicker>
                                                        </TableCell>
                                                    );

                                                default:
                                                    return (
                                                        <TableCell >
                                                            <SearchSelect use="search" lngKey={input}
                                                            />
                                                        </TableCell>
                                                    );

                                            }
                                        })()

                                    )
                            ))}

                            <TableCell align="center">
                                <Button
                                    style={{
                                        width: 80
                                    }}
                                    onClick={onClick}
                                    component={Link}
                                    variant="contained"
                                    size="small"
                                    startIcon={<SearchIcon />}
                                >
                                    <Typography> {lng.itm("M_BTN_SEA")} </Typography>
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}

                    <TableRow>

                        {emptyCellArray.map(() => (
                            <TableCell />
                        ))}

                        <Pagination registerPath={registerPath} optionsLngKey={optionsLngKey} handleSearch={handleSearch} newButtonVisible={newButtonVisible} searchPath={searchPath} page={page} setPage={setPage} isPrd={isPrd} />

                    </TableRow>
                </TableHead>
            </Table>
        </>
    );
}
