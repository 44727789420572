import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { lng } from '../../Tore/Sys'
import Glb from '../../global/Glb';
import checkBoxChecked from '../../images/checkboxChecked.png'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3)
    },
}));

export default function FootPainAreas(props) {

    const classes = useStyles();

    const labels = lng.itm("M_CHK_FT");

    const isEdit = props?.isEdit;
    const isRegister = props?.isRegister;

    const isInfo = (props?.row != undefined) && (!isEdit && !isRegister);
    const fillValue = (props?.row != undefined) && (isInfo || isEdit || isRegister);

    const [state, setState] = React.useState({
        footRPainOne: false,
        footRPainTwo: false,
        footRPainThree: false,
        footRPainFour: false,
        footRPainFive: false,
        footRPainSix: false,
        footRPainSeven: false,
        footRPainEight: false,
    });

    useEffect(() => {

        if (fillValue) {
            let row = props.row; // foot pain area (maybe left or right foot)

            let checkBoxesState = {
                footRPainOne: row.footRPainOne === true ? (true) : (false),
                footRPainTwo: row.footRPainTwo === true ? (true) : (false),
                footRPainThree: row.footRPainThree === true ? (true) : (false),
                footRPainFour: row.footRPainFour === true ? (true) : (false),
                footRPainFive: row.footRPainFive === true ? (true) : (false),
                footRPainSix: row.footRPainSix === true ? (true) : (false),
                footRPainSeven: row.footRPainSeven === true ? (true) : (false),
                footRPainEight: row.footRPainEight === true ? (true) : (false),
            }
            setState(checkBoxesState);
        }
    }, [props.row]);

    const handleChange = (event) => {
        if (isEdit || !isInfo) {
            var value = event.target.checked;
            var name = event.target.name;
            if (name) {
                if (value && value === false)
                    delete Glb.Storage.dia[name]
                else
                    Glb.Storage.dia[name] = value;

                setState({ ...state, [name]: value });
            }

        }
    };

    const { footRPainOne, footRPainTwo, footRPainThree, footRPainFour, footRPainFive, footRPainSix, footRPainSeven, footRPainEight } = state;
    const myCheckBox = () => {
        return (
            <div style={{
                width: '21.33px',
                height: '21.33px',
                display: 'inline-block',
            }} >
                <img src={checkBoxChecked} style={{
                    marginTop: "2px",
                    width: '17.33px',
                    height: '17.33px',
                    display: 'inline-block',
                }} />
            </div>
        )
    }
    return (
        <div className={classes.root}>
            <FormControl className={classes.formControl}>
                <FormLabel>{props.formLabel}</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={footRPainOne} onChange={handleChange} name="footRPainOne" />}
                        label={<span style={footRPainOne ? { fontWeight: 'bold' } : {}}>{labels.one}</span>}
                        //label={labels.one}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={footRPainTwo} onChange={handleChange} name="footRPainTwo" />}
                        label={<span style={footRPainTwo ? { fontWeight: 'bold' } : {}}>{labels.two}</span>}
                        //label={labels.two}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={footRPainThree} onChange={handleChange} name="footRPainThree" />}
                        label={<span style={footRPainThree ? { fontWeight: 'bold' } : {}}>{labels.three}</span>}
                        //label={labels.three}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={footRPainFour} onChange={handleChange} name="footRPainFour" />}
                        label={<span style={footRPainFour ? { fontWeight: 'bold' } : {}}>{labels.four}</span>}
                        //label={labels.four}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={footRPainFive} onChange={handleChange} name="footRPainFive" />}
                        label={<span style={footRPainFive ? { fontWeight: 'bold' } : {}}>{labels.five}</span>}
                        //label={labels.five}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={footRPainSix} onChange={handleChange} name="footRPainSix" />}
                        label={<span style={footRPainSix ? { fontWeight: 'bold' } : {}}>{labels.six}</span>}
                        //label={labels.six}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={footRPainSeven} onChange={handleChange} name="footRPainSeven" />}
                        label={<span style={footRPainSeven ? { fontWeight: 'bold' } : {}}>{labels.seven}</span>}
                        //label={labels.seven}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={footRPainEight} onChange={handleChange} name="footRPainEight" />}
                        label={<span style={footRPainEight ? { fontWeight: 'bold' } : {}}>{labels.eight}</span>}
                        //label={labels.eight}
                    />
                </FormGroup>
                <FormHelperText></FormHelperText>
            </FormControl>
        </div>
    );
}
