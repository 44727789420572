import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { lng } from "../Tore/Sys";
import Glb from "../global/Glb";

export default function RadioButtonsGroup({ lngKey, defVal }) {
  const [value, setValue] = React.useState(defVal);

  const handleChange = (e) => {
    var radio = e.target.value;
    var id = e.target.id;
    if (id) {
      if (radio || radio === false || radio === 0) {
        Glb.Storage.ord.tcl = radio;
        Glb.Errors.Slipper["tcl"] = false;
        setValue(radio);
      }
    }
  };

  const options = lng.itm(lngKey);

  return (
    <FormControl>
      <FormLabel> {options.label} </FormLabel>
      <RadioGroup row value={value} onChange={handleChange}>
        {options.items.map((item) => (
          <FormControlLabel
            value={item.value.toString()}
            control={<Radio id={options.id} />}
            label={item.text}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
