import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Exc, lng } from '../../Tore/Sys';
import { useLocation, useHistory } from 'react-router-dom'
import SearchTable from '../table/SearchTable';
import DataTable from '../table/DataTable';
import Glb from '../../global/Glb';
import HttpPost from '../HttpPost';


const useStyles = makeStyles((theme) => ({
    formCenter: {
        alignItems: "center",
        justify: "center",
        height: 300,
        width: "%100",
        margin: "dense",

    },
    table: {
        fontSize: "10px"
    },
    button: {
        margin: theme.spacing(1),
        justifyContent: "center"
    },
}));

export default function ProductCompanyRelations(props) {


    const classes = useStyles();
    const columnsTag = "M_TBL_COMPRD_HDR";
    const searchInputs = ["M_INP_NAM", "M_INP_ACT"]

    const [rows, setRows] = useState([]);

    const location = useLocation();
    const history = useHistory();
    const [row, setRow] = useState(location?.state?.record);

    let type = ""
    if (row?.typ === "Tabanlik") {
        type = "Tabanlık";
    }

    const handleSearch = async () => {
        let dta = Glb.Filter.dta;
        let keysLength = Object.keys(dta).length;
        if (dta &&
            !(keysLength === 0) &&
            (dta.constructor === Object)) {
            //alert(JSON.stringify(Glb.Filter))
            let response = await HttpPost("/comLst", Glb.Filter)
            if (response.sta === "ACK")
                setRows(response.dta);
            else
                setRows([])

            //alert(JSON.stringify(Glb.Storage.comLst))
        }
    }

    useEffect(() => {
        //console.log(location); // result: '/secondpage'
        if (location?.state?.record === undefined) {
            history.push("/products")
        }
        //alert(JSON.stringify(location?.state?.record))
        Glb.ListFiltered.prd = false;
        Glb.Filter.dta = { tid: row.tid }
        Glb.Filter.skp = 0;
        let dta = Glb.Filter.dta;
        //alert(JSON.stringify(row))
        handleSearch();

        return () => {
            Glb.Filter.dta = {}
            Glb.Filter.skp = 0;
            Glb.ListFiltered.prd = false;
        }
    }, [location, history]);

    const erasable = true;

    return (
        <Exc>
            <Grid container>
                <Grid container>
                    <Grid item xm={"false"} sm={2} />
                    <Grid container className={classes.formCenter} xm={12} sm={8}>
                        {/* <ProductTable /> */}
                        <Typography variant="h4" style={{ paddingTop: "20px" }}>
                            {row?.nam + " " + type + " " + lng.itm("M_TBL_PRD_COM_REL_LBL")}
                        </Typography>
                        <SearchTable searchInputs={searchInputs} registerPath="/productCompanyRegister" />
                        <DataTable rows={rows} columnsTag={columnsTag} editPath="/productEdit" erasable={erasable} editable={false} typeId={row?.tid} setRows={setRows} keyExtractor={"cpi"} />
                    </Grid>
                </Grid>
                <Grid item xm={"false"} sm={2} />
            </Grid>
        </Exc>
    );
}