import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, IconButton, Typography, LinearProgress } from '@material-ui/core';
import uuid from "uuid/v4";
import { post } from "axios";
import { lng } from '../Tore/Sys'
import Glb from '../global/Glb';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#696969',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


export default function FileInput() {
    const [myFiles, setMyFiles] = useState([])
    const [uploads, setUploads] = useState([]);
    const [progress, setProgress] = useState(0);

    const onDrop = useCallback(acceptedFiles => {
        setMyFiles([...myFiles, ...acceptedFiles])
    }, [myFiles])

    const removeFile = file => () => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
    }

    useEffect(() => {

        return () => {

        }
    }, [])

    const uploadFile = () => async () => {

        var cid = Glb.Storage.ord.cid;
        var uid = Glb.Storage.ord.uid;
        var oid = Glb.Storage.ord.oid;

        if (!cid)
            alert("Lütfen firma seçiniz")
        //alert(JSON.stringify(cid))

        let response = localStorage.getItem("response");
        let resObj = JSON.parse(response);
        var rx = {
            "rid": uuid(),
            "tim": "20210727062854805",
            "src": resObj.tar,
            "tar": "File",
            "dta": {
                "cid": cid,
                "uid": uid,
                "oid": oid
            }
        }

        const formData = new FormData();
        formData.append("rx", JSON.stringify(rx));

        setProgress(0);

        for (var file of myFiles)
            formData.append('files', file)


        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: data => {
                //Set the progress value to show the progress bar
                setProgress(Math.round((100 * data.loaded) / data.total))
            },
        }

        //const url = 'https://localhost:7443/UploadFiles';
        const url = "https://www.footmedlab.com:7443/UploadFiles"
        var asd = await post(url, formData, config)
        var data = asd.data;
        if (data?.sta === "ACK") {

            Glb.Errors.ord.fil = false;
            // const newUploads = [...uploads];
            // var uploadedFile = newUploads.find(u => u.path === file.path);
            // if (uploadedFile) {
            //     const index = newUploads.indexOf(uploadedFile);
            //     uploadedFile.ack = true;
            //     newUploads[index] = uploadedFile;
            //     setUploads(newUploads);
            // } else {
            //     file.ack = true;
            //     newUploads.push(file);
            //     setUploads(newUploads);
            // }
        } else if (data?.sta === "EXC") {
            // const newUploads = [...uploads];
            // var uploadedFile = newUploads.find(u => u.path === file.path);
            // if (uploadedFile) {
            //     const index = newUploads.indexOf(uploadedFile);
            //     uploadedFile.ack = false;
            //     newUploads[index] = uploadedFile;
            //     setUploads(newUploads);
            // } else {
            //     file.ack = false;
            //     newUploads.push(file);
            //     setUploads(newUploads);
            // }
        }
        //alert(JSON.stringify(asd));
    }

    const removeAll = () => {
        setMyFiles([])
    }

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        open,
    } = useDropzone({
        noClick: true,
        addRemoveLinks: true,
        onDrop
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const files = myFiles.map(file => {

        var uploadedFile = uploads.find(u => u.path === file.path);
        var ack = undefined
        if (uploadedFile) {
            ack = uploadedFile.ack;
        }

        return (
            <li key={file.path}>
                <IconButton onClick={removeFile(file)} >
                    <DeleteIcon />
                </IconButton>
                {file.path} - {formatBytes(file.size)}
            </li>
        )
    })

    return (
        <div className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p> {lng.itm('M_LBL_DROPZONE')} </p>
                <button type="button" onClick={open}>
                {lng.itm('M_BTN_SELECT_FILE')}
                </button>
            </div>
            <div>
                <p> {files.length > 0 && lng.itm('M_LBL_FILES')} </p>
                <ul>
                    {files}
                    {files.length > 0 && <button onClick={removeAll}>{lng.itm('M_LBL_DELETE_ALL')}</button>}
                    {files.length > 0 && <button onClick={uploadFile(myFiles)}>{lng.itm('M_LBL_UPLOAD')}</button>}
                </ul>
                <div>
                    {progress > 0 && (<Box display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                            <LinearProgress variant="determinate" value={progress} />
                        </Box>
                        <Box minWidth={35}>
                            <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                        </Box>
                    </Box>)}
                </div>
            </div>
        </div>
    );
}
