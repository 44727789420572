import React from "react";
import { Route, Redirect } from "react-router-dom";
import glb from "../global/Glb";

function PrivateGuestRoute({ children, ...rest }) {
  const isGuest = !glb.Storage.usr.isloggedIn;
  return (
    <Route
      {...rest}
      render={({ location }) =>
      isGuest ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateGuestRoute;