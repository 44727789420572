import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Glb from '../global/Glb'
import SearchTable from '../components/table/SearchTable';
import DataTable from '../components/table/DataTable';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DiagnosisRegister from '../components/diagnosis/DiagnosisRegister';
import HttpPost from '../components/HttpPost';
const useStyles = makeStyles((theme) => ({
    formCenter: {
        alignItems: "center",
        justify: "center",
        height: 300,
        width: "%100",
        margin: "dense",

    },
    table: {
        fontSize: "10px"
    },
    button: {
        margin: theme.spacing(1),
        justifyContent: "center"
    },
    root: (props) => {
        return {
            "& .MuiDialog-container > div": {
                width: "1200px",
                height: "800px",
                maxWidth: "1200px",
                "& .css-m1b0vd-MuiDialogTitle-root": {
                    padding: "16px 24px 10px",
                },
                "& .css-1y6f8wu-MuiDialogContent-root": {
                    "& .css-wffnfh": {
                        padding: "24px",
                    },
                },
            },
        };
    },
}));



export default function Diagnosis() {
    const classes = useStyles();
    var [reRender, setReRender] = useState({});
    const [rows, setRows] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState("paper");

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);

    React.useEffect(() => {
        async function getList() {
            var res = await HttpPost("/diaLst", Glb.Filter);

            if (res?.sta === "ACK") {
                if (res?.dta?.length > 0)
                    setRows(res.dta);
            }
        }

        if (Glb.Filter?.dta) {
            Glb.Filter.dta.act = true;
        }
        else {
            Glb.Filter.dta = { act: true };
        }
        Glb.Filter.skp = 0;
        Glb.ListFiltered.com = false;
        Glb.ListFiltered.usr = false;
        Glb.ListFiltered.dia = true;
        //alert(Glb.Reload.dia)
        if (!Glb.Reload.dia)
            setRows(Glb.Storage.diaLst)
        else {
            getList();
        }

        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
        return () => {
            Glb.Filter.dta = {}
            Glb.Filter.skp = 0;
            Glb.ListFiltered.dia = false;
        }
    }, []);

    var columnsTag = "M_TBL_DIA_ADM_HDR";
    const searchInputs = ["M_INP_PAT_NAM", "M_INP_PAT_SUR", "M_INP_PNO", "M_INP_COM", "M_INP_USR", "M_INP_DDM", "M_INP_ACT"];
    const isAdmin = Glb.Storage.usr.adm;

    if (!isAdmin) {
        columnsTag = "M_TBL_DIA_HDR";
        const index = searchInputs.indexOf("M_INP_COM");
        if (index > -1) {
            searchInputs.splice(index, 1);
        }
    }

    return (
        <Grid container>
            <Grid container>
                <Grid item xm={"false"} xs={0} sm={2} />
                <Grid container className={classes.formCenter} xs={12} sm={8}>
                    <SearchTable newButtonVisible={false} setRows={setRows} searchInputs={searchInputs} registerPath="/diagnosisRegister" searchPath="/diaLst" />
                    <DataTable rows={rows} columnsTag={columnsTag} diaPath="/diagnosisInformation" editPath="/diagnosisInformation" editable={true} registeredObj="dia" setReRender={setReRender} keyExtractor="did" />
                </Grid>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    classes={{
                        root: classes.root,
                    }}
                >
                    <DialogContent dividers={scroll === "paper"}>
                        <DiagnosisRegister />
                    </DialogContent>
                </Dialog>
            </Grid>
            <Grid item xm={"false"} xs={0} sm={2} />
        </Grid>

    );
}