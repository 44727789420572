import { Grid } from "@material-ui/core";
import React from "react";
import Glb from "../../global/Glb";
import { lng } from "../../Tore/Lng";
//import { lng } from '../../Tore/Sys'
import CustomSelect from "../CustomSelect";
import DatePicker from "../DatePicker";
import RadioButtonsGroup from "../RadioButtons";

export default function ShoeInputs() {

  var threadColorLng = lng.itm("M_INP_SLIPPER_TREAD_COLOR");

  const handleDateChange = (formattedDate) => {
    Glb.Storage.ord.odt = formattedDate;
    Glb.Errors.ord["odt"] = false;
  };

  return (
    <>
      <Grid container item direction="column" sm={6} spacing={1}>
        <Grid item>
          <CustomSelect lngKey="M_INP_SLIPPER_TYPES" obj="ord" />
        </Grid>
        <Grid item>
          <CustomSelect lngKey="M_INP_SLIPPER_MATERIAL_COLOR" obj="ord" />
        </Grid>
      </Grid>
      <Grid container item direction="column" sm={6} spacing={1}>
        <Grid item>
          <CustomSelect lngKey="M_INP_SLIPPER_LEATHER_TYPES" obj="ord" />
        </Grid>
        <Grid item>
          <RadioButtonsGroup
            lngKey="M_INP_SLIPPER_TREAD_COLOR"
            obj="ord"
          />
        </Grid>
        {/* <Grid item>
          <CustomSelect lngKey="M_INP_SLIPPER_FILE_TYPES" />
        </Grid> */}
      </Grid>
      <Grid container item direction="column" sm={6} spacing={1}>
        {/* <Grid item>
          <DatePicker handleChange={handleDateChange} />
        </Grid> */}
      </Grid>
      <Grid container item direction="column" sm={6} spacing={1}>

      </Grid>
    </>
  );
}
