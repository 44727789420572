import React, { useState } from "react";
import { lng } from "../Tore/Sys";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  IconButton,
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import CompanyEditForm from "./company/CompanyEditForm";
import ProductEditForm from "./product/ProductEditForm";
import UserEditForm from "./user/UserEditForm";
import PatientEditForm from "./patient/PatientEditForm";
import OrderEdit from "./order/OrderEdit";
import HttpPost from "./HttpPost";
import { useFormik } from "formik";
import Glb from "../global/Glb";
import DiagnosisInformation from "./diagnosis/DiagnosisInformation";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "15%",
  },
  modalBackground: {
    width: "25%",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    borderRadius: 15,
    borderColor: "primary.main",
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
  },
  dialogTitle: {
    margin: "0px",
  },
  textField: {
    marginRight: "25%",
    marginLeft: "25%",
    marginTop: "1%",
    marginBottom: "1%",
  },
  selectComponent: {
    marginRight: "25%",
    marginLeft: "25%",
    marginTop: "1%",
    marginBottom: "1%",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  root: (props) => {
    return {
      "& .MuiDialog-container > div": {
        width: "1200px",
        height: "800px",
        maxWidth: "1200px",
        "& .css-m1b0vd-MuiDialogTitle-root": {
          padding: "16px 24px 10px",
        },
        "& .css-1y6f8wu-MuiDialogContent-root": {
          "& .css-wffnfh": {
            padding: "24px",
          },
        },
      },
    };
  },
  rootOrder: (props) => {
    return {
      "& .MuiDialog-container > div": {
        width: "700px",
        height: "600px",
        maxWidth: "1200px",
        "& .css-m1b0vd-MuiDialogTitle-root": {
          padding: "16px 24px 10px",
        },
        "& .css-1y6f8wu-MuiDialogContent-root": {
          "& .css-wffnfh": {
            padding: "24px",
          },
        },
      },
    };
  },
}));

function clean(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

function isValidForSubmit(values) {
  if (values && Object.keys(values).length === 0 && values.constructor === Object) {
    return false;
  }
  return true;
}

function EditButton(props) {
  const paths = {
    PRODUCT: "/productEdit",
    COMPANY: "/companyEdit",
    USER: "/userEdit",
    DIAGNOSIS: "/diagnosisInformation",
    PATIENT: "/patientEdit",
    ORDER: "/orderEdit",
  };

  const [row, setRow] = useState(props.row);
  const classes = useStyles();
  const setPnm = props.setPnm;

  async function fetchRecordByXid(xid, endpoint, idStr,) {
    var dta = {};
    dta[idStr] = xid;
    var data = {
      dta: dta,
      lim: 1,
      skp: 0
    }
    var res = await HttpPost(endpoint, data);
    if (res?.sta === "ACK") {
      if (res?.dta)
        setRow(res.dta[0])
    }

  }

  var setReRender = props.setReRender;
  var rowIndex = props.rowIndex;
  var getFromServer = props.getFromServer;

  //alert(JSON.stringify(row))

  var formikCompany = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      values = clean(values);
      var isValid = isValidForSubmit(values);
      if (!isValid) {
        alert(lng.itm("M_WARN_INV_VALS"));
        return;
      }
      values.iid = row.cid;
      values.index = rowIndex;
      let response = await HttpPost("/comUpd", values);
      if (response.sta === "ACK") {
        Glb.EditSuccess.com = true;
        handleModalClose();
      }
      //alert(JSON.stringify(response, null, 2));
    },
  });
  var formikProduct = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      values = clean(values);
      var isValid = isValidForSubmit(values);
      if (!isValid) {
        alert(lng.itm("M_WARN_INV_VALS"));
        return;
      }
      values.iid = row.tid;
      values.index = rowIndex;

      let response = await HttpPost("/prdUpd", values);
      if (response.sta === "ACK") {
        Glb.EditSuccess.prd = true;
        handleModalClose();
      }
      //alert(JSON.stringify(response, null, 2));
    },
  });

  var formikUser = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      values = clean(values);
      var isValid = isValidForSubmit(values);
      if (!isValid) {
        alert(lng.itm("M_WARN_INV_VALS"));
        return;
      }
      if (values?.pwd) {
        if (values.pwd.length < 6)
          alert(lng.itm("M_WARN_INV_PASS"));
      }
      values.index = rowIndex;
      values.iid = row.uid;
      let response = await HttpPost("/usrUpd", values);
      if (response?.sta === "ACK") {
        Glb.EditSuccess.usr = true;
        handleModalClose();
      }
    },
  });

  var formikPatient = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      //alert(JSON.stringify(values));
      var isValid = isValidForSubmit(values);
      if (!isValid) {
        alert(lng.itm("M_WARN_INV_VALS"));
        return;
      }
      values = clean(values);
      values.iid = row.pid;
      values.index = rowIndex;


      let response = await HttpPost("/patUpd", values);
      if (response.sta === "ACK") {
        Glb.EditSuccess.pat = true;
        if (values?.nam || values?.sur) {
          if (setPnm) {
            var data = {
              lim: 1,
              skp: 0
            };
            data.dta = {};
            data.dta.pid = row.pid;
            var patResp = await HttpPost("/patLst", data);
            if (patResp?.sta === "ACK") {
              if (patResp?.dta) {
                var p = patResp.dta[0];
                var patNam = p.nam + " " + p.sur;
                setPnm(patNam);
              }
            }
          }
        }
        handleModalClose();
      }
    },
  });

  const [openDiagnosis, setOpenDiagnosis] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpenDiagnosis = (scrollType) => () => {
    setOpenDiagnosis(true);
    setScroll(scrollType);
  };

  const handleCloseDiagnosis = () => {
    setOpenDiagnosis(false);
    if (setReRender) {
      setReRender({});

    }
  };

  const [open, setOpen] = useState(false);

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, []);


  const handleModalOpen = () => {
    if (getFromServer && getFromServer) {

      switch (props.path) {
        case paths.PRODUCT:
          setRow(fetchRecordByXid(props.row?.tid, "/prdLst", "tid"));
          break;
        case paths.COMPANY:
          setRow(fetchRecordByXid(props.row?.cid, "/comLst", "cid"));
          break;
        case paths.USER:
          setRow(fetchRecordByXid(props.row?.uid, "/usrLst", "uid"));
          break;
        case paths.PATIENT:
          //alert(JSON.stringify(props?.row))
          Glb.ListFiltered.pat = false;
          fetchRecordByXid(props.row?.pid, "/patLst", "pid");
          break;
        case paths.DIAGNOSIS:
          setRow(fetchRecordByXid(props.row?.did, "/diaLst", "did"));
          break;
        case paths.ORDER:
          setRow(fetchRecordByXid(props.row?.oid, "/ordLst", "oid"));
          break;
        default:
          return null;
      }

    }
    setOpen(true);
  };
  const handleModalClose = () => {
    setOpen(false);
    if (setReRender) {
      setReRender({});
    }

  };

  const handleActChange = (e) => {
    let currentTarget = e.currentTarget;
    if (currentTarget) {
      let value = currentTarget.getAttribute("data-value");
      console.log(value);
      e.target.id = "act";
      if (value) {
        if (value === "false") e.target.value = false;
        else e.target.value = true;
        return e;
      }
    }
    return null;
  };

  const parseTablePath = (_) => {
    const { classes } = props;

    switch (props.path) {
      case paths.PRODUCT:
        return productEditPopup(classes);
      case paths.COMPANY:
        return companyEditPopup(classes);
      case paths.USER:
        return userEditPopup(classes);
      case paths.PATIENT:
        return patientEditPopup(classes);
      case paths.DIAGNOSIS:
        return diagnosisEdit(props.history, props.path, props.row);
      case paths.ORDER:
        return orderEdit(props.history, props.path, props.row);
      default:
        return null;
    }
  };

  const productEditPopup = (classes) => {
    return (
      <>
        <IconButton aria-label="edit" size="small" onClick={handleModalOpen}>
          <EditIcon size="small" />
        </IconButton>

        <Dialog
          fullWidth
          maxWidth="xs"
          onClose={handleModalClose}
          open={open}
          style={{ paddingBottom: "15%" }}
        >
          <DialogTitle
            className={classes.dialogTitle}
            onClose={handleModalClose}
          >
            <b>{lng.itm("M_HDR_PRD_UPD")}</b>
            <IconButton
              className={classes.closeButton}
              onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={formikProduct.handleSubmit}>
            <DialogContent
              fullWidth
              dividers
              style={{ marginBottom: "1%", marginTop: "1%" }}
            >
              <ProductEditForm
                record={props.row}
                onChange={formikProduct.handleChange}
                handleActChange={handleActChange}
              />
            </DialogContent>
            <DialogContent
              style={{
                marginRight: "25%",
                marginLeft: "25%",
                marginBottom: "1%",
                marginTop: "1%",
              }}
            >
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                size="small"
              >
                {lng.itm("M_INP_UPD")}
              </Button>
            </DialogContent>
          </form>
        </Dialog>
      </>
    );
  };

  const companyEditPopup = (classes) => {
    // const handleActChange = (e) => {
    //     let currentTarget = e.currentTarget;
    //     if (currentTarget) {
    //         let value = currentTarget.getAttribute("data-value");
    //         e.target.id = "act";
    //         if (value) {
    //             if (value === "false")
    //                 Glb.Storage.com.act = false;
    //             else
    //                 Glb.Storage.com.act = true;
    //         }
    //     }
    // }

    return (
      <>
        <IconButton aria-label="edit" size="small" onClick={handleModalOpen}>
          <EditIcon size="small" />
        </IconButton>

        <Dialog
          fullWidth
          maxWidth="xs"
          onClose={handleModalClose}
          open={open}
          style={{ paddingBottom: "15%" }}
        >
          <DialogTitle
            align="center"
            className={classes.dialogTitle}
            onClose={handleModalClose}
          >
            <b className={classes.dialogTitle}>{lng.itm("M_HDR_COM_UPD")}</b>
            <IconButton
              className={classes.closeButton}
              onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={formikCompany.handleSubmit}>
            <DialogContent fullWidth dividers style={{ overflow: "hidden" }}>
              <CompanyEditForm
                record={props.row}
                onChange={formikCompany.handleChange}
                handleActChange={handleActChange}
                setFormikField={formikCompany.setFieldValue}
              />
            </DialogContent>

            <DialogContent style={{ overflow: "hidden" }}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                size="small"
              >
                {lng.itm("M_INP_UPD")}
              </Button>
            </DialogContent>
          </form>
        </Dialog>
      </>
    );
  };

  const userEditPopup = (classes) => {
    return (
      <>
        <IconButton aria-label="edit" size="small" onClick={handleModalOpen}>
          <EditIcon size="small" />
        </IconButton>

        <Dialog
          align="center"
          onClose={handleModalClose}
          open={open}
          style={{ paddingBottom: "15%" }}
        >
          <DialogTitle
            className={classes.dialogTitle}
            onClose={handleModalClose}
            align="center"
          >
            <Grid container direction="row">
              <Grid item sm={10}>
                <b className={classes.dialogTitle}>
                  {" "}
                  {lng.itm("M_HDR_USR_UPD")}{" "}
                </b>
              </Grid>
              <Grid item sm={2}>
                <IconButton
                  align="center"
                  justify="center"
                  onClick={handleModalClose}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <form onSubmit={formikUser.handleSubmit}>
            <DialogContent align="center">
              <UserEditForm
                record={props.row}
                setFormikField={formikUser.setFieldValue}
                handleChange={formikUser.handleChange}
                handleActChange={handleActChange}
              />
            </DialogContent>
            <DialogContent align="center" style={{ overflow: "hidden" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
              >
                {lng.itm("M_INP_UPD")}
              </Button>
            </DialogContent>
          </form>
        </Dialog>
      </>
    );
  };

  const diagnosisEdit = (history, path, row) => {

    props.row.index = rowIndex;
    row.isEdit = true;
    return (
      <>
        <IconButton
          aria-label="edit"
          size="small"
          onClick={handleClickOpenDiagnosis("body")}
        >
          <EditIcon size="small" />
        </IconButton>
        <Dialog
          open={openDiagnosis}
          onClose={handleCloseDiagnosis}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          classes={{
            root: classes.root,
          }}
        >
          <DialogContent dividers={scroll === "paper"}>
            <DiagnosisInformation
              handleClose={handleCloseDiagnosis}
              record={props.row}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  };
  const orderEdit = (history, path, row) => {
    props.row.index = rowIndex;
    var editable = props.editable;
    return (
      <>
        <IconButton
          aria-label="edit"
          size="small"
          onClick={handleClickOpenDiagnosis("body")}
        >
          {editable && (<EditIcon size="small" />)}
          {!editable && (<VisibilityIcon size="small" />)}

        </IconButton>
        <Dialog
          open={openDiagnosis}
          onClose={handleCloseDiagnosis}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          classes={{
            root: classes.rootOrder,
          }}
        >
          <DialogContent dividers={scroll === "paper"}>
            <OrderEdit handleClose={handleCloseDiagnosis} record={props.row} editable={editable} />
          </DialogContent>
        </Dialog>
      </>
    );
  };
  const patientEditPopup = (classes) => {


    //alert(JSON.stringify(row))


    return (
      <>
        <IconButton aria-label="edit" size="small" onClick={handleModalOpen}>
          <EditIcon size="small" />
        </IconButton>

        <Dialog
          fullWidth
          maxWidth="xs"
          onClose={handleModalClose}
          open={open}
          style={{ paddingBottom: "15%" }}
        >
          <DialogTitle
            className={classes.dialogTitle}
            onClose={handleModalClose}
          >
            <h3>{lng.itm("M_HDR_PAT_UPD")}</h3>
            <IconButton
              className={classes.closeButton}
              onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={formikPatient.handleSubmit}>
            <DialogContent fullWidth dividers style={{ overflow: "hidden" }}>
              <PatientEditForm
                record={row}
                onChange={formikPatient.handleChange}
                handleActChange={handleActChange}
                setFormikField={formikPatient.setFieldValue}
              />
            </DialogContent>
            <DialogContent>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                size="small"
              >
                {lng.itm("M_INP_UPD")}
              </Button>
            </DialogContent>
          </form>
        </Dialog>
      </>
    );
  };

  return parseTablePath();
}

export default withStyles(makeStyles, { withTheme: true })(EditButton);
