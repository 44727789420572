import React, { useState, useEffect } from "react";
import HeaderUser from "./components/header/HeaderUser";
import HeaderAdmin from "./components/header/HeaderAdmin";
import HeaderGuest from "./components/header/HeaderGuest";
import { lng } from "./Tore/Sys";
import languages from "./i18n/language";
import Login from "./components/user/Login";
import PatientRegister from "./components/patient/PatientRegister";
import SignUp from "./components/user/SignUp";
import CompanyRegister from "./components/company/CompanyRegister";
import { Grid } from "@material-ui/core";
import { Switch, Route } from "react-router-dom";
import Company from "./pages/Companies";
import Glb from "./global/Glb";
import PrivateAdminRoute from "./route/PrivateAdminRoute";
import PrivateUserRoute from "./route/PrivateUserRoute";
import PrivateGuestRoute from "./route/PrivateGuestRoute";
import Users from "./pages/Users";
import Patients from "./pages/Patients";
import Product from "./pages/Products";
import ProductRegister from "./components/product/ProductRegister";
import DiagnosisRegister from "./components/diagnosis/DiagnosisRegister";
import Diagnosis from "./pages/Diagnosis";
import ProductCompanyRelations from "./components/product/ProductCompanyRelations";
import DiagnosisInformation from "./components/diagnosis/DiagnosisInformation";
import Orders from "./pages/Orders";
import OrderRegister from "./components/order/OrderRegister";
import OrderEdit from "./components/order/OrderEdit";
import "./App.css";
import ProductCompanyRegister from "./components/product/ProductCompanyRegister";
import { useHistory } from "react-router-dom";
import Logout from "./components/user/Logout";

function App() {
  const [usr, setUsr] = useState({});
  const [currentLng, setCurrentLng] = useState("TR");
  const history = useHistory();

  lng.languages = languages;
  lng.current = currentLng;

  let isloggedIn = Glb.Storage.usr.isloggedIn;
  let isAdmin = Glb.Storage.usr.adm;

  function handleLanguageChange (newValue) {
    if (newValue == "EN") {
        localStorage.setItem("selectedLng", "EN");
        setCurrentLng("EN");
        //props.setCurrentLanguages(languages);
    } else {
        localStorage.setItem("selectedLng", "TR");
        setCurrentLng("TR");

    }
    
};

  let header = <div />;
  if (isloggedIn && isAdmin) header = <HeaderAdmin handleLanguageChange={handleLanguageChange} currentLng={currentLng} />;
  else if (isloggedIn && !isAdmin) header = <HeaderUser handleLanguageChange={handleLanguageChange} currentLng={currentLng} />;
  else if (!isloggedIn) header = <HeaderGuest handleLanguageChange={handleLanguageChange} currentLng={currentLng} />;

  const handleLogin = (usr) => {
    Glb.Storage.usr = usr;
    Glb.Storage.usr.isloggedIn = true;
    setUsr(usr);
  };

  const handleLogout = () => {
    Glb.Storage.usr.isloggedIn = false;
    setUsr({});
    Glb.Storage.usrLst = [];
    Glb.Storage.prdLst = [];
    Glb.Storage.comLst = [];
    Glb.Storage.patLst = [];
    Glb.Storage.diaLst = [];
    Glb.Storage.ordLst = [];
    history.push("/login");
    localStorage.clear();
  };

  useEffect(() => {
    handleRefresh();
  });

  const handleRefresh = () => {

    let selectedLng = localStorage.getItem("selectedLng");
    if (selectedLng) {
      setCurrentLng(selectedLng);
    }

    let response = localStorage.getItem("response");
    let resObj = JSON.parse(response);

    let allComsStr = localStorage.getItem("allComs");
    var allComs;
    if (allComsStr) {
      allComs = JSON.parse(allComsStr)
      Glb.Storage.allComs = allComs;
    }

    let allUsrsStr = localStorage.getItem("allUsrs");
    var allUsrs;
    if (allUsrsStr) {
      allUsrs = JSON.parse(allUsrsStr)
      Glb.Storage.allUsrs = allUsrs;
    }

    if (!Glb.Storage.usr.isloggedIn) {
      if (resObj?.sta === "ACK") {
        Glb.Storage.usr.isloggedIn = true;
        handleLogin(resObj.dta);
      }
      // Glb.Storage.usr.isloggedIn = true;
      //
    }
  };

  return (
    <div className="App">
      <Grid container direction="column" spacing={3}>
        <Grid item> {header} </Grid>
        <Grid container direction="column">
          <Switch>
            {!isloggedIn ? (
              <Route
                exact
                path="/"
                children={
                  <Grid>
                    {" "}
                    <Login handleLogin={handleLogin} />{" "}
                  </Grid>
                }
              />
            ) : (
              <Route exact path="/" children={<Grid> </Grid>} />
            )}
            <PrivateGuestRoute
              exact
              path="/login"
              children={
                <Grid>
                  {" "}
                  <Login handleLogin={handleLogin} />{" "}
                </Grid>
              }
            />
            <PrivateUserRoute
              exact
              path="/logout"
              children={
                <Grid>
                  {" "}
                  <Logout handleLogout={handleLogout} />{" "}
                </Grid>
              }
            />
            <PrivateAdminRoute
              exact
              path="/diagnosis"
              children={
                <Grid>
                  {" "}
                  <Diagnosis />{" "}
                </Grid>
              }
            />
            <PrivateUserRoute
              exact
              path="/diagnosisRegister"
              children={
                <Grid>
                  {" "}
                  <DiagnosisRegister />{" "}
                </Grid>
              }
            />
            <PrivateUserRoute
              exact
              path="/diagnosisInformation/:id"
              children={
                <Grid>
                  {" "}
                  <DiagnosisInformation />{" "}
                </Grid>
              }
            />
            <PrivateUserRoute
              exact
              path="/orders"
              children={
                <Grid>
                  {" "}
                  <Orders />{" "}
                </Grid>
              }
            />
            <PrivateUserRoute
              exact
              path="/insoleRegister"
              children={
                <Grid>
                  {" "}
                  <OrderRegister type="Insole" />{" "}
                </Grid>
              }
            />
            <PrivateUserRoute
              exact
              path="/slipperRegister"
              children={
                <Grid>
                  {" "}
                  <OrderRegister type="Slipper" />{" "}
                </Grid>
              }
            />
            <PrivateUserRoute
              exact
              path="/orderEdit"
              children={
                <Grid>
                  {" "}
                  <OrderEdit type="Insole" />{" "}
                </Grid>
              }
            />
            <PrivateUserRoute
              exact
              path="/orderEdit"
              children={
                <Grid>
                  {" "}
                  <OrderEdit type="Slipper" />{" "}
                </Grid>
              }
            />
            <PrivateUserRoute
              exact
              path="/repairRegister"
              children={
                <Grid>
                  {" "}
                  <OrderRegister type="Repair" />{" "}
                </Grid>
              }
            />
            <PrivateUserRoute
              exact
              path="/patients"
              children={
                <Grid>
                  {" "}
                  <Patients />{" "}
                </Grid>
              }
            />
            <PrivateUserRoute
              exact
              path="/patientRegister"
              children={
                <Grid>
                  {" "}
                  <PatientRegister />{" "}
                </Grid>
              }
            />
            {/* <PrivateUserRoute exact path="/patientEdit/:id" children={
              <Grid> <PatientEdit /> </Grid>
            } /> */}
            <PrivateUserRoute
              exact
              path="/undefined/:id"
              children={
                <Grid>
                  {" "}
                  <h1> HENÜZ HAZIR DEĞİL </h1>{" "}
                </Grid>
              }
            />
            <PrivateAdminRoute
              exact
              path="/users"
              children={
                <Grid>
                  {" "}
                  <Users />{" "}
                </Grid>
              }
            />
            <PrivateAdminRoute
              exact
              path="/userRegister"
              children={
                <Grid>
                  {" "}
                  <SignUp />{" "}
                </Grid>
              }
            />
            {/* <PrivateAdminRoute exact path="/userEdit/:id" children={
              <Grid> <UserEdit /> </Grid>
            } /> */}
            <PrivateAdminRoute
              exact
              path="/companies"
              children={
                <Grid>
                  {" "}
                  <Company />{" "}
                </Grid>
              }
            />
            {/* <PrivateAdminRoute exact path="/companyEdit/:id" children={
              <Grid> <CompanyEdit /> </Grid>
            } /> */}
            <PrivateAdminRoute
              exact
              path="/companyRegister"
              children={
                <Grid>
                  {" "}
                  <CompanyRegister />{" "}
                </Grid>
              }
            />
            <PrivateAdminRoute
              exact
              path="/products"
              children={
                <Grid>
                  <Product />{" "}
                </Grid>
              }
            />
            <PrivateAdminRoute
              exact
              path="/productRegister"
              children={
                <Grid>
                  <ProductRegister />{" "}
                </Grid>
              }
            />
            {/* <PrivateAdminRoute exact path="/productEdit/:id" children={
              <Grid><ProductEdit />  </Grid>
            } /> */}
            <PrivateAdminRoute
              exact
              path="/productCompanyRelation"
              children={
                <Grid>
                  <ProductCompanyRelations />{" "}
                </Grid>
              }
            />
            <PrivateAdminRoute
              exact
              path="/productCompanyRegister"
              children={
                <Grid>
                  <ProductCompanyRegister />{" "}
                </Grid>
              }
            />
          </Switch>
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
