import { Grid, IconButton, makeStyles, TextField } from "@material-ui/core";
import React from "react";
import SearchSelect from "../SearchSelect";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DiagnosisRegister from "../diagnosis/DiagnosisRegister";
import Glb from "../../global/Glb";
import PatientRegister from "../patient/PatientRegister";
import HttpPost from "../HttpPost";
import { lng } from "../../Tore/Lng";
import DatePicker from '../DatePicker';
const useStyles = makeStyles((theme) => ({
  root: (props) => {
    return {
      "& .MuiDialog-container > div": {
        width: "1200px",
        height: "800px",
        maxWidth: "1200px",
        "& .css-m1b0vd-MuiDialogTitle-root": {
          padding: "16px 24px 10px",
        },
        "& .css-1y6f8wu-MuiDialogContent-root": {
          "& .css-wffnfh": {
            padding: "24px",
          },
        },
      },
    };
  },
}));

export default function OrderInputs({ cid, setCid, isRepair, typ }) {
  var sm = 6;
  var adm = Glb.Storage.usr.adm;
  const [open, setOpen] = React.useState(false);
  const [openPatient, setOpenPatient] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [pat, setPat] = React.useState("");
  const [com, setCom] = React.useState("");
  const [usr, setUsr] = React.useState("");
  // const [cid, setCid] = React.useState("");
  const [uid, setUid] = React.useState("");
  const [pid, setPid] = React.useState("");
  const [dia, setDia] = React.useState("");
  const [did, setDid] = React.useState("");
  const [diaOptions, setDiaOptions] = React.useState()

  const handleDateChange = (formattedDate) => {
    Glb.Storage.ord.odt = formattedDate;
    Glb.Errors.ord["odt"] = false;
  };

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenPatient = (scrollType) => () => {
    setOpenPatient(true);
    setScroll(scrollType);
  };

  const handleClosePatient = () => {
    setOpenPatient(false);
  };

  const handleChangeOrderNote = (e) => {
    var id = e.currentTarget.id;
    var value = e.currentTarget.value;
    Glb.Storage.ord[id] = value;
  };

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    //alert("useEfect order inputs")
    const isAdmin = Glb.Storage.usr.adm;
    if (!isAdmin) {
      Glb.Errors.ord["cid"] = false;
      Glb.Errors.ord["uid"] = false;
      setCid(Glb.Storage.usr.cid);
      setUid(Glb.Storage.usr.uid);
    }
    if (pid !== "") {
      Glb.Storage.ord["pid"] = pid;
      Glb.Errors.ord["pid"] = false;
    }
    if (pat !== "")
      Glb.Storage.ord["pnm"] = pat.nam + " " + pat.sur;
    // if (did !== "") {
    //   Glb.Storage.ord["did"] = did;
    //   Glb.Errors.ord["did"] = false;
    // }
    if (dia) {
      Glb.Storage.ord["did"] = dia.did;
      Glb.Errors.ord["did"] = false;
      //alert(Glb.Errors.ord["did"])
    }
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }

    async function fetchDiagnosisByPid(pid) {
      var data = {
        dta: {
          pid: pid
        },
        lim: 100,
        skp: 0
      }
      var res = await HttpPost("/diaLst", data);
      if (res?.sta === "ACK") {
        setDiaOptions(res.dta);
      }
    }
    // alert(JSON.stringify(pat))
    if (pat !== "") {
      fetchDiagnosisByPid(pat.pid);
    }
  }, [open, pat, pid, dia, did]);
  const classes = useStyles();
  return (
    <Grid container item spacing={2}>
      <Grid container item direction="column" sm={sm} spacing={1}>
        {adm && (
          <Grid item>
            <SearchSelect setObj={setCom} setDefObj={setPat} setId={setCid} options={Glb.Storage.allComs} lngKey="M_INP_COM" use="form" obj="ord" />
          </Grid>
        )}
        <Grid item container>
          {" "}
          <Grid item sm={11}>
            <SearchSelect
              defObj={pat}
              setObj={setPat}
              id={uid}
              lngKey="M_INP_PAT"
              use="form"
              obj="ord"
              setId={setPid}
              objProp="cid"
              filterValueForObjProp={cid} />
          </Grid>
          <Grid item sm={1}>
            {" "}
            <IconButton disabled={uid === ""} onClick={handleClickOpenPatient("body")}>
              <AddCircleOutlineIcon />
            </IconButton>
            <Dialog
              open={openPatient}
              onClose={handleClosePatient}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            // classes={{
            //   root: classes.root,
            // }}
            >
              <DialogContent dividers={scroll === "paper"}>
                <PatientRegister setPid={setPid} com={com} usr={usr} setPat={setPat} handleClose={handleClosePatient} />
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>

      </Grid>
      <Grid container item direction="column" sm={sm} spacing={1}>
        {adm && (
          <Grid item>
            <SearchSelect
              setObj={setUsr}
              setDefObj={setPat}
              id={cid}
              setId={setUid}
              options={Glb.Storage.allUsrs}
              lngKey="M_INP_USR"
              use="form"
              obj="ord"
              objProp="cid" />
          </Grid>
        )}
        {!isRepair &&
          (<Grid container item>
            <Grid item sm={9}>
              <SearchSelect options={diaOptions} defObj={dia} setObj={setDia} setId={setDid} lngKey="M_INP_DIA" use="form" obj="ord" id={pid} />
              {/* <TextField disabled InputLabelProps={{ shrink: true }} label={lng.itm("M_INP_DIA").label} value={dia?.ddm || lng.itm("M_INP_DIA").warning} /> */}
            </Grid>
            <Grid item sm={2}>
              {" "}
              <IconButton disabled={pid === ""} onClick={handleClickOpen("body")}>
                <AddCircleOutlineIcon />
              </IconButton>
              <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                classes={{
                  root: classes.root,
                }}
              >
                <DialogContent dividers={scroll === "paper"}>
                  <DiagnosisRegister pat={pat} usr={usr} com={com} setDia={setDia} handleClose={handleClose} typ={typ} />
                </DialogContent>
              </Dialog>
            </Grid>
          </Grid>)}

      </Grid>
      <Grid container item>
        <Grid item sm={6} >
          {/* <SearchSelect options={diaOptions} defObj={dia} setObj={setDia} setId={setDid} lngKey="M_INP_DIA" use="form" obj="ord" id={pid} /> */}
          <TextField label={lng.itm("M_INP_ONT")?.label} id={lng.itm("M_INP_ONT")?.id} onChange={handleChangeOrderNote} />
        </Grid>
        <Grid item sm={5}>
          <DatePicker handleChange={handleDateChange} defaultDate={new Date()} lngKey={"M_INP_ODT"} />
        </Grid>
      </Grid>
    </Grid>
  );
}
