
// byte arrayleri xor işlemi yapıyor. 
export function cryptByteArrays(encDec, xorKey) {
    let idx = 0;
    let eln = 0;
    let kln = 0;
    var res;
    eln = encDec.length;
    kln = xorKey.length;
    res = new Uint8Array(eln);

    // if (kln === 0) {
    //     //console.log("sıfır hatası"); 
    // }
    for (idx = 0; idx < eln; idx++)
        res[idx] = (encDec[idx] ^ xorKey[idx % kln]);
    return res;
}

export function cryptStrings(encStr, xorStr) {
    var e = new TextEncoder();

    return cryptByteArrays(e.encode(encStr), e.encode(xorStr));
}



export function byteArrayToHexString(a) {
    return Buffer.from(a).toString('hex').toUpperCase();
}


export function hexStringToByteArray(hexString) {
    var l = hexString.length,
        r = [];
    for (var i = 0; i < l; i += 2)
        r.push(parseInt(hexString.substr(i, 2), 16));
    return r;
}

export function the_Test(cx) {

    var x = new Crypt(cx);

    //alert(JSON.stringify(x))

    console.log("Key : " + byteArrayToHexString(x.key));
    console.log("Encoding password acüüpgizli");
    console.log("Encoding email    volkan@fenomen.org");
    x.encode("volkan@fenomen.org", "acüüpgizli")
    //cx.pwd = x.pwd;
    //cx.ema = x.ema;
    console.log("Encoded password " + x.pwd);
    console.log("Encoded email    " + x.ema);
    x.decode(x.ema, x.pwd)
    //cx.pwd = x.pwd;
    //cx.ema = x.ema;
    console.log("Decoded password " + x.pwd);
    console.log("Decoded email    " + x.ema);
}

export class Crypt {

    constructor(cx) {
        this.hdr = cx;
        this.ema = null;
        this.pwd = null;
        this.key = null;
        this.calcKey()

    }

    def(x) {
        return ((typeof (x) !== typeof (undefined)) && (x !== null));
    }

    calcKey() {
        var t = this,
            xrid = t.hdr.rid.replace(/-/g, "").toUpperCase(),
            xsrc = t.hdr.src.replace(/-/g, "").toUpperCase(),
            bar1 = cryptStrings(xrid, xsrc),
            bar2 = cryptStrings(t.hdr.tim, t.hdr.tar);
        t.key = cryptByteArrays(bar1, bar2)
    }

    encode(xema, xpwd) {
        var t = this,
            e = new TextEncoder(),
            b;

        if (t.def(xema)) {
            b = cryptByteArrays(e.encode(xema), t.key);
            t.ema = byteArrayToHexString(b);
        }
        if (t.def(xpwd)) {
            b = cryptByteArrays(e.encode(xpwd), t.key);
            t.pwd = byteArrayToHexString(b);
        }
    }

    decode(xema, xpwd) {
        var t = this,
            d = new TextDecoder(),
            b;

        if (t.def(xema)) {
            b = cryptByteArrays(hexStringToByteArray(xema), t.key);
            t.ema = d.decode(b);
        }
        if (t.def(xpwd)) {
            b = cryptByteArrays(hexStringToByteArray(xpwd), t.key);
            t.pwd = d.decode(b);
        }
    }


}
  // 2132302A34352F6E16333824313A7A3F393B bunu vermesi gerekiyor ugurbas8@gmail.com