
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HttpPost from '../components/HttpPost';
import Glb from '../global/Glb'
import SearchTable from '../components/table/SearchTable';
import DataTable from '../components/table/DataTable';
import { Grid } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    formCenter: {
        alignItems: "center",
        justify: "center",
        height: 300,
        width: "%100",
        margin: "dense",

    },
    table: {
        fontSize: "10px"
    },
    button: {
        margin: theme.spacing(1),
        justifyContent: "center"
    },
}));


export default function Orders() {
    const [rows, setRows] = useState([])
    const [columnsTag, setColumnsTag] = useState("M_TBL_ORD_HDR")
    const [reRender, setReRender] = useState({})
    const classes = useStyles();
    const searchInputs = ["M_INP_COM", "M_INP_USR", "M_INP_PAT", "M_INP_OET", "M_INP_DIA", "M_INP_TYP", "M_INP_ODS", "M_INP_OOK"]
    const isAdmin = Glb.Storage.usr.adm;

    useEffect(() => {
        async function getList() {
            var res = await HttpPost("/ordLst", Glb.Filter);

            if (res?.sta === "ACK") {
                if (res?.dta?.length > 0) {
                    setRows(res.dta);
                    //Glb.Reload.ord = false;
                }
            }
        }

        Glb.Filter.dta = { ook: -1, ods: false }
        Glb.Filter.skp = 0;
        Glb.ListFiltered.com = false;
        Glb.ListFiltered.usr = false;
        Glb.ListFiltered.pat = false;
        Glb.ListFiltered.dia = false;
        Glb.ListFiltered.prd = false;
        if (isAdmin)
            setColumnsTag("M_TBL_ORD_HDR_ADM");
        if (!Glb.Reload.ord)
            setRows(Glb.Storage.ordLst)
        else {
            getList();
        }

        return () => {
            Glb.Filter.dta = {}
            Glb.Filter.skp = 0;
        }
    }, [])

    if (!isAdmin) {
        const index = searchInputs.indexOf("M_INP_COM");
        if (index > -1) {
            searchInputs.splice(index, 1);
        }
    }

    return (
        <Grid container>
            <Grid container>
                <Grid item xm={"false"} xs={0} sm={1} />
                <Grid container className={classes.formCenter} xs={12} sm={10}>
                    <SearchTable optionsLngKey="M_BTN_ADD_MULTI" setRows={setRows} searchInputs={searchInputs} registerPath="/orderRegister" searchPath="/ordLst" />
                    <DataTable page={Glb.Page.Order} rows={rows} columnsTag={columnsTag} diaPath="/diagnosisInformation" editPath="/orderEdit" editable={true} registeredObj="ord" setReRender={setReRender} keyExtractor="oid" erasable={true} setRows={setRows} />
                </Grid>
            </Grid>
            <Grid item xm={"false"} xs={0} sm={1} />
        </Grid>

    );
}