import React, { useState, useEffect } from 'react';
import { lng } from '../../Tore/Sys';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import PhoneInput from '../PhoneInput'

const textFieldProps = {
    variant: "outlined",
    margin: "dense",
    size: "small"
}


export default function PatientEditForm(props) {

    const { record: row, onChange, handleActChange, setFormikField } = props;

    const [nam, setNam] = useState("");
    const [sur, setSur] = useState("");
    const [pno, setPno] = useState("");
    const [ema, setEma] = useState("");

    useEffect(() => {
        //alert(JSON.stringify(row))
        if (row?.nam)
            setNam(row.nam)
        if (row?.sur)
            setSur(row.sur)
        if (row?.pno)
            setPno(row.pno)
        if (row?.ema)
            setEma(row.ema)
        return () => {

        }
    }, [row])

    const handleChange = (e) => {
        e = handleActChange(e)
        if (e)
            onChange(e)
    }

    const handleTextChange = (e) => {
        var value = e.target.value;
        var id = e.target.id;
        if (id) {
            switch (id) {
                case "nam":
                    setNam(value)
                    break;
                case "sur":
                    setSur(value)
                    break;
                case "pno":
                    setPno(value)
                    break;
                case "ema":
                    setEma(value)
                    break;
                default:
                    break;
            }
        }
        if (e)
            onChange(e)
    }

    return (
        <Grid container direction="column">
            <TextField id={lng.itm("M_INP_NAM").id} label={lng.itm("M_INP_NAM").label} {...textFieldProps} value={nam || ""} onChange={handleTextChange} />
            <TextField id={lng.itm("M_INP_SUR").id} label={lng.itm("M_INP_SUR").label} {...textFieldProps} value={sur || ""} onChange={handleTextChange} />
            <PhoneInput variant="outlined" setFormikField={setFormikField} handleChange={onChange} phone={pno} />
            <TextField id={lng.itm("M_INP_EMA").id} label={lng.itm("M_INP_EMA").label} {...textFieldProps} value={ema || ""} onChange={handleTextChange} />
            <FormControl>
                <InputLabel id={lng.itm("M_INP_ACT").id} >{lng.itm("M_INP_ACT").label} </InputLabel>
                <Select
                    labelId={lng.itm("M_INP_ACT").id}
                    id={lng.itm("M_INP_ACT").id}
                    onChange={handleChange}
                    variant="outlined"
                    style={{ height: "40px" }}
                >
                    <MenuItem value={true}>{lng.itm("M_LBL_ACT")}</MenuItem>
                    <MenuItem value={false}>{lng.itm("M_LBL_INACT")}</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    );
}
