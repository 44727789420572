import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { lng } from '../Tore/Sys'
import Glb from '../global/Glb';

export default function CustomSelect(props) {

    const { lngKey, obj } = props;
    const [value, setValue] = useState(props.value);
    const selectOptions = lng.itm(lngKey);

    const handleChange = (e) => {
        var id = e.currentTarget.id;
        var v = e.target.value;
        //alert(JSON.stringify(Glb.Errors.ord))
        if (id && obj) {
            if (v || v === 0) {
                Glb.Storage[obj][id] = v;
                Glb.Errors.Insole[id] = false;
                Glb.Errors.Slipper[id] = false;
                setValue(v);
            }
        }
    }

    return (
        <FormControl fullWidth>
            <InputLabel style={{ marginLeft: "5px" }} > {selectOptions.label} </InputLabel>
            <Select id={selectOptions.id}
                onChange={handleChange}
                variant="outlined"
                value={value}
            >
                <MenuItem value={selectOptions.label} disabled>{selectOptions.label} </MenuItem>
                {selectOptions.items.map((selectOption) => (
                    <MenuItem id={selectOptions.id} value={selectOption.value}>{selectOption.text}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}