"using strict"
/*————————————————————————————————————————————————————————————————————————————
    —————————————————————————————————————————————————————
    | Lng : Internationalization subsystem for React JS |
    —————————————————————————————————————————————————————

© Copyright 2021 İhsan Volkan Töre.

Author              : IVT.  (İhsan Volkan Töre)
Version             : 202103151356

History             :
202103151356: IVT   : First Draft.
————————————————————————————————————————————————————————————————————————————*/
import {def, chk} from "./Sys.js";



/*————————————————————————————————————————————————————————————————————————————
    ——————————————————————————————————
    | Internationalization subsystem |
    ——————————————————————————————————

    Importing this from Sys.js is recommended
————————————————————————————————————————————————————————————————————————————*/

/*————————————————————————————————————————————————————————————————————————————
  CLASS : Lng.
  USAGE : Simple solution for i18n.

  NOTES : Lng class is not exported.
          Constant lng is a singleton instance.
————————————————————————————————————————————————————————————————————————————*/
class Lng {

constructor() {
var t = this;
    t._lst = null;
    t._lng = null;
    t._cur = "NONE";
}

/*————————————————————————————————————————————————————————————————————————————
  FUNC: itm
  TASK: Brings an item from current language.
  ARGS: tag : String    : Item tag.
  RETV:     : Object    : Object if exists or null. 
————————————————————————————————————————————————————————————————————————————*/
itm(tag) {
var t = this,
    r;

    chk(tag,    "tag");
    chk(t._cur, "lng.current", "E_UNASSIGNED");
    r = t._lng[tag];
    return (def(r)) ? r : tag;
}

/*————————————————————————————————————————————————————————————————————————————
  FUNC: fetch
  TASK: Brings an item from a language.
  ARGS: lngName : String    : Name of the language to search.
        tag     : String    : Item tag.
  RETV:         : Object    : Object if exists or null. 
————————————————————————————————————————————————————————————————————————————*/
fetch(lngName, tag) {
var t = this,
    l,
    r;

    chk(tag,        "tag");
    chk(lngName,    "lngName");
    l = t._lst[lngName];
    if (!def(l))
        return tag;
    r = l[tag];
    return (def(r)) ? r : tag;
}

/*————————————————————————————————————————————————————————————————————————————
  FUNC: add
  TASK: Adds a language object to languages.
  ARGS: lngName     : String    : Name of language ("tr","en","de" etc).
        lngObject   : Object    : Object containing language items.
————————————————————————————————————————————————————————————————————————————*/
add(lngName, lngObject) {
    chk(lngName, "lngName");
    chk(lngObject, "lngObject");
    this._lst[lngName] = lngObject;
}

/*————————————————————————————————————————————————————————————————————————————
  FUNC: del
  TASK: Deletes a language object from languages.
  ARGS: lngName     : String    : Name of language ("tr","en","de" etc).
————————————————————————————————————————————————————————————————————————————*/
del(lngName){
    delete(this._lst[lngName]);
}

/*————————————————————————————————————————————————————————————————————————————
  PROP: current
  GET : Returns current language name.
  SET : Sets current language with name
  ARGS: lngName     : String    : Name of language ("tr","en","de" etc).
————————————————————————————————————————————————————————————————————————————*/
get current() { return this._cur; }
set current(lngName) {
var t = this,
    l = lngName,
    r;

    chk(l,"lngName");
    r = t._lst[l];
    chk(r, l, "E_INV_LNG");
    t._cur = l;
    t._lng = r;
}

/*————————————————————————————————————————————————————————————————————————————
  PROP: languages
  GET : Gets the object containing all language objects.
  SET : Sets the object containing all language objects.
  ARGS: lngData : Object    : Object containing language objects.
————————————————————————————————————————————————————————————————————————————*/
get languages(){ return this._lst; }
set languages(lngData) {
var l = lngData;

    chk(l,"lngData");
    this._lst = lngData;
}

}   // End class Lng.

// This is exported only as a singleton...
export const lng = new Lng();