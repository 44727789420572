import { Grid } from "@material-ui/core";
import React from "react";
import Glb from "../../global/Glb";
import { lng } from "../../Tore/Lng";
import DatePicker from "../DatePicker";

export default function RepairInputs() {

    const handleDateChange = (formattedDate) => {
        Glb.Storage.ord.odt = formattedDate;
        Glb.Errors.ord["odt"] = false;
    };

    return (
        <>
            <Grid container item direction="column" sm={6} spacing={1}>


            </Grid>
            <Grid container item direction="column" sm={6} spacing={1}>

            </Grid>
            <Grid container item direction="column" sm={6} spacing={1}>
                {/* <Grid item>
                    <DatePicker handleChange={handleDateChange} />
                </Grid> */}
            </Grid>
            <Grid container item direction="column" sm={6} spacing={1}>

            </Grid>
        </>
    );
}
