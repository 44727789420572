import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { lng } from "../../Tore/Sys";
import { Grid, TextField, Typography } from "@material-ui/core";
import Glb from "../../global/Glb";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    // padding: theme.spacing(2),
    height: "100%",
    //color: "theme.palette.text.secondary",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export default function WedgesLeft(props) {
  const classes = useStyles();

  const { heelMedLbl, heelLatLbl, frntMedLbl, frntLatLbl, fullMedLbl, fullLatLbl } =
    lng.itm("M_INP_WDG");

  const [wedgeLeftHeelMedial, setHeelMed] = useState();
  const [wedgeLeftHeelLateral, setHeelLat] = useState();
  const [wedgeLeftFrontMedial, setFrntMed] = useState();
  const [wedgeLeftFrontLateral, setFrntLat] = useState();
  const [wedgeLeftFullMedial, setFullMed] = useState();
  const [wedgeLeftFullLateral, setFullLat] = useState();

  const isEdit = props?.isEdit;
  const isRegister = props?.isRegister;

  const isInfo = (props?.row != undefined) && (!isEdit && !isRegister);
  const [fillValue, setFillValue] = useState(false);

  useEffect(() => {

    if ((props?.row != undefined) && (isInfo || isEdit || isRegister))
      setFillValue(true)

    if (props.row) {
      var r = props.row;
      Glb.Storage.dia.wedgeLeftHeelMedial = parseInt(r.wedgeLeftHeelMedial);
      setHeelMed(r.wedgeLeftHeelMedial);
      Glb.Storage.dia.wedgeLeftHeelLateral = parseInt(r.wedgeLeftHeelLateral);
      setHeelLat(r.wedgeLeftHeelLateral);
      Glb.Storage.dia.wedgeLeftFrontMedial = parseInt(r.wedgeLeftFrontMedial);
      setFrntMed(r.wedgeLeftFrontMedial);
      Glb.Storage.dia.wedgeLeftFrontLateral = parseInt(r.wedgeLeftFrontLateral);
      setFrntLat(r.wedgeLeftFrontLateral);
      Glb.Storage.dia.wedgeLeftFullMedial = parseInt(r.wedgeLeftFullMedial);
      setFullMed(r.wedgeLeftFullMedial);
      Glb.Storage.dia.wedgeLeftFullLateral = parseInt(r.wedgeLeftFullLateral);
      setFullLat(r.wedgeLeftFullLateral);

    }
    return () => {

    }
  }, [props?.row])

  function handleChange(e) {
    let val = e.currentTarget.value;
    let id = e.currentTarget.id;
    if (id) {
      if (!val || val.length === 0) val = 0;
      if (val > 15) {
        val = 15;
      } else if (val < 0) val = 0;
      e.currentTarget.value = val;
      Glb.Storage.dia[id] = val;

      switch (id) {
        case "wedgeLeftHeelMedial":
          setHeelMed(val)
          break;
        case "wedgeLeftHeelLateral":
          setHeelLat(val)
          break;
        case "wedgeLeftFrontMedial":
          setFrntMed(val)
          break;
        case "wedgeLeftFrontLateral":
          setFrntLat(val)
          break;
        case "wedgeLeftFullMedial":
          setFullMed(val)
          break;
        case "wedgeLeftFullLateral":
          setFullLat(val)
          break;
        default:
          break;
      }
    }
  }

  const inputProps = { 
    style: { 
        textAlign: 'center', 
        fontSize: "18px" 
    } 
  };

  const inputLabelProps = {
      style: {
          fontSize: "17px",
          color: "black"
      }
  };

  return (
    <div className={classes.root}>
      <Grid container direction="column">
        <Grid item>
          {/* <Paper className={classes.paper}> */}
          <Typography style={{ paddingBottom: "20px" }}>
            <strong>{props.label}</strong>
          </Typography>
          {/* </Paper> */}
        </Grid>
        <Grid container direction="column">
          <Grid>
            {/* <Paper className={classes.paper}> */}

            <TextField
              id="wedgeLeftHeelMedial"
              label={heelMedLbl}
              //style={{ width: "85%", height: "60%" }}
              inputProps={inputProps}
              InputLabelProps={inputLabelProps}
              type="number"
              onChange={handleChange}
              value={
                wedgeLeftHeelMedial ? wedgeLeftHeelMedial : 0
              }
              InputProps={{
                readOnly: isInfo
              }}
            />
            {/* </Paper> */}
          </Grid>
          <Grid>
            {/* <Paper className={classes.paper}> */}
            <TextField
              id="wedgeLeftHeelLateral"
              label={heelLatLbl}
              //style={{ width: "85%", height: "60%" }}
              inputProps={inputProps}
              InputLabelProps={inputLabelProps}
              type="number"
              onChange={handleChange}
              value={
                wedgeLeftHeelLateral ? wedgeLeftHeelLateral : 0
              }
              InputProps={{
                readOnly: isInfo
              }}
            />
            {/* </Paper> */}
          </Grid>
          <Grid>
            {/* <Paper className={classes.paper}> */}
            <TextField
              id="wedgeLeftFrontMedial"
              label={frntMedLbl}
              //placeholder={wedgeLeftFrontMedial}
              //style={{ width: "85%", height: "60%" }}
              inputProps={inputProps}
              InputLabelProps={inputLabelProps}
              type="number"
              onChange={handleChange}
              value={
                wedgeLeftFrontMedial ? wedgeLeftFrontMedial : 0
              }
              InputProps={{
                readOnly: isInfo
              }}
            />
            {/* </Paper> */}
          </Grid>
          <Grid>
            {/* <Paper className={classes.paper}> */}
            <TextField
              id="wedgeLeftFrontLateral"
              //placeholder={wedgeLeftFrontLateral}
              label={frntLatLbl}
              //style={{ width: "85%", height: "60%" }}
              inputProps={inputProps}
              InputLabelProps={inputLabelProps}
              type="number"
              onChange={handleChange}
              value={
                wedgeLeftFrontLateral ? wedgeLeftFrontLateral : 0
              }
              InputProps={{
                readOnly: isInfo
              }}
            />
            {/* </Paper> */}
          </Grid>
          <Grid>
            {/* <Paper className={classes.paper}> */}
            <TextField
              id="wedgeLeftFullMedial"
              label={fullMedLbl}
              //placeholder={wedgeLeftFullMedial}
              //style={{ width: "85%", height: "60%" }}
              inputProps={inputProps}
              InputLabelProps={inputLabelProps}
              type="number"
              onChange={handleChange}
              value={
                wedgeLeftFullMedial ? wedgeLeftFullMedial : 0
              }
              InputProps={{
                readOnly: isInfo
              }}
            />
            {/* </Paper> */}
          </Grid>
          <Grid>
            {/* <Paper className={classes.paper}> */}
            <TextField
              id="wedgeLeftFullLateral"
              label={fullLatLbl}
              //placeholder={wedgeLeftFullLateral }
              style={{ paddingBottom: "10px" }}
              inputProps={inputProps}
              InputLabelProps={inputLabelProps}
              type="number"
              onChange={handleChange}
              value={
                wedgeLeftFullLateral
                  ? wedgeLeftFullLateral
                  : 0

              }
              InputProps={{
                readOnly: isInfo
              }}
            />
            {/* </Paper> */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
