import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';
import HttpPost from './HttpPost';
import Glb from '../global/Glb';

export default function RemoveButton(props) {
    const [open, setOpen] = React.useState(false);
    const oid = props.oid;
    const typeId = props.typeId;
    const cid = props.cid;
    const uid = props.uid;
    const setRows = props.setRows;
    const keyExtractor = props.keyExtractor;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteSubmit = async () => {

        switch (keyExtractor) {
            case "cpi":
                var data = {
                    dta: {
                        cid: cid,
                        tid: typeId
                    }
                }
                var res = await HttpPost("/prdRelDel", data);
                if (res?.sta === "ACK") {
                    var newRows = Glb.Storage.comLst.filter(c => c.cid !== cid);
                    if (setRows) {
                        if (newRows) {
                            Glb.Storage.comLst = newRows;
                            setRows(newRows)
                        }
                        else {
                            Glb.Storage.comLst = newRows;
                            setRows([])
                        }
                    }

                    setOpen(false);
                }
                break;
            case "oid":
                var data = {
                    iid: oid
                }
                var res = await HttpPost("/ordDel", data);
                if (res?.sta === "ACK") {
                    var newRows = Glb.Storage.ordLst.filter(x => x.oid !== oid);
                    if (setRows) {
                        if (newRows) {
                            Glb.Storage.ordLst = newRows;
                            setRows(newRows)
                        }
                        else {
                            Glb.Storage.ordLst = newRows;
                            setRows([])
                        }
                    }

                    setOpen(false);
                }
                break;
            case "uid":
                var data = {
                    iid: uid
                }
                var res = await HttpPost("/usrDel", data);
                if (res?.sta === "ACK") {
                    var newRows = Glb.Storage.usrLst.filter(x => x.uid !== uid);
                    if (setRows) {
                        if (newRows) {
                            Glb.Storage.usrLst = newRows;
                            setRows(newRows)
                        }
                        else {
                            Glb.Storage.usrLst = newRows;
                            setRows([])
                        }
                    }

                    setOpen(false);
                }
                break;

            default:
                break;
        }

    };

    return (
        <div>
            <IconButton size="small" onClick={handleClickOpen} >
                <DeleteIcon />
            </IconButton>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Silmek istediğinizden emin misiniz?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Silmeyi onayla.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        İptal
                    </Button>
                    <Button onClick={handleDeleteSubmit} color="primary" autoFocus>
                        Sil
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
