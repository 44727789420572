import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { lng } from "../../Tore/Sys";
import CustomPaper from "../CustomPaper";
import InsoleInputs from "./InsoleInputs";
import SlipperInputs from "./SlipperInputs";
import OrderInputs from "./OrderInputs";
import FileInput from "../FileInput";
import CancelAndSubmitButton from "../CancelAndSubmitButton";
import { useEffect } from "react";
import Glb from "../../global/Glb";
import HttpPost from "../HttpPost";
import { useHistory } from "react-router-dom";
import Helper from "../../utils/Helper";
import RepairInputs from "./RepairInputs";
import uuid from "uuid/v4";

export default function OrderRegister(props) {
  const { type } = props;
  const history = useHistory();
  const [cid, setCid] = useState("");

  function formatDate(date) {

    if (!date)
      return null;
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  }

  useEffect(() => {
    Glb.Storage.ord = {
      oid: uuid(),
      typ: type,
      odt: Helper.makeTimeStamp()
    };
    Glb.Errors.ord = {
      cid: true,
      uid: true,
      pid: true,
      did: true,
      odt: false,
      fil: true
    }

    var usr = Glb.Storage.usr;
    if (!usr.adm) {
      Glb.Storage.ord.uid = usr.uid;
      Glb.Errors.ord.uid = false;
      Glb.Storage.ord.cid = usr.cid;
      Glb.Errors.ord.cid = false;
      setCid(usr.cid);
    }

  }, []);

  const handleSubmit = async () => {

    var errorsObj = Glb.Errors.ord;
    var typeErrorsObj = Glb.Errors[type];

    // alert(JSON.stringify(errorsObj))
    // alert(JSON.stringify(typeErrorsObj))
    for (var prop in errorsObj) {
      if (errorsObj[prop] !== false && prop !== "did") {
        alert("Lütfen gerekli alanları (*) eksiksiz giriniz.");
        alert(prop)
        return;

      } else if (errorsObj[prop] !== false && prop === "did" && type !== "Repair") {
        alert("Lütfen gerekli alanları (*) eksiksiz giriniz.");
        alert(prop)
        return;
      }
    }

    for (var prop in typeErrorsObj) {
      if (typeErrorsObj[prop] !== false) {
        alert(prop)
        alert("Lütfen gerekli alanları (*) eksiksiz giriniz.");
        return;
      }
    }

    var ord = Glb.Storage.ord;
    //console.log(ord);
    var pnm = ord.pnm;
    delete ord.pnm;


    let response = await HttpPost("/ordIns", ord);
    if (response) {
      if (response?.sta === "ACK") {
        var oid = response?.dta.oid;
        ord.oid = oid;
        ord.ook = false;
        ord.ods = false;
        ord.pnm = pnm;
        ord.oet = Helper.makeTimeStamp();


        Glb.Reload.ord = false;
        Glb.RegisterSuccess.ord = true;
        if (Glb.Storage.ordLst) {
          Glb.Storage.ordLst.push(ord);
        } else {
          Glb.Storage.ordLst = [];
          Glb.Storage.ordLst.push(ord);
        }
        history.goBack();
      }
    }
  };

  var typeInputs;
  var headerTxt;
  var isRepair = false;
  switch (type) {
    case "Insole":
      typeInputs = <InsoleInputs cid={cid} />;
      headerTxt = lng.itm("M_LBL_INSOLE");
      break;
    case "Slipper":
      typeInputs = <SlipperInputs />;
      headerTxt = lng.itm("M_LBL_SLIPPER");
      break;
    case "Repair":
      typeInputs = <RepairInputs />
      headerTxt = lng.itm("M_LBL_REPAIR");
      isRepair = true;
      break;

    default:
      break;
  }

  return (
    <Grid container>
      <Grid item sm={2} />
      <Grid item sm={8}>
        <CustomPaper>
          <h3>{lng.itm("M_HDR_ORD_INS") + " - " + headerTxt}</h3>

          <Grid container direction="column" spacing={1}>
            <Grid container item direction="row" spacing={2}>
              <Grid container item spacing={2}>
                <OrderInputs cid={cid} setCid={setCid} isRepair={isRepair} typ={type} />
              </Grid>

              {typeInputs}

            </Grid>
            <Grid item>
              <FileInput cid={cid} />
            </Grid>
            <Grid container direction="column" alignItems="flex-end">
              <Grid style={{ paddingTop: "30px" }}>
                <CancelAndSubmitButton onClick={handleSubmit} />
              </Grid>
            </Grid>
          </Grid>
        </CustomPaper>
      </Grid>
      <Grid item sm={2} />
    </Grid>
  );
}
