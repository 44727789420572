"using strict"
/*————————————————————————————————————————————————————————————————————————————
    ——————————————————————————————————————————————
    |   Sys : JS Utility library for React JS    |
    ——————————————————————————————————————————————

© Copyright 2021 İhsan Volkan Töre.

Author              : IVT.  (İhsan Volkan Töre)
Version             : 202103151356

History             :
202103151356: IVT   : First Draft.
————————————————————————————————————————————————————————————————————————————*/
/*————————————————————————————————————————————————————————————————————————————
  INFO:
    * This library can be used in both react js and react native.
    * It Collects exception, language and other subsystems together.
————————————————————————————————————————————————————————————————————————————*/
import {exc, Exc}   from    "./Exc.js";
import {lng}        from    "./Lng.js";


/*————————————————————————————————————————————————————————————————————————————
    —————————————
    | Utilities |
    —————————————
————————————————————————————————————————————————————————————————————————————*/
/*————————————————————————————————————————————————————————————————————————————
  FUNC: def
  TASK: Checks if an object is defined and non null.
  ARGS: obj : object    : Object to check.
  RETV:     : boolean   : true if object is defined and non null. 
————————————————————————————————————————————————————————————————————————————*/
function def(obj){
    return ((typeof(obj) !== typeof(undefined)) && (obj !== null) );
}

/*————————————————————————————————————————————————————————————————————————————
  FUNC: chk
  TASK: Throws exception if an object    
                            is undefined 
                            or null 
                            or empty string or all whitespaces.
  ARGS: obj : object    : Object to check.
        nam : string    : Object variable name for exception info.
        tag : string    : [optional] tag. Default is "E_INV_ARG".
————————————————————————————————————————————————————————————————————————————*/
function chk(obj, nam, tag = "E_INV_ARG") {
var t = typeof(obj);

    if ((t   === typeof(undefined))
    ||  (obj === null)
    ||  ((t  === typeof(String)) && (obj.trim() === "")) )
        exc(tag, nam);
}


export {def, chk, lng, exc, Exc};