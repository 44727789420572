import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Glb from '../global/Glb'
import SearchTable from '../components/table/SearchTable';
import DataTable from '../components/table/DataTable';
import { lng } from '../Tore/Lng';


const useStyles = makeStyles((theme) => ({
    formCenter: {
        alignItems: "center",
        justify: "center",
        height: 300,
        width: "%100",
        margin: "dense",

    },
    table: {
        fontSize: "10px"
    },
    button: {
        margin: theme.spacing(1),
        justifyContent: "center"
    },
}));

export default function Patients() {
    const classes = useStyles();
    var columnsTag = "M_TBL_PAT_ADM_HDR";
    const searchInputs = ["M_INP_PAT_NAM", "M_INP_PAT_SUR", "M_INP_PNO", "M_INP_COM", "M_INP_USR", "M_INP_ACT"]
    const [rows, setRows] = useState([]);
    var [reRender, setReRender] = useState({});

    var columns = lng.itm(columnsTag);

    const isAdmin = Glb.Storage.usr.adm;

    useEffect(() => {

        Glb.Filter.dta = { act: true }
        Glb.Filter.skp = 0;
        Glb.ListFiltered.pat = true;

        if (!Glb.Reload.pat)
            setRows(Glb.Storage.patLst)
        return () => {
            Glb.Filter.dta = {}
            Glb.Filter.skp = 0;
            Glb.ListFiltered.pat = false;
        }
    }, [])

    if (!isAdmin) {
        var index = searchInputs.indexOf("M_INP_COM");
        if (index > -1) {
            searchInputs.splice(index, 1);
        }

        columnsTag = "M_TBL_PAT_HDR"
    }

    return (
        <Grid container>
            <Grid container>
                <Grid item xm={"false"} sm={2} />
                <Grid container className={classes.formCenter} xm={12} sm={8}>
                    <SearchTable setRows={setRows} searchInputs={searchInputs} registerPath="/patientRegister" searchPath="/patLst" />
                    <DataTable rows={rows} columnsTag={columnsTag} editPath="/patientEdit" editable={true} registeredObj="pat" setReRender={setReRender} keyExtractor="pid" />
                </Grid>
                <Grid item xm={"false"} sm={2} />
            </Grid>
        </Grid>

    );
}