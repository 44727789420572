import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { lng } from '../../Tore/Sys'
import Glb from '../../global/Glb';
import checkBoxChecked from '../../images/checkboxChecked.png'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

export default function BodyPainAreasRight(props) {

    const classes = useStyles();

    const labels = lng.itm("M_CHK_BD");

    const isEdit = props?.isEdit;
    const isRegister = props?.isRegister;

    const isInfo = (props?.row != undefined) && (!isEdit && !isRegister);
    const fillValue = (props?.row != undefined) && (isInfo || isEdit || isRegister);

    const [state, setState] = React.useState({
        bodyRSFootPain: false,
        bodyRSHipPain: false,
        bodyRSNeckPain: false,
        bodyRSBackPain: false,
        bodyRSKneePain: false,
        bodyRSAnklePain: false

    });

    useEffect(() => {

        if (fillValue) {

            let row = props.row;

            let checkBoxesState = {
                bodyRSFootPain: row.bodyRSFootPain === true ? (true) : (false),
                bodyRSHipPain: row.bodyRSHipPain === true ? (true) : (false),
                bodyRSNeckPain: row.bodyRSNeckPain === true ? (true) : (false),
                bodyRSBackPain: row.bodyRSBackPain === true ? (true) : (false),
                bodyRSKneePain: row.bodyRSKneePain === true ? (true) : (false),
                bodyRSAnklePain: row.bodyRSAnklePain === true ? (true) : (false)
            }
            setState(checkBoxesState);
        }
    }, [props.row]);

    const handleChange = (event) => {
        if (isEdit || !isInfo) {
            var value = event.target.checked;
            var name = event.target.name;
            if (name) {
                if (value && value === false)
                    delete Glb.Storage.dia[name]
                else
                    Glb.Storage.dia[name] = value;
                setState({ ...state, [name]: value });
            }

        }
    };

    const { bodyRSFootPain, bodyRSHipPain, bodyRSNeckPain, bodyRSBackPain, bodyRSKneePain, bodyRSAnklePain } = state;
    const myCheckBox = () => {
        return (
            <div style={{
                width: '21.33px',
                height: '21.33px',
                display: 'inline-block',
            }} >
                <img src={checkBoxChecked} style={{
                    marginTop: "2px",
                    width: '17.33px',
                    height: '17.33px',
                    display: 'inline-block',
                }} />
            </div>
        )
    }
    return (
        <div className={classes.root}>
            <FormControl className={classes.formControl}>
                <FormLabel>{props.formLabel}</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={bodyRSNeckPain} onChange={handleChange} name="bodyRSNeckPain" />}
                        label={<span style={bodyRSNeckPain ? { fontWeight: 'bold' } : {}}>{labels.one}</span>}
                        //label={labels.one}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={bodyRSBackPain} onChange={handleChange} name="bodyRSBackPain" />}
                        label={<span style={bodyRSBackPain ? { fontWeight: 'bold' } : {}}>{labels.two}</span>}
                        //label={labels.two}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={bodyRSHipPain} onChange={handleChange} name="bodyRSHipPain" />}
                        label={<span style={bodyRSHipPain ? { fontWeight: 'bold' } : {}}>{labels.three}</span>}
                        //label={labels.three}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={bodyRSKneePain} onChange={handleChange} name="bodyRSKneePain" />}
                        label={<span style={bodyRSKneePain ? { fontWeight: 'bold' } : {}}>{labels.four}</span>}
                        //label={labels.four}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={bodyRSAnklePain} onChange={handleChange} name="bodyRSAnklePain" />}
                        label={<span style={bodyRSAnklePain ? { fontWeight: 'bold' } : {}}>{labels.five}</span>}
                        //label={labels.five}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={bodyRSFootPain} onChange={handleChange} name="bodyRSFootPain" />}
                        label={<span style={bodyRSFootPain ? { fontWeight: 'bold' } : {}}>{labels.six}</span>}
                        //label={labels.six}
                    />
                </FormGroup>
                <FormHelperText></FormHelperText>
            </FormControl>
        </div>
    );
}
