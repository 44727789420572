import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SearchTable from '../components/table/SearchTable';
import DataTable from '../components/table/DataTable';
import Glb from '../global/Glb';

const useStyles = makeStyles((theme) => ({
    formCenter: {
        alignItems: "center",
        justify: "center",
        height: 300,
        width: "%100",
        margin: "dense"
    },
    table: {
        fontSize: "10px"
    },
    button: {
        margin: theme.spacing(1),
        justifyContent: "center"
    },
}));

export default function Companies() {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    var [reRender, setReRender] = useState({});

    useEffect(() => {
        Glb.ListFiltered.prd = false;

        Glb.Filter.dta = { act: true }
        Glb.Filter.skp = 0;

        if (Glb.Storage.allComs)
            setRows(Glb.Storage.allComs)
        else {
            if (Glb.Storage.comLst.length !== 0)
                setRows(Glb.Storage.comLst)
        }


        return () => {
            Glb.Filter.dta = {}
            Glb.Filter.skp = 0;
        }
    }, [])


    const columnsTag = "M_TBL_COM_HDR";
    const searchInputs = ["M_INP_COM_NAM", "M_INP_EMA", "M_INP_PNO", "M_INP_PRD", "M_INP_ACT"]
    return (
        <Grid container>
            <Grid container>
                <Grid item xm={"false"} xs={0} sm={2} />
                <Grid container className={classes.formCenter} xs={12} sm={8}>
                    <SearchTable setRows={setRows} searchInputs={searchInputs} registerPath="/companyRegister" searchPath="/comLst" />
                    <DataTable rows={rows} columnsTag={columnsTag} editPath="/companyEdit" editable={true} registeredObj="com" setReRender={setReRender} keyExtractor="cid" />
                </Grid>
            </Grid>
            <Grid item xm={"false"} xs={0} sm={2} />
        </Grid>

    );
}