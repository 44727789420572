import React, { useEffect, createRef, useState } from "react";
import {
  Grid, TextField,
  Button, Box, Typography,
  FormControlLabel, LinearProgress, IconButton
} from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { lng } from "../../Tore/Sys";
import CustomPaper from "../CustomPaper";
import InsoleEdit from "./InsoleEdit";
import SlipperEdit from "./SlipperEdit";
import InfoButton from "../InfoButton";
//import FileInput from "../FileInput";
import CancelAndSubmitButton from "../CancelAndSubmitButton";
import Glb from "../../global/Glb";
import HttpPost from "../HttpPost";
import EditButton from "../EditButton";
import EditSuccessAlert from "../EditSuccessAlert";
import Helper from "../../utils/Helper";
import axios from "axios";
import uuid from "uuid/v4";
import Pdf from "react-to-pdf";
import checkBoxChecked from '../../images/checkboxChecked.png'
import PdfIcon from '../../images/downloadPdf.png'

function isValidForSubmit(values) {
  var ord = { ...values };
  delete ord.oid;
  delete ord.index;
  if (ord && Object.keys(ord).length === 0 && ord.constructor === Object) {
    return false;
  }
  return true;
}

export default function OrderEdit(props) {
  const { record, handleClose } = props;
  const [reRender, setReRender] = useState();
  const [pnm, setPnm] = useState(record?.pnm);
  const [ddm, setDdm] = useState(record?.ddm);
  const [progress, setProgress] = useState(0);
  const editable = props.editable;

  //const row = location?.state?.record;

  const editSuccessPat = Glb.EditSuccess.pat;
  const editSuccessDia = Glb.EditSuccess.dia;

  const isAdmin = Glb.Storage.usr.adm;

  var allComs = Glb.Storage.allComs;

  var userCompany;
  if (isAdmin && allComs)
    userCompany = allComs.find(c => c.cid === record.cid)

  useEffect(() => {
    Glb.Storage.ord = {
      oid: record.oid,
      index: record.index
    };

    Helper.resetErrors();

    if (Glb.Storage.ordLst) {
      if (ddm) {
        if (record.index !== null && record.index !== undefined) {
          Glb.Storage.ordLst[record.index]["ddm"] = ddm;
        }
      }

      if (pnm) {
        if (record.index !== null && record.index !== undefined) {
          Glb.Storage.ordLst[record.index]["pnm"] = pnm;
        }
      }
    }

    return () => {
      Glb.EditSuccess.pat = false;
      Glb.EditSuccess.dia = false;
    }

    // async function fetchRecordByXid(xid, endpoint, idStr) {
    //   var dta = {};
    //   dta[idStr] = xid;
    //   var data = {
    //     dta: dta,
    //     lim: 1,
    //     skp: 0
    //   }
    //   var res = await HttpPost(endpoint, data);
    //   if (res?.sta === "ACK") {
    //     //alert(JSON.stringify(res))
    //     if (res?.dta) {
    //       if (idStr === "pid")
    //         setPat(res.dta[0]);
    //       else if (idStr === "did")
    //         setDia(res.dta[0]);
    //     }
    //   }
    // }

    // fetchRecordByXid(record.pid, "/patLst", "pid");
  }, [pnm, ddm]);

  const handleChange = (e) => {
    var checked = e.target.checked;
    var id = e.target.id;
    if (id) {
      if (checked || checked === false) {
        Glb.Storage.ord[id] = checked;
      }
    }
  };

  const handleTextChange = (e) => {
    var id = e.target.id;
    var value = e.target.value;
    if (id) {
      if (value) {
        Glb.Storage.ord[id] = value;
      }
    }
  };

  const handleSubmit = async () => {
    var ord = Glb.Storage.ord;

    var isValid = isValidForSubmit(ord);
    if (!isValid) {
      alert(lng.itm("M_WARN_INV_VALS"));
      return;
    }

    let response = await HttpPost("/ordUpd", ord);
    if (response) {
      if (response?.sta === "ACK") {
        Glb.EditSuccess.ord = true;
        if (handleClose)
          handleClose()
      }
    }
  };

  const downloadOrderFiles = async () => {

    var dta = {
      cid: record.cid,
      oid: record.oid,
      setProgress: setProgress
    }

    await HttpPost("/downloadOrderFiles", dta)

    // let response = localStorage.getItem("response");
    // let resObj = JSON.parse(response);
    // var rx = {
    //   "rid": uuid(),
    //   "tim": "20210727062854805",
    //   "src": resObj.tar,
    //   "tar": "File",
    //   "dta": {
    //     "cid": record.cid,
    //     "oid": record.oid
    //   }
    // }

    // axios({
    //   url: "https://www.footmedlab.com:7443/" + "DownloadFiles", //your url
    //   method: 'POST',
    //   data: rx,
    //   responseType: 'blob', // important
    //   onDownloadProgress: (progressEvent) => {
    //     let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //     console.log(progressEvent.lengthComputable)
    //     console.log(percentCompleted);
    //     setProgress(percentCompleted)
    //   }
    // }).then((response) => {
    //   console.log(response)
    //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   var filename = "";
    //   var disposition = response.headers["content-disposition"];
    //   if (disposition && disposition.indexOf('attachment') !== -1) {
    //     var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    //     var matches = filenameRegex.exec(disposition);
    //     if (matches != null && matches[1]) {
    //       filename = matches[1].replace(/['"]/g, '');
    //     }
    //   }

    //   link.setAttribute('download', filename);
    //   document.body.appendChild(link);
    //   link.click();
    // });

  }

  var typeInputs;
  var headerTxt;
  switch (record.typ) {
    case "Insole":
      typeInputs = <InsoleEdit row={record} />;
      headerTxt = lng.itm("M_LBL_INSOLE");
      break;
    case "Slipper":
      typeInputs = <SlipperEdit row={record} />;
      headerTxt = lng.itm("M_LBL_SLIPPER");
      break;
    case "Repair":
      //typeInputs = <SlipperInputs />
      headerTxt = lng.itm("M_LBL_REPAIR");
      break;

    default:
      break;
  }
  const ref = createRef();
  const myCheckBox = () => {
    return (
      <div style={{
        width: '21.33px',
        height: '21.33px',
        display: 'inline-block',
      }} >
        <img src={checkBoxChecked} style={{
          marginTop: "2px",
          width: '17.33px',
          height: '17.33px',
          display: 'inline-block',
        }} />
      </div>
    )
  }
  console.log(record);
  return (
    <Grid container>
      <Pdf options={{ orientation: "landscape", unit: "in", format: [7, 5.8] }}
        x={.25} y={0}
        targetRef={ref} filename="siparis.pdf">
        {({ toPdf }) => <IconButton onClick={toPdf}>
          <img src={PdfIcon} style={{ width: '30px', height: '35px', }} />
        </IconButton>}
      </Pdf>
      <Grid item ref={ref} >
        <CustomPaper>
          <h3>{lng.itm("M_HDR_ORD_UPD") + " - " + headerTxt}</h3>

          <Grid container direction="column" spacing={1}>

            <Grid container item direction="row" spacing={2}>
              <Grid container item>
                {(isAdmin) && (
                  <Grid sm={4}>
                    <TextField disabled label={lng.itm("M_INP_COM").label} value={userCompany.nam} />
                    {/* <SearchSelect id="" use="form" lngKey={"M_INP_COM"} options={allComs} defaultValue={userCompany} /> */}
                  </Grid>
                )}

                <Grid sm={4}>
                  <Grid container direction="column">
                    <Grid container item direction="row">
                      <Grid sm={10}>
                        <TextField disabled label={lng.itm("M_INP_PAT").label} value={pnm} />
                      </Grid>
                      {editable && (
                        <Grid>
                          <EditButton row={record} path={"/patientEdit"} setReRender={setReRender} getFromServer={true} setPnm={setPnm}></EditButton>
                        </Grid>
                      )}
                    </Grid>
                    <Grid sm={10}>
                      {editSuccessPat && <EditSuccessAlert />}
                    </Grid>
                  </Grid>
                </Grid>
                {(record.typ != "Repair") && (<Grid sm={4}>
                  <Grid container direction="column">
                    <Grid container item direction="row">
                      <Grid sm={10}>
                        <TextField disabled label={lng.itm("M_INP_DIA").label} value={ddm} />
                      </Grid>
                      <Grid>
                        {editable && (<InfoButton row={record} setReRender={setReRender} isEdit={true} setDdm={setDdm} updateList="ordLst" />)}
                      </Grid>
                    </Grid>
                    <Grid sm={10}>
                      {editSuccessDia && <EditSuccessAlert />}
                    </Grid>

                  </Grid>
                </Grid>)}
              </Grid>
              <Grid container item spacing={2}>
                {typeInputs}
              </Grid>

            </Grid>

            {isAdmin && (
              <>
                <Grid container item >
                  <Grid sm={7}>
                    <TextField label={lng.itm("M_INP_ODN").label} id={lng.itm("M_INP_ODN").id} onChange={handleTextChange} defaultValue={record?.odn} fullWidth></TextField>
                  </Grid>
                  <Grid container
                    sm={5}
                    direction="row"
                    justify="center"
                    alignItems="flex-end">
                    <Grid
                      item

                    >
                      <FormControlLabel
                        control={
                          <Checkbox checkedIcon={myCheckBox()} onChange={handleChange} name="ods" id="ods" defaultChecked={record?.ods} />
                        }
                        label={lng.itm('M_CHK_ORD_DELIVERY_STATUS')}
                      />
                    </Grid>

                    <Grid item style={{ marginLeft: "15px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox checkedIcon={myCheckBox()} onChange={handleChange} name="ook" id="ook" defaultChecked={record?.ook} />
                        }
                        label={lng.itm('M_CHK_ORD_OOK')}
                      />
                    </Grid>

                  </Grid>
                </Grid>
                <Grid style={{ marginTop: "15px" }} >
                  <TextField multiline fullWidth label={lng.itm("M_INP_ONT").label} id={lng.itm("M_INP_ONT").id} onChange={handleTextChange} defaultValue={record?.ont}></TextField>
                </Grid>
              </>
            )}

            <Grid direction="column" alignItems="center" >
              <Grid container item justify="center">
                <IconButton onClick={downloadOrderFiles}>
                  <Grid container direction="column" >
                    <Grid>
                      <CloudDownloadIcon />
                    </Grid>
                    <Grid>
                      {lng.itm('M_LBL_DOWNLOAD')}
                    </Grid>
                  </Grid>
                </IconButton>

              </Grid>
              <Grid>
                {progress > 0 && (<Box display="flex" alignItems="center">
                  <Box width="100%" mr={1}>
                    <LinearProgress variant="determinate" value={progress} />
                  </Box>
                  <Box minWidth={35}>
                    <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                  </Box>
                </Box>)}

              </Grid>

            </Grid>
            <Grid container direction="column" alignItems="flex-end">
              <Grid style={{ paddingTop: "30px" }}>
                <CancelAndSubmitButton onClick={handleSubmit} submitable={editable} handleClose={handleClose} />
              </Grid>
            </Grid>
          </Grid>
        </CustomPaper>
      </Grid>
    </Grid>
  );
}
