import { Button, Input, makeStyles, TableCell, TextField } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import BusinessIcon from '@material-ui/icons/Business';
import React, { useState, useEffect } from 'react';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { lng } from '../../Tore/Sys'
import ButtonDropdown from './ButtonDropdown';
import { Link } from 'react-router-dom';
import Glb from '../../global/Glb';

const useStyles = makeStyles(() => ({
    formCenter: {
        alignItems: "center",
        justify: "center",
        height: 300,
        width: "100%",
        margin: "dense",

    },
    table: {
        fontSize: "10px"
    },
    tableRow: {

    },
    tableCell: {
        padding: "8px",
        height: 1
    },
    iconButton: {
        width: 60,
        height: 60
    }
}));

function checkIsValidFilter() {
    let dta = Glb.Filter.dta;
    let keysLength = Object.keys(dta).length;
    if (dta &&
        !(keysLength === 0) &&
        (dta.constructor === Object)) {
        return true;
    }
    return false;
}


export default function Pagination(props) {
    const classes = useStyles();

    const { handleSearch, registerPath, optionsLngKey, newButtonVisible, searchPath, page, setPage, isPrd } = props;

    const handleDecrease = () => {
        var isValid = checkIsValidFilter();
        if (!isValid) {
            alert(lng.itm("M_WARN_INV_FILTER"));
            return;
        }
        let newPage = parseInt(page) - 1;
        let pageOld = page;
        if (newPage > 1) {
            setPage(newPage)
            let lim = Glb.Filter.lim;
            let skp = lim * (newPage - 1);
            Glb.Filter.skp = skp;
        } else if (newPage === 1) {
            Glb.Filter.skp = 0;
            setPage(1);
        } else
            setPage(1);

        if (newPage !== pageOld)
            handleSearch()
    }

    const handleIncrease = () => {
        var isValid = checkIsValidFilter();
        if (!isValid) {
            alert(lng.itm("M_WARN_INV_FILTER"));
            return;
        }
        let newPage = parseInt(page) + 1;
        setPage(newPage)
        let lim = Glb.Filter.lim;
        let skp = lim * (newPage - 1);
        Glb.Filter.skp = skp;
        if (newPage !== page)
            handleSearch()
    }

    const handleChange = (e) => {
        let value = e.target.value;
        //alert(value)
        if (value < 1 && value !== "")
            value = 1;
        setPage(value)
    }

    const handleEnter = (e) => {

        var isValid = checkIsValidFilter();
        if (!isValid) {
            alert(lng.itm("M_WARN_INV_FILTER"));
            return;
        }
        if (e.key === "Enter") {
            let newPage = parseInt(e.target.value);
            setPage(newPage)
            let lim = Glb.Filter.lim;
            let skp = lim * (newPage - 1);
            Glb.Filter.skp = skp;
            handleSearch()
        }
    }


    return (
        <>

            {(searchPath) &&
                (
                    <>
                        <TableCell align="right" >
                            {(isPrd) && (
                                <Button
                                    id="increase"
                                    component={Link}
                                    to={"/productCompanyRegister"}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{
                                        width: 80,
                                        float: 'left'
                                    }}
                                    startIcon={<BusinessIcon />}
                                    endIcon={<ShoppingCartIcon />}
                                    onClick={handleIncrease}
                                >
                                    +
                                </Button>
                            )}
                            <Button
                                className={classes.button}
                                variant="contained"
                                size="small"
                                startIcon={<NavigateBeforeIcon />}
                                onClick={handleDecrease}
                            />


                        </TableCell>
                        <TableCell
                            align="center"
                        // style={{ width: 70 }}
                        >
                            <TextField
                                id={lng.itm("M_INP_PAG").id}
                                helperText={lng.itm("M_INP_PAG").label}
                                inputProps={{ min: 1, style: { textAlign: 'center' }, }}
                                type="tel"
                                onKeyPress={handleEnter}
                                onChange={handleChange}
                                //defaultValue={page}
                                value={page}
                            />

                        </TableCell>
                        <TableCell align="left" >
                            <Button
                                id="increase"
                                className={classes.button}
                                variant="contained"
                                size="small"
                                startIcon={<NavigateNextIcon />}
                                onClick={handleIncrease}
                            >
                            </Button>
                        </TableCell>
                    </>
                )
            }
            <TableCell align="center">
                <ButtonDropdown visible={newButtonVisible} registerPath={registerPath} optionsLngKey={optionsLngKey} />
            </TableCell>
        </>
    );
}