import React, { useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import {
  IconButton,
  Dialog,
  DialogContent,
  makeStyles
} from "@material-ui/core";
import DiagnosisInformation from "./diagnosis/DiagnosisInformation";
import HttpPost from "./HttpPost";
const useStyles = makeStyles((theme) => ({
  smallButton: {
    padding: 0,
  },
  smallIcon: {
    fontSize: "1em"
  },
  input: {
    display: "none"
  },
  root: (props) => {
    return {
      "& .MuiDialog-container > div": {
        width: "1200px",
        height: "800px",
        maxWidth: "1200px",
        "& .css-m1b0vd-MuiDialogTitle-root": {
          padding: "16px 24px 10px",
        },
        "& .css-1y6f8wu-MuiDialogContent-root": {
          "& .css-wffnfh": {
            padding: "24px",
          },
        },
      },
    };
  },
}));

export default function InfoButton(props) {
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState(props.row);
  const [component, setComponent] = useState("");
  const [typ, setTyp] = useState();

  var isEdit = props.isEdit;
  var setDdm = props.setDdm;

  const descriptionElementRef = React.useRef(null);

  async function fetchDiagnosis() {
    var data = {
      dta: {
        did: row.did
      },
      lim: 1,
      skp: 0
    }
    var res = await HttpPost("/diaLst", data);
    if (res?.sta === "ACK") {
      //patient = res.dta;
      //alert(JSON.stringify(res.dta));
      //setDummyState(res.dta[0]);
      var dia = res.dta[0]
      if (dia) {
        dia.isEdit = false
        setRow(dia);
        var comp = (<DialogContent>
          <DiagnosisInformation orders={props.row} handleClose={handleClose} record={dia} isEdit={isEdit} setDdm={setDdm} typ={typ} page={props.page} />
        </DialogContent>)
        setComponent(comp)
      }
    }
  }

  React.useEffect(() => {
    if (row.typ)
      setTyp(row.typ)
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }


  }, [open]);

  const handleOpen = () => {
    fetchDiagnosis();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    if (setReRender)
      setReRender({});
  };
  const classes = useStyles();
  var setReRender = props.setReRender;
  return (
    <>
      <IconButton
        className={classes.smallButton}
        aria-label="info"
        size="small"
        onClick={handleOpen}
      >
        {isEdit && (<EditIcon size="small" />)}
        {!isEdit && (<VisibilityIcon size="small" />)}

      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        classes={{
          root: classes.root,
        }}
      >
        {open &&
          component
        }
      </Dialog>
    </>
  );
}
