import React from 'react';
import TextField from '@material-ui/core/TextField';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import PhoneInput from '../PhoneInput'
import { lng } from '../../Tore/Sys'


const inputProps = {
    variant: "outlined",
    margin: "dense",
    size: "small"
}

export default function CompanyEditForm(props) {

    const company = props.record;
    const onChange = props.onChange;
    const handleActChange = props.handleActChange;
    const setFormikField = props.setFormikField;

    const handleChange = (e) => {
        e = handleActChange(e)
        if (e)
            onChange(e)
    }


    return (
        <Grid container direction="column">
            <TextField id={lng.itm("M_INP_NAM").id} label={lng.itm("M_INP_NAM").label} defaultValue={company.nam} {...inputProps} onChange={onChange} />
            <TextField id={lng.itm("M_INP_EMA").id} label={lng.itm("M_INP_EMA").label} defaultValue={company.ema} {...inputProps} onChange={onChange} />
            <TextField id={lng.itm("M_INP_ADR").id} label={lng.itm("M_INP_ADR").label} defaultValue={company.adr} {...inputProps} onChange={onChange} multiline rows={4} />

            <PhoneInput variant="outlined" setFormikField={setFormikField} handleChange={onChange} phone={company.pno} />
            <FormControl>
                <InputLabel id={lng.itm("M_INP_ACT").id} >{lng.itm("M_INP_ACT").label} </InputLabel>
                <Select
                    labelId={lng.itm("M_INP_ACT").id}
                    id={lng.itm("M_INP_ACT").id}
                    onChange={handleChange}
                    variant="outlined"
                    style={{ height: "40px" }}
                >
                    <MenuItem value={true}>{lng.itm("M_LBL_ACT")}</MenuItem>
                    <MenuItem value={false}>{lng.itm("M_LBL_INACT")}</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    );
}
