import React from "react";
import TextField from "@material-ui/core/TextField";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { lng } from "../../Tore/Sys";

const inputProps = {
  variant: "outlined",
  margin: "dense",
  size: "small",
};

export default function ProductEditForm({ record, onChange, handleActChange }) {
  const handleChange = (e) => {
    e = handleActChange(e);
    if (e) onChange(e);
  };
  return (
    <Grid container direction="column">
      <TextField
        id={lng.itm("M_INP_NAM").id}
        label={lng.itm("M_INP_NAM").label}
        defaultValue={record.nam}
        {...inputProps}
        onChange={onChange}
      />

      <TextField
        id={lng.itm("M_INP_NAMEN").id}
        label={lng.itm("M_INP_NAMEN").label}
        defaultValue={record.namen}
        {...inputProps}
        onChange={onChange}
      />

      <FormControl>
        <InputLabel id={lng.itm("M_INP_ACT").id}>
          {lng.itm("M_INP_ACT").label}{" "}
        </InputLabel>
        <Select
          labelId={lng.itm("M_INP_ACT").id}
          id={lng.itm("M_INP_ACT").id}
          variant="outlined"
          style={{ height: "40px" }}
          onChange={handleChange}
        >
          <MenuItem value={true}>{lng.itm("M_LBL_ACT")}</MenuItem>
          <MenuItem value={false}>{lng.itm("M_LBL_INACT")}</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
}
