import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import Glb from '../global/Glb';
import { lng } from '../Tore/Sys'
import Helper from '../utils/Helper';
import HttpPost from './HttpPost';
import { matchSorter } from 'match-sorter';

function SearchSelect(props) {

    var textFieldProps = {
        variant: "outlined",
        size: "small",
        margin: "dense"
    }

    var { lngKey, use, setFormikField, defaultValue, obj, setId, id, options, required, objProp, defObj, setDefObj, setObj, disable, filterValueForObjProp } = props;

    const input = lng.itm(lngKey)
    const [searchWaiting, setSearchWaiting] = useState(null);

    const [disabled, setDisabled] = useState(false);

    var filterOptions;

    if (required)
        textFieldProps.required = true;

    if (use && use === "form") {
        textFieldProps.variant = "outlined";
    } else
        delete textFieldProps.variant;

    const [selects, setSelects] = useState([]);

    const [field, setField] = useState(null);
    const [defVal, setDefVal] = useState(defObj);

    useEffect(() => {

        if (disable) {
            setDisabled(true);
        }
        if (id === "" && use !== "search") {
            Glb.Errors.ord[input.id] = true;
            if (setId)
                setId("");
            setDisabled(true);
            setDefVal("");
        }
        else if (defObj) {
            // alert("DefObjS " + JSON.stringify(defObj))
            setDisabled(false);
            setDefVal(defObj);
            Glb.Errors.ord[input.id] = false;
            if (setId) {
                setId(defObj[input.id]);
                //alert(input.id)
            }
        }
        else
            setDisabled(false)
        if (defObj === "")
            setSelects([])
        if (options) {

            if (objProp) {
                //alert(JSON.stringify(options))
                var newOptions = options.filter(o => o[objProp] === id)
                //alert(JSON.stringify(newOptions))
                setSelects(newOptions);

            } else
                setSelects(options);

        }

        switch (input.id) {
            case "pid":
                filterOptions = (options, { inputValue }) =>
                    matchSorter(options, inputValue, { threshold: matchSorter.rankings.CONTAINS, keys: ["nam", "sur", "pno", "ema"] });
                break;
            case "uid":
                (!objProp || !id) ? setSelects(Glb.Storage.allUsrs) : setSelects(Glb.Storage.allUsrs.filter(o => o[objProp] === id))
                filterOptions = (options, { inputValue }) =>
                    matchSorter(options, inputValue, { threshold: matchSorter.rankings.CONTAINS, keys: ["nam", "sur"] });
                break;
            case "dia":
                filterOptions = (options, { inputValue }) =>
                    matchSorter(options, inputValue, { threshold: matchSorter.rankings.CONTAINS, keys: ["ddm", "det"] });
                break;
            case "did":
                filterOptions = (options, { inputValue }) =>
                    matchSorter(options, inputValue, { threshold: matchSorter.rankings.CONTAINS, keys: ["ddm", "det"] });
                break;
            case "cid":
                setSelects(Glb.Storage.allComs)
                filterOptions = (options, { inputValue }) =>
                    matchSorter(options, inputValue, { threshold: matchSorter.rankings.CONTAINS, keys: ["nam"] });
                break;
            default:
                filterOptions = (options, { inputValue }) =>
                    matchSorter(options, inputValue, { threshold: matchSorter.rankings.CONTAINS, keys: ["nam"] });
                break;
        }

    }, [id, defObj, options, filterValueForObjProp]);

    const updateSearch = async (text) => {

        var filter = {
            lim: 10,
            skp: 0,
            dta: {}
        };
        if (text && input?.id !== "did")
            filter.dta.nam = text;
        else if (text && input?.id === "did")
            filter.dta.ddm = text;
        if (objProp) {
            var value = id;
            if (filterValueForObjProp && filterValueForObjProp !== "")
                value = filterValueForObjProp;
            filter.dta[objProp] = value;
        }

        let searchEndpoint = "";
        if (input.id === "tid")
            searchEndpoint = "/prdLst"
        else if (input.id === "cid")
            searchEndpoint = "/comLst"
        else if (input.id === "uid")
            searchEndpoint = "/usrLst"
        else if (input.id === "pid")
            searchEndpoint = "/patLstByNam"
        else if (input.id === "did")
            searchEndpoint = "/diaLst"
        let response = await HttpPost(searchEndpoint, filter)
        if (response.sta === "ACK") {
            if (response.dta !== null)
                setSelects(response.dta);
        }
    }

    const handleSelection = (event, value, reason) => {

        if (reason === "select-option") {
            var buggyId = event.target.id;
            var id = buggyId.substring(0, 3);
            setField(id);

            setDefVal(value)
            if (value) {
                Glb.Filter.dta[id] = value[id];
                Glb.Errors.ord[id] = false;
                if (setFormikField) {
                    setFormikField(id, value[id]);
                } else {
                    if (obj) {
                        Glb.Storage[obj][id] = value[id];
                    }
                }
                if (setId) {
                    setId(value[id]);
                    //alert(input.id)
                }
                if (setObj) {

                    setObj(value)
                }
            }
        }
        else if (reason === "clear") {
            delete Glb.Filter.dta[field];
            setDefVal("")
            if (setId) {
                setId("");
            }
            if (setDefObj)
                setDefObj("");
        }

    }

    return (<Autocomplete
        id={input.id}
        key={id}
        options={selects}
        getOptionLabel={(option) => {
            console.log(option)
            if (option && option !== false) {
                if (option.ddm) {

                    return option.ddm + " " + option.det;
                }
                if (input.id === "pid") {
                    var pno = option?.pno
                    if (pno)
                        pno = Helper.maskPhone(pno);

                    return option?.nam + " " + option?.sur + " " +
                        pno + " " + option?.ema
                }

                return option.sur ?
                    (option.nam + " " + option.sur)
                    : (option.nam)
            }
            else
                return ""
        }}
        onChange={handleSelection}
        filterOptions={filterOptions}
        fullWidth
        disabled={disabled}
        value={(defVal !== "") && defVal}
        defaultValue={defaultValue && defaultValue}
        renderInput={(params) => <TextField fullWidth id={input.id} onChange={(e) => {
            let text = e.target.value;

            if (text.length < 2)
                return;
            if (searchWaiting) {
                clearTimeout(searchWaiting)
            }
            let searchfunc = setTimeout(() => {
                setSearchWaiting(null);
                updateSearch(text)
            }, 400);
            setSearchWaiting(searchfunc);

        }} {...params} label={input.label} {...textFieldProps} />}
    />

    );
}

export default SearchSelect;