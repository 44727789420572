import { Grid, IconButton, makeStyles, Paper, TextField, Typography, Box, LinearProgress } from '@material-ui/core';
import React, { createRef, useEffect, useState } from 'react';
import PainAreas from './PainAreas';
import { ThemeProvider } from '@material-ui/styles';
import { lng } from '../../Tore/Sys'
import WedgesLeft from './WedgesLeft';
import WedgesRight from './WedgesRight';
import ShortnessLeft from './ShortnessLeft';
import ShortnessRight from './ShortnessRight';
import PatientInfo from './PatientInfo';
import Glb from '../../global/Glb'
import CancelAndSubmitButton from '../CancelAndSubmitButton';
import HttpPost from '../HttpPost';
import Helper from '../../utils/Helper';
import Pdf from "react-to-pdf";
import PdfIcon from '../../images/downloadPdf.png'
import checkBoxChecked from '../../images/checkboxChecked.png'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MeasurementAFoot from "./MeasurementAFoot";
import MeasurementBFoot from "./MeasurementBFoot";
import MeasurementABImage from '../../images/ab.jpg';

const useStyles = makeStyles((theme) => ({
    formCenter: {
        alignItems: "center",
        //width: "%100",
        //margin: "dense",
    },
    paper: {
        // padding: theme.spacing(2),
        height: "100%",
        //color: "theme.palette.text.secondary",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "top",
        paddingTop: "20px"
    },
    abImg: {
        height: "130px",
        width: "130px"
    },
}));

export default function DiagnosisInformation({ record, handleClose, isEdit, setReRender, setDdm, orders, typ, page }) {

    const [row, setRow] = useState(record);
    const [orderInf, setOrderInf] = useState([])
    const isAdmin = Glb.Storage.usr.adm;
    const [oet, setoet] = useState()
    const [odt, setodt] = useState()
    const [ont, setont] = useState()
    const [odn, setodn] = useState()
    const [xts, setxts] = useState()
    const [ook, setook] = useState()
    const [ods, setods] = useState()
    const [fileNames, setFileNames] = useState()
    const [progress, setProgress] = useState(0);
    const [measurer, setMeasurer] = useState();
    const [fillValue, setFillValue] = useState(false);

    if (isEdit)
        row.isEdit = isEdit;
    var allComs = Glb.Storage.allComs;

    var userCompany;
    if (allComs)
        userCompany = allComs.find(c => c.cid === row.cid)

    function handleChange(e) {
        let val = e.currentTarget.value
        let id = e.currentTarget.id;

        if (id) {

            Glb.Storage.dia[id] = val;
            Glb.Errors.dia[id] = false;
            switch (id) {
                case "measurer":
                    setMeasurer(val)
                    break;
                default:
                    break;
            }

        }
    }

    useEffect(() => {
        //alert(JSON.stringify(orders))
        async function GetFileNames() {
            var dta = {
                oid: orders.oid
            }
            var res = await HttpPost("/GetFileNames", dta);
            if (res?.sta === "ACK") {
                if (res?.dta?.length > 0)
                    setFileNames(res.dta);
            }
        }
        Glb.Storage.dia = {}
        Helper.resetDiaErrors();
        if (orders)
            GetFileNames();
        if (record) {
            setMeasurer(record.measurer);
            setFillValue(true);
        }

    }, [record])

    useEffect(() => {
        //console.log(row)
        if (orders?.typ)
            getOrderInfoRow()
    }, [])
    const handleSubmit = async () => {
        // alert(row.did)
        // alert(JSON.stringify(Glb.Storage.dia));
        var dia = Glb.Storage.dia;
        if (dia && Object.keys(dia).length === 0 && dia.constructor === Object) {
            alert("Herhangi bir değişiklik yapmadınız.")
            return;
        }
        dia.did = row.did;
        dia.index = row.index;
        var ddm = dia.ddm;
        let res = await HttpPost("/diaUpd", dia);
        if (res) {
            if (res?.sta === "ACK") {
                Glb.EditSuccess.dia = true;
                if (ddm) {
                    if (setDdm)
                        setDdm(ddm);
                }
                if (setReRender)
                    setReRender({});
                Object.keys(dia).map((item) => {
                    row[item] = dia[item];
                });
                setRow(row);
                handleClose();
            }
        }
    };

    const downloadOrderFiles = async () => {

        var dta = {
            cid: record.cid,
            oid: orders.oid,
            setProgress: setProgress
        }

        console.log(dta)
        await HttpPost("/downloadOrderFiles", dta)
    }

    //console.log('order Info ', orders);
    const classes = useStyles();
    const ref = createRef()
    function getOrderInfoRow() {
        switch (orders.typ) {
            case "Insole":
                async function fetchProductsByTid(tid) {
                    var data = {
                        dta: {
                            tid: tid
                        },
                        lim: 1,
                        skp: 0
                    }
                    var res = await HttpPost("/prdLst", data);
                    if (res?.sta === "ACK") {

                        setOrderInf([
                            {
                                label: lng.itm('M_INP_INSOLE_FOOT_TYPE').label,
                                value: lng.itm('M_INP_INSOLE_FOOT_TYPE').items.find(item => item.value == orders.stp).text
                            },
                            { label: lng.itm('M_INP_INSOLE_HARDNESS').label, value: lng.itm('M_INP_INSOLE_HARDNESS').items.find(item => item.value == orders.ihs).text },
                            { label: lng.itm('M_INP_INSOLE_TYPES').label, value: (localStorage.getItem("selectedLng") === "EN" && res.dta?.find(item => item.tid === orders.tid)?.namen) ? res.dta?.find(item => item.tid === orders.tid)?.namen : res.dta?.find(item => item.tid === orders.tid)?.nam },
                            { label: lng.itm('M_INP_INSOLE_STAGES').label, value: lng.itm('M_INP_INSOLE_STAGES').items.find(item => item.value == orders.spf).text },
                            { label: lng.itm('M_INP_INSOLE_LEFT').label, value: lng.itm('M_INP_INSOLE_LEFT').items.find(item => item.value == orders.ilf).text },
                            { label: lng.itm('M_INP_INSOLE_COVERING_CHOICES').label, value: lng.itm('M_INP_INSOLE_COVERING_CHOICES').items.find(item => item.value == orders.cch).text },])
                    }
                }
                setoet(orders.oet)
                setodt(orders.odt)
                setont(orders.ont)
                setodn(orders.odn)
                setxts(orders.xts)
                setook(orders.ook)
                setods(orders.ods)
                fetchProductsByTid(orders.tid)


                break;
            case "Slipper":
                setOrderInf([
                    {
                        label: lng.itm('M_INP_SLIPPER_TYPES').label,
                        value: lng.itm('M_INP_SLIPPER_TYPES').items.find(item => item.value == orders.slp).text
                    },
                    { label: lng.itm('M_INP_SLIPPER_MATERIAL_COLOR').label, value: lng.itm('M_INP_SLIPPER_MATERIAL_COLOR').items.find(item => item.value == orders.mcl).text },
                    { label: lng.itm('M_INP_SLIPPER_LEATHER_TYPES').label, value: lng.itm('M_INP_SLIPPER_LEATHER_TYPES').items.find(item => item.value == orders.ltp).text },
                    { label: lng.itm('M_INP_SLIPPER_TREAD_COLOR').label, value: lng.itm('M_INP_SLIPPER_TREAD_COLOR').items.find(item => item.value == orders.tcl).text },
                ])
                setoet(orders.oet)
                setodt(orders.odt)
                setont(orders.ont)
                setodn(orders.odn)
                setook(orders.ook)
                setods(orders.ods)
                break;
            case "Repair":
                //typeInputs = <SlipperInputs />
                // headerTxt = lng.itm("M_LBL_REPAIR");
                break;

            default:
                break;
        }
    }
    // useEffect(() => {
    //     //console.log(orderInf);
    // }, [orderInf])
    const myOrderInfoComp = () => {
        return (<Grid container style={{ marginTop: "10px" }}>
            {orderInf.map(item => (
                <>
                    <Grid style={{ display: "flex", alignItems: 'center', }} md={4}>
                        <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '15px' }}>{item.label} :</Typography>
                        <Typography variant="body2"style={{ fontSize: '15px' }}>{item.value}</Typography>
                    </Grid>

                </>
            ))}
        </Grid>
        )
    }
    const checkIcon = () => {
        return (<div style={{
            width: '21.33px',
            height: '21.33px',
            display: 'inline-block',
        }} >
            <img src={checkBoxChecked} style={{
                marginTop: "2px",
                width: '17.33px',
                height: '17.33px',
                display: 'inline-block',
            }} />
        </div>)
    }
    function orderDetail() {
        switch (orders.typ) {
            case "Insole":
                return <Grid container>
                    <Grid style={{ display: "flex", alignItems: 'center', }} md={4}>
                        <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '15px' }}>{lng.itm('M_INP_OET').label} :</Typography>
                        <Typography variant="body2" style={{ fontSize: '15px' }}>{oet && Helper.timeStampToDate(oet)}</Typography>
                    </Grid>
                    <Grid style={{ display: "flex", alignItems: 'center', }} md={4}>
                        <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '15px' }}>{lng.itm('M_LBL_ESTIM_DELIVER_DATE')} :</Typography>
                        <Typography variant="body2" style={{ fontSize: '15px' }}>{odt && Helper.timeStampToDate(odt)}</Typography>
                    </Grid>
                    {ont && <Grid style={{ display: "flex", alignItems: 'center', }} md={4}>
                        <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '15px' }}>{lng.itm('M_INP_ONT').label} :</Typography>
                        <Typography variant="body2" style={{ fontSize: '15px' }}>{ont && ont}</Typography>
                    </Grid>}
                    {odn && <Grid style={{ display: "flex", alignItems: 'center', }} md={4}>
                        <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '15px' }}>{lng.itm('M_INP_ODN').label} :</Typography>
                        <Typography variant="body2" style={{ fontSize: '15px' }}>{odn && odn}</Typography>
                    </Grid>}
                    {xts &&
                        <Grid style={{ display: "flex", alignItems: 'center', }} md={4}>
                            {checkIcon()}
                            <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '15px' }}>{lng.itm('M_CHK_INSOLE_XTR_SOFT_COVER').label}</Typography>
                        </Grid>
                    }
                    {ook &&
                        <Grid style={{ display: "flex", alignItems: 'center', }} md={4}>
                            {checkIcon()}
                            <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '15px' }}> {lng.itm('M_CHK_ORD_OOK_INFO')}</Typography>
                        </Grid>
                    }
                    {ods &&
                        <Grid style={{ display: "flex", alignItems: 'center', }} md={4}>
                            {checkIcon()}
                            <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '15px' }}> {lng.itm('M_CHK_ORD_DELIVERY_STATUS')} </Typography>
                        </Grid>
                    }
                </Grid>

            case "Slipper":
                return <Grid container>
                    <Grid style={{ display: "flex", alignItems: 'center', }} md={4}>
                        <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '15px' }}>{lng.itm('M_INP_OET').label} :</Typography>
                        <Typography variant="body2">{oet && Helper.timeStampToDate(oet)}</Typography>
                    </Grid>
                    <Grid style={{ display: "flex", alignItems: 'center', }} md={4}>
                        <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '15px' }}>{lng.itm('M_LBL_ESTIM_DELIVER_DATE')} :</Typography>
                        <Typography variant="body2">{odt && Helper.timeStampToDate(odt)}</Typography>
                    </Grid>
                    {
                        ont && <Grid style={{ display: "flex", alignItems: 'center', }} md={4}>
                            <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '15px' }}>{lng.itm('M_INP_ONT').label} :</Typography>
                            <Typography variant="body2">{ont && ont}</Typography>
                        </Grid>
                    }
                    {
                        odn && <Grid style={{ display: "flex", alignItems: 'center', }} md={4}>
                            <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '15px' }}>{lng.itm('M_INP_ODN').label} :</Typography>
                            <Typography variant="body2">{odn && odn}</Typography>
                        </Grid>
                    }

                    {
                        ook &&
                        <Grid style={{ display: "flex", alignItems: 'center', }} md={4}>
                            {checkIcon()}
                            <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '15px' }}>{lng.itm('M_CHK_ORD_OOK_INFO')}</Typography>
                        </Grid>
                    }
                    {
                        ods &&
                        <Grid style={{ display: "flex", alignItems: 'center', }} md={4}>
                            {checkIcon()}
                            <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '15px' }}>{lng.itm('M_CHK_ORD_DELIVERY_STATUS')}</Typography>
                        </Grid>
                    }
                </Grid >

            case "Repair":
                //typeInputs = <SlipperInputs />
                // headerTxt = lng.itm("M_LBL_REPAIR");
                break;

            default:
                break;
        }
    }
    function getCompPatientTextfieldStyle() {
        if (page == Glb.Page.Order) {
            return {
                style: { color: "black", fontSize: "20px" }
            }
        }
        return {};
    }
    function getCompPatientTextfieldLabelStyle() {
        if (page == Glb.Page.Order) {
            return {
                style: { fontSize: "20px", color: "black" }
            }
        }
        return {};
    }
    return (
        <ThemeProvider>
            <Grid container>
                <Pdf options={{ orientation: "landscape", unit: "in", format: orders?.typ ? [14, 13] : [14, 12] }}
                    x={.25} y={0}
                    targetRef={ref} filename={(row?.pnm + "_" + row?.ddm + "_" + orders?.oet + ".pdf").split(' ').join('-')}>
                    {({ toPdf }) => <IconButton onClick={toPdf}>
                        <img src={PdfIcon} style={{ width: '30px', height: '35px', }} />
                    </IconButton>}
                    {/* <button onClick={toPdf}>Generate Pdf</button> */}
                </Pdf>



                {fileNames && (
                    <>
                        <IconButton onClick={downloadOrderFiles}>
                            <Grid container direction="column" >
                                <Grid>
                                    <CloudDownloadIcon />
                                </Grid>
                                <Grid>
                                    {lng.itm('M_LBL_FILE')}
                                </Grid>
                                <Grid>
                                    {progress > 0 && (<Box display="flex" alignItems="center">
                                        <Box width="100%" mr={1}>
                                            <LinearProgress variant="determinate" value={progress} />
                                        </Box>
                                        <Box minWidth={35}>
                                            <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                                        </Box>
                                    </Box>)}

                                </Grid>
                            </Grid>
                        </IconButton>
                        <Grid direction="column">
                            <h4 style={{ marginBlockEnd: "0px", marginBlockStart: "3px" }}>{lng.itm('M_LBL_FILES')}:</h4>
                            <ul style={{ paddingLeft: "15px" }}>
                                {
                                    fileNames.map(item => (
                                        <li >
                                            <Typography variant="body2" style={{ fontWeight: 'bold' }}>{item}</Typography>
                                        </li>
                                    ))
                                }
                            </ul>
                        </Grid>
                    </>
                )
                }

                <Grid ref={ref} container direction="column" className={classes.formCenter} spacing={1}>
                    <Grid item>
                        <Grid item sm={12}>
                            {/* <Paper className={classes.paper}> */}
                            <Typography variant="h3" style={{ paddingTop: "20px" }}>
                                {(row?.isEdit) ? lng.itm("M_HDR_DIA_UPD") : lng.itm("M_HDR_DIA_INF")}
                            </Typography>
                            {/* </Paper> */}
                        </Grid>
                    </Grid>
                    <Grid container item>
                        {(isAdmin) && (
                            <Grid sm={6}>
                                <TextField disabled label={lng.itm("M_INP_COM").label} value={userCompany.nam} InputLabelProps={getCompPatientTextfieldLabelStyle()} inputProps={getCompPatientTextfieldStyle()} style={{ width: '90%' }} />
                                {/* <SearchSelect id="" use="form" lngKey={"M_INP_COM"} options={allComs} defaultValue={userCompany} /> */}
                            </Grid>
                        )}

                        <Grid sm={6}>
                            <TextField disabled label={lng.itm("M_INP_PAT").label} value={row?.pnm} InputLabelProps={getCompPatientTextfieldLabelStyle()} inputProps={getCompPatientTextfieldStyle()} style={{ width: '90%' }} />
                        </Grid>
                    </Grid>
                    {orders?.typ && <>
                        {myOrderInfoComp()}
                        {orderDetail()}
                    </>
                    }

                    <Grid item>
                        <PainAreas row={row} />
                    </Grid>

                    <Grid container item direction="row">
                        <Grid item sm={3}>
                            <Paper className={classes.paper}>
                                <PatientInfo row={row} isEdit={row?.isEdit} />
                            </Paper>
                        </Grid>
                        <Grid item sm={3}>
                            <Paper className={classes.paper}>
                                <WedgesLeft row={row} isEdit={row?.isEdit} side="left" label={lng.itm("M_INP_WDG").labelL} />
                            </Paper>
                        </Grid>
                        <Grid item sm={3}>
                            <Paper className={classes.paper}>
                                <WedgesRight row={row} isEdit={row?.isEdit} side="right" label={lng.itm("M_INP_WDG").labelR} />
                            </Paper>
                        </Grid>
                        <Grid container item sm={3} direction="column">
                            <Grid container>
                                <Grid item sm={6}>
                                    <Paper className={classes.paper}>
                                        <ShortnessLeft row={row} isEdit={row?.isEdit} side="left" label={lng.itm("M_INP_SHR").labelL} />
                                    </Paper>
                                </Grid>
                                <Grid item sm={6}>
                                    <Paper className={classes.paper}>
                                        <ShortnessRight row={row} isEdit={row?.isEdit} side="right" label={lng.itm("M_INP_SHR").labelR} />
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Grid container direction="row">
                                <Grid container>
                                    {(typ === "Slipper" || row.measurementAL !== 0 || row.measurementAL !== 0) && (<Grid item sm={12}>
                                        <Paper className={classes.paper}>
                                            <Grid container style={{ "justify-content": "center" }}>
                                                <Grid sm={3}>
                                                    <MeasurementAFoot row={row} isEdit={row?.isEdit} isRegister={false} />
                                                </Grid>
                                                <Grid sm={6}>
                                                    <img alt="Foot pain areas" src={MeasurementABImage} className={classes.abImg} />
                                                </Grid>
                                                <Grid sm={3}>
                                                    <MeasurementBFoot row={row} isEdit={row?.isEdit} isRegister={false} />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>)}

                                </Grid>
                                <Grid item sm={12}>
                                    <Paper className={classes.paper}>
                                        <TextField id={lng.itm("M_INP_MEA").id} label={lng.itm("M_INP_MEA").label} style={{ color: "pink" }} onChange={handleChange} value={fillValue ? measurer : ""} />
                                    </Paper>
                                </Grid>
                            </Grid>


                        </Grid>
                    </Grid>
                    {row?.isEdit && (
                        <Grid container direction="column" alignItems="flex-end">
                            <Grid style={{ paddingTop: "30px" }}>
                                <CancelAndSubmitButton onClick={handleSubmit} handleClose={handleClose} />
                            </Grid>

                        </Grid>
                    )}
                </Grid>

            </Grid>

        </ThemeProvider>
    );
}