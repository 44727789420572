import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { lng } from '../Tore/Sys';
import Helper from '../utils/Helper'
import Glb from '../global/Glb';

const inputProps = {
    margin: "dense",
    variant: "outlined",
    size: "small",
};

export default function PhoneInput(props) {

    const { setFormikField, variant, required, phone } = props;
    var [number, setNumber] = useState(0);

    useEffect(() => {
        var num = Helper.maskPhone(phone);
        setNumber(num);

    }, [phone])


    if (variant)
        inputProps.variant = variant;
    else
        delete inputProps.variant;

    if (required)
        inputProps.required = true;

    const lngKey = "M_INP_PNO"
    const handlePhoneChange = (e) => {

        var number = e.target.value;
        if (number[0] === '0')
            number = number.substring(1);
        number = Helper.maskPhone(number);
        e.target.value = number;
        setNumber(number)
        if (setFormikField)
            setFormikField("pno", number.replace(/\D/g, ''));
        else {
            if (e.target.value || e.target.value === false)
                Glb.Filter.dta[e.currentTarget.id] = number.replace(/\D/g, '');
            else
                delete Glb.Filter.dta[e.currentTarget.id]
        }
    }

    return (
        lngKey &&
        <TextField id={lng.itm(lngKey).id} label={lng.itm(lngKey).label} value={number} {...inputProps} onChange={handlePhoneChange} />
    );
}