import Glb from "../global/Glb";

const Helper = {
    maskPhone: (number) => {
        if (number) {
            var x = number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        } return '';
    },

    isFunction: (x) => {
        return Object.prototype.toString.call(x) == '[object Function]';
    },


    formatDate: (date) => {
        var d = new Date();
        if (date)
            d = date;
        var month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    },

    ddMMyyyyToDate: (dateString, splitBy) => {
        var dateParts = dateString.split(splitBy);
        var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        return dateObject;
    },

    timeStampToDate: (dateStr) => {
        if (!dateStr)
            return "";
        var year = dateStr.substring(0, 4);
        var month = dateStr.substring(4, 6);
        var day = dateStr.substring(6, 8);

        var date = new Date(year, month - 1, day);

        return Helper.formatDate(date);

    },

    timeStampToDateObj: (dateStr) => {
        var year = dateStr.substring(0, 4);
        var month = dateStr.substring(4, 6);
        var day = dateStr.substring(6, 8);

        return new Date(year, month - 1, day);
    },

    makeTimeStamp: () => {
        var date = new Date();
        var mm = date.getMonth() + 1;
        var dd = date.getDate();
        return (
            "" +
            date.getFullYear() +
            (mm > 9 ? '' : '0') + mm +
            (dd > 9 ? '' : '0') + dd +
            date.getHours() +
            date.getMinutes() +
            date.getMilliseconds()
        );
    },

    resetDiaErrors: () => {
        Glb.Errors.dia = {
            ddm: true,
            hei: true,
            wei: true,
            fts: true,
            fls: true,
            frs: true,
            mll: true,
            mrl: true
        }
    },

    resetErrors: () => {
        Glb.Errors = {
            dia: {
                ddm: true,
                hei: true,
                wei: true,
                fts: true,
                fls: true,
                frs: true,
                mll: true,
                mrl: true
            },
            ord: {
                cid: true,
                uid: true,
                pid: true,
                did: true,
                odt: true,
                fil: true
            },
            Insole: {
                stp: true,
                tid: true,
                ihs: true,
                spf: true,
                ilf: true,
                cch: true
            },
            Slipper: {
                slp: true,
                ltp: true,
                mcl: true,
                tcl: true
            }
        }

    },
    resetUpdateAlerts: () => {
        Glb.EditSuccess = {
            com: false,
            usr: false,
            prd: false,
            pat: false,
            dia: false,
            ord: false
        };
    },
    resetRegisterAlerts: () => {
        Glb.RegisterSuccess = {
            com: false,
            usr: false,
            prd: false,
            pat: false,
            dia: false,
            ord: false
        };
    }
}

export default Helper;