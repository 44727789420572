import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { lng } from '../../Tore/Sys'
import { Grid, TextField, Typography } from '@material-ui/core';
import Glb from '../../global/Glb';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& label': {
            margin: theme.spacing(1),
            width: '120px'
        },
    },
    paper: {
        // padding: "3px",
        height: "100%",
        //color: "theme.palette.text.secondary",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },


}));

export default function ShortnessRight(props) {

    const classes = useStyles();

    const [shortnessRightHeel, setHeel] = useState();
    const [shortnessRightFront, setFrnt] = useState();
    const [shortnessFullRight, setFull] = useState();

    const isEdit = props?.isEdit;
    const isRegister = props?.isRegister;

    const isInfo = (props?.row != undefined) && (!isEdit && !isRegister);
    const [fillValue, setFillValue] = useState(false);


    useEffect(() => {
        if ((props?.row != undefined) && (isInfo || isEdit || isRegister))
            setFillValue(true)
        if (props.row) {
            var r = props.row;
            Glb.Storage.dia.shortnessRightHeel = parseInt(r.shortnessRightHeel);
            setHeel(r.shortnessRightHeel);
            Glb.Storage.dia.shortnessRightFront = parseInt(r.shortnessRightFront);
            setFrnt(r.shortnessRightFront);
            Glb.Storage.dia.shortnessFullRight = parseInt(r.shortnessFullRight);
            setFull(r.shortnessFullRight);

        }
        return () => {

        }
    }, [props.row])

    function handleChange(e) {
        let val = e.currentTarget.value
        let id = e.currentTarget.id;
        if (id) {
            if (!val || val.length === 0)
                val = 0
            if (val > 60) {
                val = 60
            } else if (val < 0)
                val = 0
            e.currentTarget.value = val;
            Glb.Storage.dia[id] = val;

            switch (id) {
                case "shortnessRightHeel":
                    setHeel(val)
                    break;
                case "shortnessRightFront":
                    setFrnt(val)
                    break;
                case "shortnessFullRight":
                    setFull(val)
                    break;
                default:
                    break;
            }
        }
    }

    const inputProps = {
        min: 0,
        max: 60,
        style: {
            textAlign: 'center',
            fontSize: "18px"
        }
    };

    const inputLabelProps = {
        style: {
            fontSize: "17px",
            color: "black"
        }
    };

    const { heel, frnt, full } = lng.itm("M_INP_SHR");

    return (
        <div className={classes.root}>
            <Grid container direction="column">
                <Grid item >
                    {/* <Paper className={classes.paper}> */}
                    <Typography style={{ paddingBottom: "20px", fontSize: "13px" }}>
                        <strong>
                            {props.label}
                        </strong>
                    </Typography>
                    {/* </Paper> */}
                </Grid>
                <Grid container direction="column">
                    <Grid>
                        {/* <Paper className={classes.paper}> */}
                        <TextField
                            id="shortnessRightHeel"
                            label={heel}
                            //style={{ width: "85%", height: "60%" }}
                            inputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            type="number"
                            onChange={handleChange}
                            value={shortnessRightHeel ? shortnessRightHeel : 0}
                            InputProps={{
                                readOnly: isInfo
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>
                    <Grid >
                        {/* <Paper className={classes.paper}> */}
                        <TextField
                            id="shortnessRightFront"
                            label={frnt}
                            //style={{ width: "85%", height: "60%" }}
                            inputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            type="number"
                            onChange={handleChange}
                            value={shortnessRightFront ? shortnessRightFront : 0}
                            InputProps={{
                                readOnly: isInfo
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>
                    <Grid >
                        {/* <Paper className={classes.paper}> */}
                        <TextField
                            id="shortnessFullRight"
                            label={full}
                            //placeholder={frntMed}
                            //style={{ width: "85%", height: "60%" }}
                            inputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            type="number"
                            onChange={handleChange}
                            value={shortnessFullRight ? shortnessFullRight : 0}
                            InputProps={{
                                readOnly: isInfo
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>
                </Grid>
            </Grid>

        </div>
    );
}



