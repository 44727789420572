import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { lng } from '../../Tore/Sys'
import { Grid, TextField, Typography } from '@material-ui/core';
import Glb from '../../global/Glb';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& label': {
            margin: theme.spacing(1),
            fontSize: '15px',
            width: '200px'
        },
    },
    paper: {
        // padding: "3px",
        height: "100%",
        //color: "theme.palette.text.secondary",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },


}));

export default function MeasurementBFoot(props) {

    const classes = useStyles();

    const [measurementBL, setMeasurementBL] = useState();
    const [measurementBR, setMeasurementBR] = useState();

    const isEdit = props?.isEdit;
    const isRegister = props?.isRegister;

    const isInfo = (props?.row != undefined) && (!isEdit && !isRegister);
    const [fillValue, setFillValue] = useState(false);

    useEffect(() => {
        if ((props?.row != undefined) && (isInfo || isEdit || isRegister))
            setFillValue(true)
        if (props.row) {
            var r = props.row;
            Glb.Storage.dia.measurementBL = parseInt(r.measurementBL);
            setMeasurementBL(r.measurementBL);
            Glb.Storage.dia.measurementBR = parseInt(r.measurementBR);
            setMeasurementBR(r.measurementBR);
        }
        return () => {

        }
    }, [props.row])


    function handleChange(e) {
        let val = e.currentTarget.value
        let id = e.currentTarget.id;
        if (id) {
            if (!val || val.length === 0)
                val = 0
            if (val > 60) {
                val = 60
            } else if (val < 0)
                val = 0
            e.currentTarget.value = val;
            Glb.Storage.dia[id] = val;

            switch (id) {
                case "measurementBL":
                    setMeasurementBL(val)
                    break;
                case "measurementBR":
                    setMeasurementBR(val)
                    break;
                default:
                    break;
            }
        }
    }

    const inputProps = {
        min: 0,
        max: 60,
        style: {
            textAlign: 'center',
            fontSize: "18px"
        }
    };

    const inputLabelProps = {
        style: {
            fontSize: "17px",
            color: "black"
        }
    };

    const { labelLA, labelRA, title } = lng.itm("M_INP_B");

    return (
        <div className={classes.root}>
            <Grid container direction="column">
                <Grid item >
                    {/* <Paper className={classes.paper}> */}
                    <Typography style={{ padding: "5px", fontSize: "13px"}}>
                        <strong>
                            {title}
                        </strong>
                    </Typography>
                    {/* </Paper> */}
                </Grid>
                <Grid container direction="column">
                    <Grid>
                        {/* <Paper className={classes.paper}> */}
                        <TextField
                            id="measurementBL"
                            label={labelLA}
                            //style={{ width: "85%", height: "60%" }}
                            inputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            type="number"
                            onChange={handleChange}
                            value={measurementBL ? measurementBL : 0}
                            InputProps={{
                                readOnly: isInfo
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>
                    <Grid >
                        {/* <Paper className={classes.paper}> */}
                        <TextField
                            id="measurementBR"
                            label={labelRA}
                            //style={{ width: "85%", height: "60%" }}
                            inputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            type="number"
                            onChange={handleChange}
                            value={measurementBR ? measurementBR : 0}
                            InputProps={{
                                readOnly: isInfo
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>

                </Grid>
            </Grid>

        </div>
    );
}



