import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { lng } from '../../Tore/Sys'
import Glb from '../../global/Glb';
import checkBoxChecked from '../../images/checkboxChecked.png'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3)
    },
}));

export default function FootPainAreas(props) {

    const classes = useStyles();

    const labels = lng.itm("M_CHK_FT");

    const isEdit = props?.isEdit;
    const isRegister = props?.isRegister;

    const isInfo = (props?.row != undefined) && (!isEdit && !isRegister);
    const fillValue = (props?.row != undefined) && (isInfo || isEdit || isRegister);

    const [state, setState] = React.useState({
        footLPainOne: false,
        footLPainTwo: false,
        footLPainThree: false,
        footLPainFour: false,
        footLPainFive: false,
        footLPainSix: false,
        footLPainSeven: false,
        footLPainEight: false,
    });

    useEffect(() => {

        if (fillValue) {
            let row = props.row; // foot pain area (maybe left or right foot)

            let checkBoxesState = {
                footLPainOne: row.footLPainOne === true ? (true) : (false),
                footLPainTwo: row.footLPainTwo === true ? (true) : (false),
                footLPainThree: row.footLPainThree === true ? (true) : (false),
                footLPainFour: row.footLPainFour === true ? (true) : (false),
                footLPainFive: row.footLPainFive === true ? (true) : (false),
                footLPainSix: row.footLPainSix === true ? (true) : (false),
                footLPainSeven: row.footLPainSeven === true ? (true) : (false),
                footLPainEight: row.footLPainEight === true ? (true) : (false),
            }
            setState(checkBoxesState);
        }
    }, [props.row]);

    const handleChange = (event) => {

        if (isEdit || !isInfo) {
            var value = event.target.checked;
            var name = event.target.name;
            if (name) {
                if (value && value === false)
                    delete Glb.Storage.dia[name]
                else
                    Glb.Storage.dia[name] = value;

                setState({ ...state, [name]: value });
            }

        }
    };

    const { footLPainOne, footLPainTwo, footLPainThree, footLPainFour, footLPainFive, footLPainSix, footLPainSeven, footLPainEight } = state;
    const myCheckBox = () => {
        return (
            <div style={{
                width: '21.33px',
                height: '21.33px',
                display: 'inline-block',
            }} >
                <img src={checkBoxChecked} style={{
                    marginTop: "2px",
                    width: '17.33px',
                    height: '17.33px',
                    display: 'inline-block',
                }} />
            </div>
        )
    }
    return (
        <div className={classes.root}>
            <FormControl className={classes.formControl}>
                <FormLabel>{props.formLabel}</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={footLPainOne} onChange={handleChange} name="footLPainOne" />}
                        label={<span style={footLPainOne ? { fontWeight: 'bold' } : {}}>{labels.one}</span>}
                        //label={labels.one}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={footLPainTwo} onChange={handleChange} name="footLPainTwo" />}
                        label={<span style={footLPainTwo ? { fontWeight: 'bold' } : {}}>{labels.two}</span>}
                        //label={labels.two}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={footLPainThree} onChange={handleChange} name="footLPainThree" />}
                        label={<span style={footLPainThree ? { fontWeight: 'bold' } : {}}>{labels.three}</span>}
                        //label={labels.three}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={footLPainFour} onChange={handleChange} name="footLPainFour" />}
                        label={<span style={footLPainFour ? { fontWeight: 'bold' } : {}}>{labels.four}</span>}
                        //label={labels.four}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={footLPainFive} onChange={handleChange} name="footLPainFive" />}
                        label={<span style={footLPainFive ? { fontWeight: 'bold' } : {}}>{labels.five}</span>}
                        //label={labels.five}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={footLPainSix} onChange={handleChange} name="footLPainSix" />}
                        label={<span style={footLPainSix ? { fontWeight: 'bold' } : {}}>{labels.six}</span>}
                        //label={labels.six}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={footLPainSeven} onChange={handleChange} name="footLPainSeven" />}
                        label={<span style={footLPainSeven ? { fontWeight: 'bold' } : {}}>{labels.seven}</span>}
                        //label={labels.seven}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={footLPainEight} onChange={handleChange} name="footLPainEight" />}
                        label={<span style={footLPainEight ? { fontWeight: 'bold' } : {}}>{labels.eight}</span>}
                        //label={labels.eight}
                    />
                </FormGroup>
                <FormHelperText></FormHelperText>
            </FormControl>
        </div>
    );
}
