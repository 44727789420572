import "date-fns";
import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { lng } from "../Tore/Sys";
import trLocale from "date-fns/locale/tr";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Helper from "../utils/Helper";

export default function MaterialUIPickers({ handleChange, value, defaultDate, inputVariant = "outlined", lngKey, maxDate }) {
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState();

  var inputInfo = lng.itm(lngKey);

  function formatDate(selectedDate) {
    var mm = selectedDate.getMonth() + 1;
    var dd = selectedDate.getDate();
    return (
      "" +
      selectedDate.getFullYear() +
      (mm > 9 ? '' : '0') + mm +
      (dd > 9 ? '' : '0') + dd
    );
  }

  const handleDateChange = (date) => {
    //alert("datePicker")
    //console.log(date)

    if (date) {
      var formattedDate = formatDate(date);
      if (handleChange) {
        //alert("if handle change")
        handleChange(formattedDate);
        console.log(formattedDate);
      }
      setSelectedDate(date);
    } else {
      if (handleChange)
        handleChange(undefined)
    }
  };

  useEffect(() => {

    if (defaultDate) {
      var formattedDate = formatDate(defaultDate);
      if (handleChange)
        handleChange(formattedDate)
      setSelectedDate(defaultDate)
    }


    return () => {

    }
  }, [])

  return (
    <MuiPickersUtilsProvider locale={trLocale} utils={DateFnsUtils}>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="flex-start"
      >
        <KeyboardDatePicker
          id="date-picker-dialog"
          label={inputInfo?.label}
          format="dd/MM/yyyy"
          value={selectedDate ? selectedDate : value ? Helper.timeStampToDateObj(value) : null}
          onChange={handleDateChange}
          inputVariant={inputVariant}
          maxDate={maxDate ? maxDate : undefined}
          style={{ marginTop: "5px", marginBottom: "10px" }}
          //inputProps={{style: {padding:0, margin:0}}}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          fullWidth
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
