import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { lng } from '../Tore/Sys'



export default function CancelButton({ handleClose }) {

    const history = useHistory();

    function goBack() {
        if (handleClose)
            handleClose()
        else
            history.goBack();
    }

    return (
        <Button onClick={goBack} variant="contained" size="small" >
            {lng.itm("M_INP_CAN")}
        </Button>
    );
}