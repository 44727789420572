import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import Glb from '../../global/Glb';
import { lng } from '../../Tore/Sys'
import CustomSelect from '../CustomSelect';
import CustomSelectProducts from '../CustomSelectProducts';
import DatePicker from '../DatePicker';

export default function InsoleInputs({ cid }) {

    useEffect(() => {

        return () => {

        }
    }, [cid])

    const handleChange = (e) => {

        var checked = e.target.checked;
        var id = e.target.id;
        if (id) {
            if (checked || checked === false) {
                Glb.Storage.ord.xts = checked;
                Glb.Errors.Insole[id] = false;
            }
        }

    }
    var extraSoftLng = lng.itm("M_CHK_INSOLE_XTR_SOFT_COVER");

    return (
        <Grid container spacing={1}>
            <Grid container item direction="column" sm={6} spacing={1}>
                <Grid item >
                    <CustomSelect lngKey="M_INP_INSOLE_FOOT_TYPE" obj="ord" />
                </Grid>
                <Grid item >
                    <CustomSelect lngKey="M_INP_INSOLE_HARDNESS" obj="ord" />
                </Grid>
            </Grid>
            <Grid container item direction="column" sm={6} spacing={1}>
                <Grid item >
                    <CustomSelectProducts lngKey="M_INP_INSOLE_TYPES" obj="ord" cid={cid} />
                </Grid>
                <Grid item >
                    <CustomSelect lngKey="M_INP_INSOLE_STAGES" obj="ord" />
                </Grid>
            </Grid>
            <Grid container item direction="column" sm={6} spacing={1}>
                <Grid item >
                    <CustomSelect lngKey="M_INP_INSOLE_LEFT" obj="ord" />
                </Grid>

            </Grid>
            <Grid container item direction="column" sm={6} spacing={1}>
                <Grid item >
                    <CustomSelect lngKey="M_INP_INSOLE_COVERING_CHOICES" obj="ord" />
                </Grid>
                <Grid item >
                    <FormControl fullWidth>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox id={extraSoftLng.id} name="extraSoft" onChange={handleChange} />}
                                label={extraSoftLng.label}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    );

}