import React, { useEffect } from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import HttpPost from './HttpPost';
import Glb from '../global/Glb';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      margin: theme.spacing(3),
    },
  },
}));

export default function CustomSelectMultiple(props) {

  const { optionsPath } = props;

  const classes = useStyles();

  const [values, setValues] = React.useState([]);


  useEffect(() => {
    loadData();
  }, []);



  const loadData = async () => {
    Glb.Filter.dta = { act: true }
    let response = await HttpPost(optionsPath, Glb.Filter)
    if (response.sta === "ACK")
      setValues(response.dta)
  }

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id="tags-standard"
        options={values}
        getOptionLabel={(option) => option.nam}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Multiple values"
            placeholder="Favorites"
          />
        )}
      />
    </div>
  );
}