import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect } from 'react';
import { lng } from '../../Tore/Sys'
import { useHistory } from "react-router-dom";

import DataTableRow from './DataTableRow';
import RegisterSuccessAlert from '../RegisterSuccessAlert';
import EditSuccessAlert from '../EditSuccessAlert';
import Glb from '../../global/Glb';

const useStyles = makeStyles((theme) => ({
    table: {
        fontSize: "10px"
    },
    tableCell: {
        padding: "0px",
        height: "30px",
    },
    tableHeaderCell: {
        padding: "0px",
        height: "35px"
    },
    tableRow: {
        "&:hover": {
            backgroundColor: "#B1CCFF !important",
            zindex: -1,

        },
        cursor: 'pointer',
    }
}));

export default function DataTable(props) {
    const classes = useStyles();
    const history = useHistory();
    const columns = lng.itm(props.columnsTag);

    var centeredHeaders = ["act", "dia", "rel", "ook", "typ", "oct", "odt", "oet", "det", "ods"];

    const { rows, editPath, infoPath, hasInfo, diaPath, erasable, editable, registeredObj, setReRender, typeId, setRows, keyExtractor } = props;
    var registerSuccess = false;
    var editSuccess = false;
    if (registeredObj) {
        registerSuccess = Glb.RegisterSuccess[registeredObj];
        editSuccess = Glb.EditSuccess[registeredObj];
    }

    useEffect(() => {

        return () => {
            if (registeredObj) {
                Glb.RegisterSuccess[registeredObj] = false;
                Glb.EditSuccess[registeredObj] = false;
            }
        }
    }, [])

    let dataExist = false;


    if (rows && rows[0]) {
        dataExist = true;
    }


    var isCnmExist = columns.find(c => c.for === "cnm");
    var isUnmExist = columns.find(c => c.for === "unm");
    var allComs;
    var allUsrs;
    if (dataExist)
        if (isCnmExist) {
            allComs = Glb.Storage.allComs;
        }
    if (isUnmExist) {
        var allUsrsStr = localStorage.getItem("allUsrs");
        allUsrs = JSON.parse(allUsrsStr);
    }

    const onRowClick = (row) => {
        row.edit = false;
        history.push({
            pathname: infoPath,
            state: {
                record: row
            }
        });
    }

    return (
        dataExist &&
        <>
            {registerSuccess && <RegisterSuccessAlert />}
            {editSuccess && <EditSuccessAlert />}
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCell} />
                        {columns.map((header) => (
                            <TableCell key={header.for} className={classes.tableHeaderCell} style={{ textAlign: (centeredHeaders.includes(header.for)) ? "center" : "left" }} ><strong>{header.label}</strong></TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => {
                        if (isCnmExist && allComs) {
                            let com = allComs.find(c => c.cid === row.cid);
                            if (com)
                                row.cnm = com.nam;
                        }
                        if (isUnmExist && allUsrs) {
                            let usr = allUsrs.find(u => u.uid === row.uid);
                            if (usr)
                                row.unm = usr.nam + " " + usr.sur;
                        }

                        return <DataTableRow key={row[keyExtractor]} keyExtractor={keyExtractor} columns={columns} row={row} diaPath={diaPath} infoPath={infoPath} editPath={editPath} index={index} hasInfo={hasInfo} onRowClick={onRowClick} classes={classes} history={history} erasable={erasable} editable={editable} setReRender={setReRender} rowIndex={index} typeId={typeId} setRows={setRows} page={props.page} />
                    })}
                </TableBody>
            </Table>
        </>
    );
}

