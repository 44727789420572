import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import { lng } from '../../Tore/Sys'
import CustomPaper from '../CustomPaper';
import CancelAndSubmitButton from '../CancelAndSubmitButton';
import HttpPost from '../HttpPost'
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom'
import PhoneInput from '../PhoneInput'

import Glb from '../../global/Glb';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formCenter: {
        alignItems: "center",
        justify: "center"
    }
}));

const inputProps = {
    margin: "dense",
    variant: "outlined",
    size: "small",
    required: true
};


export default function CompanyRegister() {
    const classes = useStyles();
    const history = useHistory();

    const formik = useFormik({
        initialValues: {
            nam: '',
            ema: '',
            adr: '',
            pno: '',
        },
        onSubmit: async values => {
            const company = values;
            let ema = values.ema;
            let response = await HttpPost("/comIns", company)
            if (response) {
                if (response?.sta === "ACK") {
                    var cid = response?.dta.cid;
                    values.cid = cid;
                    values.act = true;
                    values.ema = ema;
                    Glb.Reload.com = false;
                    Glb.RegisterSuccess.com = true;
                    if (Glb.Storage.comLst) {
                        Glb.Storage.comLst.push(values);
                    } else {
                        Glb.Storage.comLst = [];
                        Glb.Storage.comLst.push(values);
                    }
                    var allComsStr;
                    if (Glb.Storage.allComs) {
                        Glb.Storage.allComs.push(values);
                        allComsStr = JSON.stringify(Glb.Storage.allComs);
                        localStorage.setItem("allComs", allComsStr);
                    } else {
                        Glb.Storage.allComs = [];
                        Glb.Storage.allComs.push(values);
                        allComsStr = JSON.stringify(Glb.Storage.allComs);
                        localStorage.setItem("allComs", allComsStr);
                    }

                    history.goBack();
                } else {
                    //ExcAlert(true, "asd");
                }
            }
        },
    });

    return (
        <Grid container>
            <Grid item sm={2} />
            <Grid container className={classes.formCenter} direction="column">
                <CustomPaper>

                    <h3> {lng.itm("M_HDR_COM_INS")} </h3>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container direction="column" >
                            <TextField id={lng.itm("M_INP_COM_NAM").id} label={lng.itm("M_INP_COM_NAM").label} {...inputProps} onChange={formik.handleChange} />
                            <TextField id={lng.itm("M_INP_EMA").id} label={lng.itm("M_INP_EMA").label} type="email" {...inputProps} onChange={formik.handleChange} />
                            <TextField id={lng.itm("M_INP_ADR").id} label={lng.itm("M_INP_ADR").label} {...inputProps} onChange={formik.handleChange} multiline rows={4} />
                            <PhoneInput setFormikField={formik.setFieldValue} variant="outlined" required />
                            {/* <TextField id={lng.itm("M_INP_PNO").id} label={lng.itm("M_INP_PNO").label} {...inputProps} onChange={handlePhoneChange} /> */}

                            <CancelAndSubmitButton />
                        </Grid>
                    </form>

                </CustomPaper>

            </Grid>
            <Grid item sm={2} />
        </Grid>

    );
}
