import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { lng } from '../../Tore/Sys'
import { Grid, TextField, Typography } from '@material-ui/core';
import Glb from '../../global/Glb';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& label': {
            width: '115%'
        },

    },
    paper: {
        // padding: theme.spacing(2),
        height: "100%",
        //color: "theme.palette.text.secondary",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },

}));

export default function PatientInfo(props) {

    const classes = useStyles();

    const { hei: heiLbl, wei: weiLbl, fts: ftsLbl, fls: flsLbl, frs: frsLbl, mll: mllLbl, mrl: mrlLbl } = lng.itm("M_INP_PAT_INF");

    const isEdit = props?.isEdit;
    const isRegister = props?.isRegister;

    const isInfo = (props?.row != undefined) && (!isEdit && !isRegister);
    const [fillValue, setFillValue] = useState(false);
    const [row, setRow] = useState();
    const [hei, setHei] = useState();
    const [wei, setWei] = useState();
    const [fts, setFts] = useState();
    const [fls, setFls] = useState();
    const [frs, setFrs] = useState();
    const [mll, setMll] = useState();
    const [mrl, setMrl] = useState();


    useEffect(() => {
        if ((props?.row != undefined) && (isInfo || isEdit || isRegister))
            setFillValue(true)

        Glb.Storage.dia = {};
        if (props.row) {
            var r = props.row;

            setHei(r.hei);
            setWei(r.wei);
            setFts(r.fts);
            setFls(r.fls);
            setFrs(r.frs);
            setMll(r.mll);
            setMrl(r.mrl);

            if (isRegister) {

                Glb.Storage.dia.hei = parseFloat(r.hei);
                Glb.Storage.dia.wei = parseFloat(r.wei);
                Glb.Storage.dia.fts = parseFloat(r.fts);
                Glb.Storage.dia.fls = parseFloat(r.fls);
                Glb.Storage.dia.frs = parseFloat(r.frs);
                Glb.Storage.dia.mll = parseFloat(r.mll);
                Glb.Storage.dia.mrl = parseFloat(r.mrl);
            } else if (isEdit)
                Glb.Storage.dia.did = r.did;

            Glb.Errors.dia.hei = false;
            Glb.Errors.dia.wei = false;
            Glb.Errors.dia.fts = false;
            Glb.Errors.dia.fls = false;
            Glb.Errors.dia.frs = false;
            Glb.Errors.dia.mll = false;
            Glb.Errors.dia.mrl = false;
        }

        return () => {

        }
    }, [props?.row])


    function handleChange(e) {

        let val = e.currentTarget.value
        let id = e.currentTarget.id;


        if (id) {
            // if (!val || val.length === 0) {
            //     val = ""
            //     Glb.Errors.dia[id] = true;
            // }
            if (val > 220) {
                val = 220
                e.currentTarget.value = parseFloat(val);
            } else if (val < 0) {
                val = 0
                e.currentTarget.value = parseFloat(val);
            }

            Glb.Storage.dia[id] = parseFloat(val);
            Glb.Errors.dia[id] = false;
            switch (id) {
                case "hei":
                    setHei(parseFloat(val))
                    break;
                case "wei":
                    setWei(parseFloat(val))
                    break;
                case "fts":
                    setFts(parseFloat(val))
                    break;
                case "fls":
                    setFls(parseFloat(val))
                    break;
                case "frs":
                    setFrs(parseFloat(val))
                    break;
                case "mll":
                    setMll(parseFloat(val))
                    break;
                case "mrl":
                    setMrl(parseFloat(val))
                    break;

                default:
                    break;
            }

            //alert(JSON.stringify(Glb.Storage.dia))
        }
    }

    const inputProps = { 
        style: { 
            textAlign: 'center', 
            fontSize: "18px" 
        } 
    };

    const inputLabelProps = {
        style: {
            fontSize: "17px",
            color: "black"
        }
    };

    return (
        <div className={classes.root}>
            <Grid container direction="column" >
                <Grid item >
                    {/* <Paper className={classes.paper}> */}
                    <Typography style={{ paddingBottom: "20px" }}>
                        <strong>
                            {lng.itm("M_HDR_DIA_PAT_MEASURES")}
                        </strong>
                    </Typography>
                    {/* </Paper> */}
                </Grid>
                <Grid container direction="column" >
                    <Grid >
                        {/* <Paper className={classes.paper}> */}

                        <TextField
                            id="hei"
                            label={heiLbl}
                            required
                            //style={{ width: "85%", height: "60%" }}
                            inputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            type="number"
                            onChange={handleChange}
                            value={hei ? (hei) : 0}
                            InputProps={{
                                readOnly: isInfo,
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>
                    <Grid >
                        {/* <Paper className={classes.paper}> */}
                        <TextField
                            id="wei"
                            label={weiLbl}
                            required
                            //style={{ width: "85%", height: "60%" }}
                            inputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            type="number"
                            onChange={handleChange}
                            value={wei ? (wei) : 0}
                            InputProps={{
                                readOnly: isInfo && !isEdit,
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>
                    <Grid >
                        {/* <Paper className={classes.paper}> */}
                        <TextField
                            id="fts"
                            label={ftsLbl}
                            required
                            //placeholder={frntMed}
                            //style={{ width: "85%", height: "60%" }}
                            inputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            type="number"
                            onChange={handleChange}
                            value={fts ? (fts) : 0}
                            InputProps={{
                                readOnly: isInfo && !isEdit,
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>
                    <Grid >
                        {/* <Paper className={classes.paper}> */}
                        <TextField
                            id="fls"
                            //placeholder={frntLat}
                            label={flsLbl}
                            required
                            //style={{ width: "85%", height: "60%" }}
                            inputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            type="number"
                            onChange={handleChange}
                            value={fls ? (fls) : 0}
                            InputProps={{
                                readOnly: isInfo && !isEdit,
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>
                    <Grid >
                        {/* <Paper className={classes.paper}> */}
                        <TextField
                            id="frs"
                            label={frsLbl}
                            required
                            //placeholder={fullMed}
                            //style={{ width: "85%", height: "60%" }}
                            inputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            type="number"
                            onChange={handleChange}
                            value={frs ? (frs) : 0}
                            InputProps={{
                                readOnly: isInfo && !isEdit,
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>
                    <Grid >
                        {/* <Paper className={classes.paper}> */}
                        <TextField
                            id="mll"
                            label={mllLbl}
                            required
                            //placeholder={fullLat}
                            style={{ paddingBottom: "10px", "&label": { fontSize: "1px" } }}
                            inputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            type="number"
                            onChange={handleChange}
                            value={mll ? (mll) : 0}
                            InputProps={{
                                readOnly: isInfo && !isEdit,
                            }}
                        />
                        {/* </Paper> */}
                    </Grid>
                    <Grid>
                        <TextField
                            id="mrl"
                            label={mrlLbl}
                            required
                            //placeholder={fullLat}
                            style={{ paddingBottom: "10px" }}
                            inputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            type="number"
                            onChange={handleChange}
                            value={mrl ? (mrl) : 0}
                            InputProps={{
                                readOnly: isInfo && !isEdit,
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

        </div>
    );
}
