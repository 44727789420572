import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SearchTable from '../components/table/SearchTable';
import DataTable from '../components/table/DataTable';
import Glb from '../global/Glb';


const useStyles = makeStyles((theme) => ({
    formCenter: {
        alignItems: "center",
        justify: "center",
        height: 300,
        width: "%100",
        margin: "dense",

    },
    table: {
        fontSize: "10px"
    },
    button: {
        margin: theme.spacing(1),
        justifyContent: "center"
    },
}));

export default function Users() {
    const classes = useStyles();

    const [rows, setRows] = useState([]);
    var [reRender, setReRender] = useState({});

    useEffect(() => {
        Glb.Filter.dta = { act: true }
        Glb.Filter.skp = 0;
        Glb.ListFiltered.com = false;

        if (Glb.Storage.allUsrs)
            setRows(Glb.Storage.allUsrs)
        else {
            if (Glb.Storage.usrLst.length !== 0)
                setRows(Glb.Storage.usrLst)
        }
        return () => {
            Glb.Filter.dta = {}
            Glb.Filter.skp = 0;
        }
    }, [])

    const columnsTag = "M_TBL_USR_HDR";
    const searchInputs = ["M_INP_NAM", "M_INP_SUR", "M_INP_EMA", "M_INP_PNO", "M_INP_COM", "M_INP_ACT"]

    return (
        <Grid container>
            <Grid container>
                <Grid item xm={"false"} sm={2} />
                <Grid container className={classes.formCenter} xm={12} sm={8}>
                    <SearchTable setRows={setRows} searchInputs={searchInputs} registerPath="/userRegister" searchPath="/usrLst" />
                    <DataTable rows={rows} columnsTag={columnsTag} editPath="/userEdit" editable={true} erasable={true} registeredObj="usr" setReRender={setReRender} keyExtractor="uid" />
                </Grid>
            </Grid>
            <Grid item xm={"false"} sm={2} />
        </Grid>

    );
}