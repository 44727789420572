/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { lng } from '../../Tore/Sys'
import Glb from '../../global/Glb';
import { IconButton, TextField, Typography } from '@material-ui/core';
import checkBoxChecked from '../../images/checkboxChecked.png'


export default function DiagnosisChecks(props) {
    const useStyles = makeStyles((theme) => ({

        root: {
            display: 'flex',
        },
        formControl: {
            margin: theme.spacing(3)
        },
        labelSty: {
            // color: prop.checked ? 'green' : 'black',
            fontWeight: 'bold'
        }
    }));

    const classes = useStyles();

    const labels = lng.itm("M_CHK_DIA");

    const isEdit = props?.isEdit;
    const isRegister = props?.isRegister;
    const [ddm, setDdm] = useState();

    const isInfo = (props?.row != undefined) && (!isEdit && !isRegister);
    const fillValue = (props?.row != undefined) && (isInfo || isEdit || isRegister);
    const [row, setRow] = useState();

    const [state, setState] = React.useState({
        pesplanus: false,
        pescavus: false,
        calcanealExostosis: false,
        plantarFasitis: false,
        mortonNeuroma: false,
        rheumatism: false,
        diabeticFoot: false,
        tibialisPosteriorDeformity: false,
        achillesTendinitis: false,
        halluxValgus: false,
        navicularAccesory: false,
        halluxRigidus: false,
        halluxLimitus: false,
        stressFracture: false
    });

    useEffect(() => {

        if (props.row) {
            setRow(props.row)
        }

        if (isRegister)
            Glb.Storage.dia = props.row;

        if (fillValue) {

            let row = props.row;

            let checkBoxesState = {
                pesplanus: row.pesplanus === true ? (true) : (false),
                pescavus: row.pescavus === true ? (true) : (false),
                calcanealExostosis: row.calcanealExostosis === true ? (true) : (false),
                plantarFasitis: row.plantarFasitis === true ? (true) : (false),
                mortonNeuroma: row.mortonNeuroma === true ? (true) : (false),
                rheumatism: row.rheumatism === true ? (true) : (false),
                diabeticFoot: row.diabeticFoot === true ? (true) : (false),
                tibialisPosteriorDeformity: row.tibialisPosteriorDeformity === true ? (true) : (false),
                achillesTendinitis: row.achillesTendinitis === true ? (true) : (false),
                halluxValgus: row.halluxValgus === true ? (true) : (false),
                navicularAccesory: row.navicularAccesory === true ? (true) : (false),
                halluxRigidus: row.halluxRigidus === true ? (true) : (false),
                halluxLimitus: row.halluxLimitus === true ? (true) : (false),
                stressFracture: row.stressFracture === true ? (true) : (false)
            }
            setState(checkBoxesState);
            setDdm(row.ddm);
        }
    }, [props.row]);

    useEffect(() => {
        Glb.Errors.dia.ddm = false;
        return () => {

        }
    }, [ddm])

    const handleChange = (event) => {

        if (isEdit || !isInfo) {
            var value = event.target.checked;
            var name = event.target.name;
            if (name) {
                if (value && value === false)
                    delete Glb.Storage.dia[name]
                else
                    Glb.Storage.dia[name] = value;
                setState({ ...state, [name]: value });
            }

        }
    };

    const handleTextchange = (e) => {
        if (isEdit || !isInfo) {
            var value = e.target.value;
            var id = e.target.id;
            if (value) {
                Glb.Storage.dia[id] = value;
                Glb.Errors.dia[id] = false;
            } else
                delete Glb.Storage.dia[id];
        }
        setDdm(value);
    };

    const { pesplanus, pescavus, calcanealExostosis, plantarFasitis, mortonNeuroma, rheumatism, diabeticFoot, tibialisPosteriorDeformity, achillesTendinitis, halluxValgus, navicularAccesory, halluxRigidus, halluxLimitus, stressFracture } = state;

    const myCheckBox = () => {
        return (
            <div style={{
                width: '21.33px',
                height: '21.33px',
                display: 'inline-block',
            }} >
                <img src={checkBoxChecked} style={{
                    marginTop: "2px",
                    width: '17.33px',
                    height: '17.33px',
                    display: 'inline-block',
                }} />
            </div>
        )
    }
    return (
        <div className={classes.root}>
            <FormControl className={classes.formControl}>
                <FormHelperText> </FormHelperText>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox checkedIcon={myCheckBox()} checked={pesplanus} onChange={handleChange} name="pesplanus" />
                        }
                        label={<span style={pesplanus ? { fontWeight: 'bold' } : {}}>{labels.pesplanus}</span>}
                        //label={labels.pesplanus}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={pescavus} onChange={handleChange} name="pescavus" />}
                        label={<span style={pescavus ? { fontWeight: 'bold' } : {}}>{labels.pescavus}</span>}
                        //label={labels.pescavus}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={calcanealExostosis} onChange={handleChange} name="calcanealExostosis" />}
                        label={<span style={calcanealExostosis ? { fontWeight: 'bold' } : {}}>{labels.calcanealExostosis}</span>}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={plantarFasitis} onChange={handleChange} name="plantarFasitis" />}
                        label={<span style={plantarFasitis ? { fontWeight: 'bold' } : {}}>{labels.plantarFasitis}</span>}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={mortonNeuroma} onChange={handleChange} name="mortonNeuroma" />}
                        label={<span style={mortonNeuroma ? { fontWeight: 'bold' } : {}}>{labels.mortonNeuroma}</span>}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={rheumatism} onChange={handleChange} name="rheumatism" />}
                        label={<span style={rheumatism ? { fontWeight: 'bold' } : {}}>{labels.rheumatism}</span>}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={diabeticFoot} onChange={handleChange} name="diabeticFoot" />}
                        label={<span style={diabeticFoot ? { fontWeight: 'bold' } : {}}>{labels.diabeticFoot}</span>}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={tibialisPosteriorDeformity} onChange={handleChange} name="tibialisPosteriorDeformity" />}
                        label={<span style={tibialisPosteriorDeformity ? { fontWeight: 'bold' } : {}}>{labels.tibialisPosteriorDeformity}</span>}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={achillesTendinitis} onChange={handleChange} name="achillesTendinitis" />}
                        label={<span style={achillesTendinitis ? { fontWeight: 'bold' } : {}}>{labels.achillesTendinitis}</span>}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={halluxValgus} onChange={handleChange} name="halluxValgus" />}
                        label={<span style={halluxValgus ? { fontWeight: 'bold' } : {}}>{labels.halluxValgus}</span>}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={navicularAccesory} onChange={handleChange} name="navicularAccesory" />}
                        label={<span style={navicularAccesory ? { fontWeight: 'bold' } : {}}>{labels.navicularAccesory}</span>}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={halluxRigidus} onChange={handleChange} name="halluxRigidus" />}
                        label={<span style={halluxRigidus ? { fontWeight: 'bold' } : {}}>{labels.halluxRigidus}</span>}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={halluxLimitus} onChange={handleChange} name="halluxLimitus" />}
                        label={<span style={halluxLimitus ? { fontWeight: 'bold' } : {}}>{labels.halluxLimitus}</span>}
                    />
                    <FormControlLabel
                        control={<Checkbox checkedIcon={myCheckBox()} checked={stressFracture} onChange={handleChange} name="stressFracture" />}
                        label={<span style={stressFracture ? { fontWeight: 'bold' } : {}}>{labels.stressFracture}</span>}
                    />
                </FormGroup>
                {/* <FormHelperText> </FormHelperText> */}
                <TextField required label={labels.ddm} id="ddm" onChange={handleTextchange} value={ddm || ""} multiline />
            </FormControl>



        </div>
    );
}
